import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  getFormQuery,
  getUpcomingConsultationsQuery,
  submitHealthieForm,
} from "@/api";
import { FormField, Loader } from "@/components";
import { env } from "@/env";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { Button, Checkbox, errorToast, icons, IconWrapper } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

export const MissingIntakeForm = () => {
  const [openLoopConsent, setOpenLoopConsent] = useState<CheckedState>();
  const [medicationConsent, setMedicationConsent] = useState<CheckedState>();
  const { data: consultations } = useQuery({
    ...getUpcomingConsultationsQuery(),
  });

  const formId = consultations?.length
    ? env.VITE_HEALTHIE_ONBOARDING_SYNC_FORM_ID
    : env.VITE_HEALTHIE_ONBOARDING_ASYNC_FORM_ID;

  const navigate = useNavigate();

  const { control, handleSubmit, setError } = useForm();

  const patientId = useUserStore((state) => state.user?.id);
  const { setUser, user } = useUserStore();

  const { data: fields, isLoading } = useQuery({
    ...getFormQuery(formId, patientId),
    select: (data) => data.fields,
  });

  const queryClient = useQueryClient();
  const { mutate: submitHealthieFormMutation, isPending } = useMutation({
    mutationFn: submitHealthieForm.mutation,
    onSuccess: () => {
      submitHealthieForm.invalidates(queryClient);
      if (user) {
        setUser({ ...user, intakeFormCompleted: true });
      }
      navigate(ROUTES.HOME);
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  return (
    <div className="flex flex-col gap-4 px-20 py-5">
      <div className="flex flex-col items-center">
        <h1 className="font-serif text-2xl text-brown-10 sm:text-3xl sm:leading-snug">
          {t`Intake form questions`}
        </h1>
        <p className="text-sm font-medium text-brown-08 sm:text-lg sm:leading-6">
          {t`(*) Mandatory field`}
        </p>
      </div>
      <form
        onSubmit={handleSubmit((value) =>
          submitHealthieFormMutation({
            formId,
            patientId: patientId!,
            answers: value,
          }),
        )}
        className="flex max-w-3xl flex-col gap-8"
      >
        {isLoading && <Loader />}

        {fields?.map((field) => (
          <FormField key={field.id} formField={field} control={control} />
        ))}

        {!!fields?.length && (
          <>
            <div className="flex flex-col gap-3 text-brown-10">
              <Checkbox
                checked={openLoopConsent}
                onCheckedChange={setOpenLoopConsent}
                id=""
                label={
                  <span>
                    {t`I consent to OpenLoop Health's `}
                    <Link
                      to="https://openloophealth.com/privacy-policy"
                      className="font-bold underline"
                    >{t`Privacy Policy`}</Link>
                    {", "}
                    <Link
                      to="https://openloophealth.com/terms-of-use"
                      className="font-bold underline"
                    >{t`Terms & Conditions`}</Link>
                    {", and "}
                    <Link
                      to="https://openloophealth.com/telehealth-consent"
                      className="font-bold underline"
                    >{t`Telehealth Consent`}</Link>
                  </span>
                }
              />
              <Checkbox
                checked={medicationConsent}
                onCheckedChange={setMedicationConsent}
                id=""
                label={t`I consent to only get weight-loss medication from this platform`}
              />
            </div>

            <div className="flex items-center justify-end pt-7">
              <Button
                disabled={isPending || !(openLoopConsent && medicationConsent)}
                type="submit"
                size="lg"
                className="mb-4 w-full sm:w-fit"
              >
                {t`Next`}
                <IconWrapper size="sm" className="hidden sm:block">
                  <icons.ChevronRight />
                </IconWrapper>
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

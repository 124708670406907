import { t } from "ttag";

import { ROUTES } from "@/router";
import { SUPPORT_EMAIL } from "@/shared.constants";
import { Alert, Badge, Label } from "@/ui";
import { StepCard } from ".";

export const Step4 = () => (
  <div className="flex flex-col gap-2">
    <Label htmlFor="step-4-checklist" size="md">{t`Fourth step:`}</Label>

    <StepCard.Root id="step-4-checklist">
      <StepCard.Title>{t`💊 Looking for a Refill?`}</StepCard.Title>

      <StepCard.Content>
        {t`You can request a refill after your 3rd injection of the month:`}
        <StepCard.Item>
          <StepCard.ItemTitle>
            {t`1. Click on `}
            <StepCard.Link
              to={ROUTES.CONSULTATIONS.PURPOSE}
            >{t`"New Medication Request"`}</StepCard.Link>
            {t` in your portal.`}
          </StepCard.ItemTitle>
        </StepCard.Item>

        <StepCard.Item className="flex flex-col gap-2">
          <StepCard.ItemTitle>{t`2. Choose between:`}</StepCard.ItemTitle>
          <StepCard.ItemContent variant="secondary">
            <div className="flex justify-between gap-4">
              <StepCard.ItemTitle>{t`Refill Without a Video Visit`}</StepCard.ItemTitle>
              <Badge size="md">{t`Fastest`}</Badge>
            </div>
            {t`Convenient for quick refills if you have no questions for your provider`}
          </StepCard.ItemContent>

          <StepCard.ItemContent variant="secondary">
            <StepCard.ItemTitle>{t`Refill With a Video Visit`}</StepCard.ItemTitle>
            {t`Ideal if you need to discuss changes or have questions about your medication.`}
          </StepCard.ItemContent>
        </StepCard.Item>
      </StepCard.Content>
    </StepCard.Root>

    <Alert
      content={
        <p>
          {t`Need Help?`}
          <br />
          {t`If you have any questions or need assistance at any step, please reach out to us at `}
          <span className="font-semibold text-nature-08 md:font-bold">
            {SUPPORT_EMAIL}
          </span>
          {t`. We're here to help!`}
        </p>
      }
    />

    <Alert
      content={
        <p>
          {t`Important: To ensure timely processing of your refill request, please make sure you have completed and uploaded the following lab tests within the past 12 months:`}
          <ul className="list-inside list-disc pl-2">
            <li>{t`Comprehensive Metabolic Panel (CMP)`}</li>
            <li>{t`Thyroid-Stimulating Hormone (TSH)`}</li>
            <li>{t`Lipid Panel`}</li>
            <li>{t`Hemoglobin A1c (HgbA1c)`}</li>
          </ul>
        </p>
      }
    />
  </div>
);

import { t } from "ttag";

import { Alert } from "@/ui";

export const BillingDetails = () => (
  <Alert
    content={
      <p>
        {t`You'll be billed as `}
        <strong>{t`"Fridays", "Fridays Health" or "Thrive Health Group Inc."`}</strong>
        {t` on your bank statement.`}
        <br />
        {t`Additional charges from “Open Loop Health” may apply for medications and labs.`}
      </p>
    }
  />
);

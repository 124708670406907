import type { QueryClient } from "@tanstack/react-query";

import type { Folder, UploadedFile, UploadFilesValues } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

const DOMAIN = "documents";

interface UploadedFileWithUrl extends UploadedFile {
  url: string;
}
export const getDocumentQuery = (documentId?: string | null) => ({
  enabled: !!documentId,
  queryKey: [DOMAIN, "getDocumentUrl", documentId],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<UploadedFileWithUrl>>(
      `/documents/${documentId}`,
    );

    return data.data;
  },
});

export const updateDocument = {
  mutation: async (params: Omit<UploadedFile, "createdAt">) => {
    const { data } = await privateAPI.put<ServiceResponse<null>>(
      `/documents/${params.id}`,
      { name: params.name },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const deleteDocument = {
  mutation: async (id: UploadedFile["id"]) => {
    const { data } = await privateAPI.delete<ServiceResponse<null>>(
      `/documents/${id}`,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const uploadTempFile = {
  mutation: async (file: File) => {
    const { data } = await privateAPI.post<ServiceResponse<{ fileId: string }>>(
      "/temp-files",
      { file },
      { headers: { "Content-Type": "multipart/form-data" } },
    );

    return data.data;
  },
};

const getFieldsWithoutFiles = (fields: UploadFilesValues["files"]) =>
  fields.map((field) => ({ id: field.id, name: field.name }));

export const submitDocuments = {
  mutation: async (params: UploadFilesValues) => {
    const { data } = await privateAPI.post<ServiceResponse<null>>(
      "/temp-files/upload-files",
      {
        type: params.type,
        files: getFieldsWithoutFiles(params.files),
      },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const getDocumentsQuery = (type: Folder) => ({
  queryKey: [DOMAIN, "getDocumentsQuery", type],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<UploadedFile[]>>(
      `/documents`,
      { params: { type } },
    );
    return data.data;
  },
});

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { setPassword } from "@/api";
import { Logo, PasswordTips } from "@/components";
import { ROUTES } from "@/router";
import { Button, icons, IconWrapper, Input } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

const resetPasswordSchema = z.object({
  password: z
    .string()
    .min(8, { message: t`Password must be at least 8 characters long` }),
  passwordConfirmation: z.string().min(8, {
    message: t`Password confirmation must be at least 8 characters long`,
  }),
});

export const ResetPasswordForm = () => {
  const token = new URLSearchParams(window.location.search).get("token");
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const navigate = useNavigate();

  const { mutate: resetPasswordMutation, isPending } = useMutation({
    mutationFn: setPassword.mutation,
    onSuccess: () => {
      setPasswordUpdated(true);
    },
    onError: (error) => {
      handleAxiosFieldErrors(error, setError);
    },
  });

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    setError,
  } = useForm<z.infer<typeof resetPasswordSchema>>({
    resolver: zodResolver(resetPasswordSchema),
  });

  return (
    <div className="flex flex-col sm:h-full sm:w-152">
      <header className="relative flex h-fit flex-col gap-3 px-6 pb-20 pt-14 text-nature-02 sm:max-h-80 sm:justify-between sm:gap-5 sm:px-14 sm:pb-8 sm:text-brown-09">
        <Logo className="mx-auto h-20 w-fit self-start sm:fill-nature-08" />
        {!passwordUpdated && (
          <>
            <div className="flex flex-col gap-2 text-center sm:text-left">
              <h2 className="text-2xl tracking-wide sm:text-brown-10">
                {t`Reset password`}
              </h2>
            </div>

            <div className="absolute bottom-0 left-1/2 -z-10 h-219 w-219 -translate-x-1/2 rounded-full bg-nature-07 sm:hidden" />
          </>
        )}
      </header>
      {passwordUpdated && (
        <>
          <div className="flex flex-col gap-6 px-14">
            <IconWrapper className="h-20 w-20">
              <icons.Success className="h-20 w-20" />
            </IconWrapper>
            <div className="flex flex-col gap-2 text-center sm:text-left">
              <h2 className="text-2xl tracking-wide sm:text-brown-10">
                {t`Password reset successfully`}
              </h2>
              <p className="font-light sm:font-normal">
                {t`Your password has been reset successfully!`}
                <br />
                {t`Please return to the main screen to log in.`}
              </p>
            </div>
            <Button
              size="lg"
              onClick={() => navigate(ROUTES.LOGIN)}
              className="w-full"
            >
              {t`Go to log in`}
            </Button>
          </div>
        </>
      )}
      {!passwordUpdated && (
        <form
          onSubmit={handleSubmit((values) =>
            resetPasswordMutation({ ...values, token }),
          )}
          className="flex flex-col gap-16 px-6 py-10 sm:gap-10 sm:px-14 sm:pt-0"
        >
          <div className="flex flex-col gap-4">
            <Input
              compact={false}
              id="set-password"
              type={showPassword ? "text" : "password"}
              label={t`New password`}
              placeholder={t`Your new password here`}
              left={<icons.LockOpen />}
              right={
                showPassword ? (
                  <icons.OpenedEye
                    onClick={() => setShowPassword(false)}
                    className="cursor-pointer"
                  />
                ) : (
                  <icons.ClosedEye
                    onClick={() => setShowPassword(true)}
                    className="cursor-pointer"
                  />
                )
              }
              {...register("password")}
              error={errors.password?.message}
            />
            <Input
              compact={false}
              id="set-password-confirmation"
              type={showPasswordConfirmation ? "text" : "password"}
              label={t`Confirm password`}
              placeholder={t`Confirm your password here`}
              left={<icons.LockOpen />}
              right={
                showPasswordConfirmation ? (
                  <icons.OpenedEye
                    onClick={() => setShowPasswordConfirmation(false)}
                    className="cursor-pointer"
                  />
                ) : (
                  <icons.ClosedEye
                    onClick={() => setShowPasswordConfirmation(true)}
                    className="cursor-pointer"
                  />
                )
              }
              {...register("passwordConfirmation")}
              error={errors.passwordConfirmation?.message}
            />
            <PasswordTips />
          </div>
          <div className="flex flex-col gap-3 py-2">
            <Button
              type="submit"
              className="w-full"
              size="lg"
              disabled={!isDirty || isPending}
            >
              {t`Save changes`}
            </Button>
            <Button
              size="lg"
              variant="tertiary"
              onClick={() => navigate(ROUTES.LOGIN)}
              className="w-full"
            >
              {t`Cancel`}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

import { useState } from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Navigate, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { SIZE, VARIANT } from "@/shared.constants";
import { useOnboardingStore } from "@/stores";
import { Badge, RadioIndicator } from "@/ui";
import { calculateAge, tw } from "@/utils";
import { OnboardingActionButtons } from "../OnboardingActionButtons";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";
import { JourneyBeginBackground } from "./Background";

const APPOINTMENT_REQUIRED_STATES = [
  "AR",
  "DC",
  "DE",
  "ID",
  "KS",
  "LA",
  "NM",
  "RI",
  "WV",
] as const;

const KICK_START = {
  APPOINTMENT: "appointment",
  DELIVERY: "delivery",
} as const;

const KICK_START_OPTIONS = [
  { value: KICK_START.DELIVERY, label: t`Order meds without visit` },
  { value: KICK_START.APPOINTMENT, label: t`Schedule video visit` },
] as const;

type StateValue = (typeof APPOINTMENT_REQUIRED_STATES)[number];
type KickStartValue = (typeof KICK_START)[keyof typeof KICK_START];

export const JourneyBeginCover = ({
  onContinue,
}: {
  onContinue: (value: KickStartValue) => void;
}) => {
  const navigate = useNavigate();
  const [purpose, setPurpose] = useState<KickStartValue>();
  const user = useOnboardingStore((state) => state.user);
  if (!user) {
    return <Navigate to={ROUTES.ONBOARDING.MAIN_INFO} />;
  }

  const { state, dateOfBirth } = user;
  const userAge = calculateAge(dateOfBirth);

  const requiresAppointmentByAge = userAge >= 65;
  const requiresAppointmentByState = APPOINTMENT_REQUIRED_STATES.includes(
    state as StateValue,
  );

  const appointmentRequired =
    requiresAppointmentByAge || requiresAppointmentByState;

  const canContinue = Boolean(purpose ?? appointmentRequired);

  return (
    <OnboardingLayout
      variant={VARIANT.SECONDARY}
      background={<JourneyBeginBackground />}
    >
      <div
        className={tw(
          "flex h-full grow flex-col items-center gap-4 sm:max-w-2xl sm:gap-12",
          appointmentRequired && "sm:gap-4",
        )}
      >
        <OnboardingHeader
          description={t`STEP 3`}
          title={
            appointmentRequired
              ? t`Begin your journey`
              : t`How would you like to kick-start your journey?`
          }
          variant={VARIANT.SECONDARY}
        />

        <div className="flex grow flex-col gap-4 sm:max-w-xl sm:gap-6">
          {!appointmentRequired && (
            <RadioGroupPrimitive.Root
              className="flex w-full flex-col gap-2 sm:gap-4"
              value={purpose}
              onValueChange={(value: KickStartValue) => setPurpose(value)}
            >
              {KICK_START_OPTIONS.map((option) => (
                <RadioGroupPrimitive.Item
                  key={option.value}
                  value={option.value}
                  id={option.value}
                  className="relative flex items-center gap-2 rounded-2xl border border-brown-05 bg-brown-01 p-4 py-5 hover:bg-brown-03 data-[state=checked]:bg-brown-04"
                >
                  <RadioIndicator />
                  <label
                    htmlFor={option.value}
                    className="flex grow cursor-pointer justify-between gap-0.5 text-left leading-5 text-brown-10"
                  >
                    {option.label}
                  </label>
                  {option.value === KICK_START.DELIVERY && (
                    <Badge
                      size={SIZE.MEDIUM}
                      className="absolute right-4 top-1/2 -translate-y-1/2"
                    >{t`Fastest`}</Badge>
                  )}
                </RadioGroupPrimitive.Item>
              ))}
            </RadioGroupPrimitive.Root>
          )}

          <div
            className={tw(
              "sm:text-xls h-full max-h-80 grow text-center text-lg text-nature-02 sm:h-fit sm:grow-0",
              !appointmentRequired && "h-fit",
            )}
          >
            {appointmentRequired ? (
              t`Unlock the door to your health journey with our platform. Schedule your inaugural video call with a medical professional, marking the beginning of your personalized treatment plan. Let's embark together on this path towards improved well-being and vitality.`
            ) : (
              <p className="text-lg text-nature-02 sm:text-xl">
                {t`Choose between first video visit or order medication directly. Flexibility is key; feel free to switch options as your treatment progresses!`}
                <br className="block py-3.5 content-['']" />
                {t`Whether you are looking for branded meds or compounded, you may choose either option above!`}
              </p>
            )}
          </div>

          <div className="w-full pb-10 sm:pt-2">
            <OnboardingActionButtons
              variant={VARIANT.SECONDARY}
              onCancel={() => {
                navigate(ROUTES.ONBOARDING.MAIN_INFO);
              }}
              continueDisabled={!canContinue}
              onContinue={() =>
                appointmentRequired
                  ? onContinue(KICK_START.APPOINTMENT)
                  : purpose && onContinue(purpose)
              }
            />
          </div>
        </div>
      </div>
    </OnboardingLayout>
  );
};

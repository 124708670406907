import { PAYMENT_STATUS } from "@/shared.constants";
import type { Payment } from "@/shared.types";
import { Badge, Card } from "@/ui";
import { formatBackendDate, tw } from "@/utils";
import { icons, IconWrapper } from "../../ui/common/Icons";

interface PaymentHistoryCardsProps {
  payments?: Payment[];
}

export const PaymentHistoryCards = ({ payments }: PaymentHistoryCardsProps) => {
  return (
    <div className="block w-full md:hidden">
      {payments?.map((payment) => (
        <Card
          key={payment.id}
          title="Membership payment"
          actions={
            <Badge
              className={tw(
                "rounded-full p-0 px-2 text-base capitalize",
                payment.status === PAYMENT_STATUS.SUCCESS &&
                  "border-green-07 bg-green-07",
                payment.status === PAYMENT_STATUS.PENDING &&
                  "border-yellow-07 bg-yellow-07",
                payment.status === PAYMENT_STATUS.FAILED &&
                  "border-red-07 bg-red-07",
              )}
            >
              {payment.status === PAYMENT_STATUS.FAILED && (
                <IconWrapper className="fill-red-07">
                  <icons.ExclamationCircle />
                </IconWrapper>
              )}
              {payment.status}
            </Badge>
          }
        >
          <p>${payment.amount}</p>

          <div className="flex justify-between">
            <p className="font-bold text-salmon-10">
              <span className="font-normal">Date: </span>
              {formatBackendDate(payment.date)}
            </p>

            {payment.hostedInvoiceUrl && (
              <button
                className="flex items-center gap-1 text-nature-08"
                onClick={() => window.open(payment.hostedInvoiceUrl)}
              >
                <IconWrapper>
                  <icons.Invoice />
                </IconWrapper>
                Invoice
              </button>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

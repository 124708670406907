import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { getFormQuery, submitHealthieForm } from "@/api";
import { FormField, FullScreenLoader, Loader } from "@/components";
import { env } from "@/env";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { Button, errorToast, icons, IconWrapper } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

export const FollowUp = () => {
  const { user } = useUserStore();
  const { control, handleSubmit, setError } = useForm();
  const { data: fields, isLoading } = useQuery({
    ...getFormQuery(env.VITE_HEALTHIE_FOLLOW_UP_FORM_ID, user?.id),
    select: (data) => data.fields,
  });

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const {
    mutate: submitFollowUpFormMutation,
    isPending: isPendingSubmitFollowUpFormMutation,
  } = useMutation({
    mutationFn: submitHealthieForm.mutation,
    onSuccess: () => {
      submitHealthieForm.invalidates(queryClient);
      navigate(ROUTES.CONSULTATIONS.SCHEDULE);
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  if (!user) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <form
      onSubmit={handleSubmit((value) =>
        submitFollowUpFormMutation({
          formId: env.VITE_HEALTHIE_FOLLOW_UP_FORM_ID,
          patientId: user.id,
          answers: value,
        }),
      )}
      className="flex max-w-2xl grow flex-col gap-8 pb-8 pt-1"
    >
      {fields?.map((field) => (
        <FormField key={field.id} formField={field} control={control} />
      ))}

      {!!fields?.length && (
        <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
          <Button
            onClick={() => navigate(ROUTES.CONSULTATIONS.PURPOSE)}
            variant="secondary"
            size="lg"
            disabled={isPendingSubmitFollowUpFormMutation}
          >
            <IconWrapper size="sm" className="hidden md:block">
              <icons.ChevronLeft />
            </IconWrapper>
            {t`Back`}
          </Button>
          <Button
            type="submit"
            size="lg"
            disabled={isPendingSubmitFollowUpFormMutation}
          >
            {t`Next`}
            <IconWrapper size="sm" className="hidden md:block">
              <icons.ChevronRight />
            </IconWrapper>
          </Button>
        </div>
      )}

      {isPendingSubmitFollowUpFormMutation && <FullScreenLoader />}
    </form>
  );
};

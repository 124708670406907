import { useEffect, useState } from "react";
import { useDebounce, useWindowSize } from "@uidotdev/usehooks";
import {
  Brush,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { DEFAULT_DEBOUNCE_TIME } from "@/shared.constants";
import type { WeightEntry } from "@/shared.types";
import { DocumentCircleFixedIconMono } from "@/ui";
import { CustomYAxisTick, WeightTooltipContent } from "./components";

const WIDTH_LG = 1024;
const MAX_BRUSH_DISTANCE = 2;
const MIN_ENTRIES = 5;

interface WeightEntryChartData extends WeightEntry {
  x: number;
  y: number;
}

interface WeightProgressChartProps {
  data: WeightEntryChartData[];
}

interface BrushStartEndIndex {
  startIndex?: number;
  endIndex?: number;
}

export const WeightProgressChart = ({ data }: WeightProgressChartProps) => {
  const { width } = useWindowSize();
  const debouncedWidth = useDebounce(width, DEFAULT_DEBOUNCE_TIME);

  const [tooltipActive, setTooltipActive] = useState(false);
  const [brushIndex, setBrushIndex] = useState<BrushStartEndIndex>();

  const dataLength = data.length;
  const showBrush = dataLength > MIN_ENTRIES;

  useEffect(() => {
    if (!showBrush || !debouncedWidth) {
      return;
    }

    setBrushIndex({
      startIndex: 0,
      endIndex: debouncedWidth < WIDTH_LG ? MIN_ENTRIES - 1 : dataLength - 1,
    });
  }, [debouncedWidth, showBrush, dataLength]);

  const onBrushChange = (index: BrushStartEndIndex) => {
    if (!brushIndex || !showBrush) {
      return;
    }

    const startIndex = index?.startIndex ?? 0;
    const endIndex = index?.endIndex ?? 0;
    const distance = Math.abs(endIndex - startIndex);

    if (distance > MAX_BRUSH_DISTANCE) {
      setBrushIndex(index);
    } else {
      setBrushIndex((prev) => ({
        startIndex:
          prev?.startIndex === startIndex
            ? startIndex
            : Math.max(endIndex - 2, 0),
        endIndex:
          prev?.endIndex === endIndex
            ? endIndex
            : Math.min(startIndex + 2, dataLength),
      }));
    }

    setTooltipActive(false);
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart
        data={data}
        onMouseLeave={() => setTooltipActive(false)}
        height={300}
        margin={{
          top: 30,
          right: 30,
          bottom: 16,
          left: -30,
        }}
      >
        <XAxis
          dataKey="x"
          scale={dataLength > 1 ? "linear" : "auto"}
          height={showBrush ? 40 : undefined}
          label={
            <Label
              position="insideTopRight"
              className="-translate-y-5 translate-x-6 pb-2 text-sm text-salmon-10"
            >
              t
            </Label>
          }
          tick={{ fill: "#6F5140", fontSize: 12 }}
          padding={{ left: 20 }}
        />

        <YAxis
          tickCount={6}
          dataKey="y"
          tickMargin={25}
          padding={{ top: 20 }}
          tick={<CustomYAxisTick />}
          label={
            <Label
              position="top"
              className="-translate-y-2 translate-x-8 pb-2 text-sm text-salmon-10"
            >
              wt (lb)
            </Label>
          }
        />
        <ZAxis type="number" range={[500]} />

        <Tooltip
          trigger="click"
          active={tooltipActive}
          offset={0}
          cursor={{
            strokeDasharray: "3 3",
          }}
          content={(tooltipData) => (
            <WeightTooltipContent
              tooltipData={tooltipData}
              onClose={() => setTooltipActive(false)}
            />
          )}
        />

        <Scatter
          name="weight"
          fill="#8C6955"
          line={{
            strokeDasharray: "5 4",
          }}
          shape={<DocumentCircleFixedIconMono className="cursor-pointer" />}
          onClick={() => setTooltipActive(true)}
        />

        {showBrush && (
          <Brush
            height={10}
            onChange={onBrushChange}
            tickFormatter={() => ""}
            startIndex={brushIndex?.startIndex}
            endIndex={brushIndex?.endIndex}
            stroke="#A59383"
            fill="#DDCEC0"
            className="[&>.recharts-layer.recharts-brush-traveller]:hidden [&>.recharts-layer.recharts-brush-traveller]:md:block"
          />
        )}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

import { useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "ttag";

import { uploadTempFile } from "@/api";
import { ACCEPTED_IMAGE_TYPES, MAX_UPLOAD_SIZE } from "@/shared.constants";
import type { UploadFilesValues } from "@/shared.types";
import {
  CheckIcon,
  IconWrapper,
  Input,
  Loader,
  Message,
  ReloadIcon,
  TrashBin2Icon,
  UploadedFile,
} from "@/ui";
import { formatBytes, tw } from "@/utils";
import {
  DOCUMENT_EDIT_NAME_LABEL,
  DOCUMENT_ICON,
} from "../components.constants";

interface FileUploadProps {
  fieldIndex: number;
  onRemove: () => void;
}

export const FileUpload = ({ fieldIndex, onRemove }: FileUploadProps) => {
  const {
    formState: { errors },
    control,
    register,
    setError,
    setValue,
    trigger,
    clearErrors,
  } = useFormContext<UploadFilesValues>();
  const field = useWatch({ control, name: `files.${fieldIndex}` });
  const folderName = useWatch({ control, name: `type` });
  const selected = {
    file: field.file,
    fileId: field.id,
  };

  const nameError = errors.files?.[fieldIndex]?.name?.message;
  const fileError = errors.files?.[fieldIndex]?.file;
  const error = fileError?.message ?? nameError;

  const filePreview = useMemo(
    () =>
      selected.file &&
      ACCEPTED_IMAGE_TYPES.includes(selected.file.type) &&
      URL.createObjectURL(selected.file),
    [selected.file],
  );

  const {
    mutate: uploadTempFileMutation,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: uploadTempFile.mutation,
    onSuccess: ({ fileId }) => {
      setValue(`files.${fieldIndex}.id`, fileId);
      clearErrors(`files.${fieldIndex}.id`);
    },
    onError: (err) => {
      console.error(err);
      setError(
        `files.${fieldIndex}.file`,
        {
          type: "backend",
          message: t`There was an error, and the file couldn't be uploaded correctly.`,
        },
        { shouldFocus: true },
      );
    },
  });

  useEffect(() => {
    if (error) {
      return;
    }
    if (selected.file.size > MAX_UPLOAD_SIZE) {
      void trigger(`files.${fieldIndex}.file`);
    } else if (!selected.fileId) {
      void trigger(`files.${fieldIndex}.id`);
      uploadTempFileMutation(selected.file);
    }
  }, [
    fieldIndex,
    selected.file,
    selected.fileId,
    error,
    trigger,
    uploadTempFileMutation,
  ]);

  const handleRetry = () => {
    if (fileError?.type === "backend" && selected.file) {
      uploadTempFileMutation(selected.file);
      clearErrors(`files.${fieldIndex}.file`);
    }
  };

  const handleRemove = () => {
    onRemove();
  };

  return (
    <div className="relative flex flex-col gap-2.5 rounded-2xl border border-brown-04 p-4 text-salmon-09">
      <IconWrapper
        onClick={handleRemove}
        size="sm"
        as="button"
        type="button"
        className="absolute right-5 top-4 text-red-07"
      >
        <TrashBin2Icon />
      </IconWrapper>

      <Input
        id={`files.${fieldIndex}.name`}
        label={DOCUMENT_EDIT_NAME_LABEL[folderName]}
        left={DOCUMENT_ICON[folderName]}
        {...register(`files.${fieldIndex}.name`)}
      />

      <UploadedFile.Root>
        <UploadedFile.Container className={tw(!!fileError && "bg-red-01")}>
          {filePreview &&
            (selected.file?.type.includes("image") ? (
              <UploadedFile.Preview
                src={filePreview}
                alt={selected.file.name}
                loading="lazy"
              />
            ) : (
              <embed
                src={filePreview}
                className="aspect-square h-full rounded-md object-cover"
              />
            ))}

          <div className="flex grow justify-between">
            {selected.file && (
              <UploadedFile.Content>
                <UploadedFile.Title>{selected.file.name}</UploadedFile.Title>

                <UploadedFile.Description>
                  {formatBytes(selected.file.size, { decimals: 2 })}
                </UploadedFile.Description>
              </UploadedFile.Content>
            )}

            <UploadedFile.Actions>
              {isPending && (
                <Loader className="size-6 fill-salmon-10 stroke-salmon-06" />
              )}

              {isError && (
                <IconWrapper
                  as="button"
                  type="button"
                  size="md"
                  onClick={handleRetry}
                  className="cursor-pointer"
                >
                  <ReloadIcon />
                </IconWrapper>
              )}

              {isSuccess && (
                <IconWrapper
                  size="md"
                  className="rounded-full border-2 border-salmon-06 bg-salmon-05 text-salmon-10"
                >
                  <CheckIcon />
                </IconWrapper>
              )}
            </UploadedFile.Actions>
          </div>
        </UploadedFile.Container>

        {error && <Message error={error} />}
      </UploadedFile.Root>
    </div>
  );
};

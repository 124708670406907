import type { QueryClient } from "@tanstack/react-query";

import type { Coupon, Payment, PaymentMethod } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";

const DOMAIN = "stripe";

interface CreateStripeSubscriptionParams {
  userId: number;
  couponCode: string | null;
}

export const createStripeSubscription = {
  mutation: async ({ userId, couponCode }: CreateStripeSubscriptionParams) => {
    const { data } = await publicAPI.post<
      ServiceResponse<{ subscriptionId: string; clientSecret: string }>
    >(`/patients/${userId}/create-subscription`, { couponCode });

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const getPaymentMethodQuery = () => ({
  queryKey: [DOMAIN, "getPaymentMethodQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<PaymentMethod>>(
      `/patients/payment-method`,
    );

    return data.data;
  },
});

export const updatePaymentMethod = {
  mutation: async (params: { paymentMethodId?: string; userId?: number }) => {
    const response = await privateAPI.put<ServiceResponse<void>>(
      `/patients/payment-method`,
      params,
    );

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const scheduleSubscription = {
  mutation: async ({ paymentMethodId }: { paymentMethodId?: string }) => {
    const response = await privateAPI.post<ServiceResponse<void>>(
      `/patients/schedule-subscription`,
      { paymentMethodId },
    );

    return response.data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: ["patient"] });
  },
};

export const getPaymentHistoryQuery = (lastPayment: string | undefined) => ({
  queryKey: [DOMAIN, "getPaymentHistoryQuery", lastPayment],
  queryFn: async () => {
    const response = await privateAPI.get<ServiceResponse<Payment[]>>(
      `/patients/payment-information`,
      { params: { startAfter: lastPayment } },
    );

    return response.data;
  },
});

export const applyCode = {
  mutation: async (couponCode?: string) => {
    const {
      data: { data },
    } = await publicAPI.post<ServiceResponse<Coupon>>(`/validate-coupon-code`, {
      couponCode,
    });

    return data;
  },
};

import type { TimeSlot } from "@/shared.types";
import { formatAvailableTime, tw } from "@/utils";
import { useScheduleContext } from "../useSchedule";

interface TimeSlotItemProps {
  timeSlot: TimeSlot;
}

export const TimeSlotItem = ({ timeSlot }: TimeSlotItemProps) => {
  const { selectedAppointment, onSelectAppointment } = useScheduleContext();

  return (
    <button
      key={timeSlot.dateTime}
      type="button"
      className={tw(
        "h-fit w-32 rounded-l-full rounded-r-full bg-brown-02 py-2 text-center text-sm font-medium text-brown-09 duration-150 hover:bg-salmon-09 hover:text-salmon-02",
        timeSlot.dateTime === selectedAppointment?.dateTime &&
          "bg-salmon-10 text-salmon-02",
      )}
      onClick={() => onSelectAppointment(timeSlot)}
    >
      {`${formatAvailableTime(timeSlot.dateTime)} ${timeSlot.timezone}`}
    </button>
  );
};

import { shapes } from "@/ui/common/Shapes";

const Row = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex w-full flex-1 items-center justify-between gap-5">
      {children}
    </div>
  );
};

export const Background = () => {
  return (
    <div className="sm:flex sm:flex-col sm:justify-center">
      <div className="flex flex-col gap-2 bg-nature-10 px-8 py-5">
        <Row>
          <shapes.RoundedRectangle shapeColor="tertiary" direction="bottom" />
          <shapes.CompositeSquareBy3
            shapeColor="tertiary"
            animated
            direction="bottom"
            shapeVariant="secondary"
          />
          <shapes.CompositeSquareBy2
            shapeColor="tertiary"
            direction="bottom"
            shapeVariant="tertiary"
          />
          <shapes.CompositeCircleBy2 shapeColor="tertiary" />
        </Row>

        <Row>
          <shapes.Doughnut shapeColor="tertiary" />
          <shapes.PartialDoughnut shapeColor="tertiary" direction="bottom" />
          <shapes.CompositeCircleBy2 shapeColor="tertiary" direction="right" />
          <shapes.CompositeSquareBy2
            shapeColor="tertiary"
            animated
            direction="left"
            shapeVariant="secondary"
          />
        </Row>

        <Row>
          <shapes.RoundedRectangle shapeColor="tertiary" />
          <shapes.CompositeSquareBy2
            shapeColor="tertiary"
            direction="bottom"
            shapeVariant="tertiary"
          />
          <shapes.CompositeSquareBy3 shapeColor="tertiary" direction="bottom" />
          <shapes.Doughnut shapeColor="tertiary" />
        </Row>

        <Row>
          <shapes.CompositeSquareBy2 shapeColor="tertiary" direction="right" />
          <shapes.PartialDoughnut2 shapeColor="tertiary" animated />
          <shapes.CompositeSquareBy2
            shapeColor="tertiary"
            direction="left"
            shapeVariant="tertiary"
          />
          <shapes.CompositeSquareBy2 shapeColor="tertiary" direction="bottom" />
        </Row>

        <Row>
          <shapes.CompositeSquareBy3
            shapeColor="tertiary"
            animated
            direction="bottom"
            shapeVariant="tertiary"
          />
          <shapes.CompositeSquareBy2
            shapeColor="tertiary"
            direction="bottom"
            shapeVariant="secondary"
          />
          <shapes.PartialDoughnut2 shapeColor="tertiary" direction="right" />
          <shapes.CompositeSquareBy2
            shapeColor="tertiary"
            direction="left"
            shapeVariant="secondary"
          />
        </Row>
      </div>
    </div>
  );
};

import type {
  ComponentProps,
  ComponentPropsWithoutRef,
  ReactNode,
} from "react";
import { forwardRef, Fragment } from "react";
import { Slot } from "@radix-ui/react-slot";
import { Link } from "react-router-dom";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { tw } from "@/utils";
import { AltArrowRightIcon } from "../icons";
import type { BreadCrumbLink } from "../ui.types";
import { IconWrapper } from "./Icons";

const BreadcrumbRoot = forwardRef<
  HTMLElement,
  ComponentPropsWithoutRef<"nav"> & {
    separator?: ReactNode;
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />);
BreadcrumbRoot.displayName = "BreadcrumbRoot";

const BreadcrumbList = forwardRef<
  HTMLOListElement,
  ComponentPropsWithoutRef<"ol">
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={tw(
      "flex flex-wrap items-center gap-1.5 break-words text-sm text-brown-08 sm:gap-2.5",
      className,
    )}
    {...props}
  />
));
BreadcrumbList.displayName = "BreadcrumbList";

const BreadcrumbItem = forwardRef<
  HTMLLIElement,
  ComponentPropsWithoutRef<"li">
>(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={tw("inline-flex items-center gap-1.5", className)}
    {...props}
  />
));
BreadcrumbItem.displayName = "BreadcrumbItem";

const BreadcrumbLink = forwardRef<
  HTMLAnchorElement,
  ComponentPropsWithoutRef<"a"> & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";

  return (
    <Comp
      ref={ref}
      className={tw(
        "font-medium transition-colors hover:text-brown-10",
        className,
      )}
      {...props}
    />
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";

const BreadcrumbPage = forwardRef<
  HTMLSpanElement,
  ComponentPropsWithoutRef<"span">
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    role="link"
    aria-disabled="true"
    aria-current="page"
    className={tw("font-bold text-brown-10", className)}
    {...props}
  />
));
BreadcrumbPage.displayName = "BreadcrumbPage";

const BreadcrumbSeparator = ({
  children,
  className,
  color,
  ...props
}: ComponentProps<"li"> & { color?: Variant }) => (
  <li
    role="presentation"
    aria-hidden="true"
    className={tw(className)}
    {...props}
  >
    {children ?? (
      <IconWrapper
        size="xs"
        className={tw(
          color === VARIANT.PRIMARY && "text-salmon-01",
          color === VARIANT.SECONDARY && "text-brown-08",
        )}
      >
        <AltArrowRightIcon />
      </IconWrapper>
    )}
  </li>
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

const BreadcrumbEllipsis = ({
  className,
  ...props
}: ComponentProps<"span">) => (
  <span
    role="presentation"
    aria-hidden="true"
    className={tw("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    ...
    <span className="sr-only">More</span>
  </span>
);
BreadcrumbEllipsis.displayName = "BreadcrumbEllipsis";

export interface BreadcrumbProps {
  links: BreadCrumbLink[];
  color?: Variant;
  className?: string;
}
export const Breadcrumb = ({
  links,
  color = VARIANT.PRIMARY,
  className,
}: BreadcrumbProps) => {
  return (
    <BreadcrumbRoot className={className}>
      <BreadcrumbList>
        {links.map((link, i) =>
          i + 1 !== links.length ? (
            <Fragment key={link.href}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link
                    to={link.href}
                    className={tw(
                      "hidden items-center gap-1 focus:underline focus:underline-offset-1 focus:outline-none md:flex",
                      color === VARIANT.PRIMARY &&
                        "text-salmon-01 hover:text-salmon-03",
                      color === VARIANT.SECONDARY &&
                        "text-brown-08 hover:text-brown-10",
                    )}
                  >
                    {link.icon && (
                      <IconWrapper
                        size="sm"
                        className={tw(
                          color === VARIANT.PRIMARY && "fill-nature-01",
                          color === VARIANT.SECONDARY && "fill-brown-07",
                        )}
                      >
                        {typeof link.icon === "function"
                          ? link.icon({ className })
                          : link.icon}
                      </IconWrapper>
                    )}
                    {link.label}
                  </Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
            </Fragment>
          ) : (
            <BreadcrumbItem key={link.href}>
              <BreadcrumbPage
                className={tw(
                  "hidden items-center gap-1 md:flex",
                  color === VARIANT.PRIMARY && "text-salmon-01",
                  color === VARIANT.SECONDARY && "text-brown-10",
                )}
              >
                {link.icon && (
                  <IconWrapper
                    size="sm"
                    className={tw(
                      color === VARIANT.PRIMARY && "fill-nature-01",
                      color === VARIANT.SECONDARY && "fill-brown-07",
                    )}
                  >
                    {typeof link.icon === "function"
                      ? link.icon({ className })
                      : link.icon}
                  </IconWrapper>
                )}
                {link.label}
              </BreadcrumbPage>
            </BreadcrumbItem>
          ),
        )}
      </BreadcrumbList>
    </BreadcrumbRoot>
  );
};

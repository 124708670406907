import { t } from "ttag";

import { illustratedIcons } from "@/ui";

export const TimeSlotPromptMessage = () => (
  <div className="flex grow flex-col items-center justify-center gap-2">
    <illustratedIcons.Schedule />
    <p className="text-xl font-medium text-brown-05">{t`Choose a date to view available times`}</p>
  </div>
);

import type { DPMonth } from "@rehookify/datepicker";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./DPSelect";

interface MonthSelectProps {
  months: DPMonth[];
  month: number;
  onChange: (month: number) => void;
}

export const MonthSelect = ({ months, month, onChange }: MonthSelectProps) => {
  return (
    <Select
      value={month.toString()}
      onValueChange={(value) => onChange(parseInt(value))}
    >
      <SelectTrigger id="month-selector-trigger">
        <SelectValue placeholder="Select year..." />
      </SelectTrigger>
      <SelectContent align="center">
        {months.map((dPMonth) => (
          <SelectItem
            key={dPMonth.$date.toDateString()}
            value={dPMonth.$date.getMonth().toString()}
          >
            {dPMonth.month}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

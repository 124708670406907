import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getAvailableTimesQuery } from "@/api";
import { useOnboardingStore } from "@/stores";
import { Card } from "@/ui";
import { formatBackendDateToVerbose, tw } from "@/utils";
import { useScheduleContext } from "../useSchedule";
import { TimeSlotErrorMessage } from "./ErrorMessage";
import { TimeSlotPromptMessage } from "./PromptMessage";
import { TimeSlotsSkeleton } from "./Skeleton";
import { TimeSlotItem } from "./TimeSlotItem";

export const TimeSlotSection = ({
  canChooseProvider = true,
}: {
  canChooseProvider?: boolean;
}) => {
  const { selectedDate, canUseCalendar, selectedProvider } =
    useScheduleContext();

  const userId = useOnboardingStore((state) => state.user?.id);
  const {
    data: availableTimeSlots,
    isLoading: isLoadingAvailableTimeSlots,
    isError: isTimeSlotsError,
  } = useQuery({
    ...getAvailableTimesQuery({
      date: selectedDate,
      providerId: selectedProvider?.id,
      userId,
    }),
  });

  const showErrorMessage = isTimeSlotsError || availableTimeSlots?.length === 0;
  const showPrompt =
    !availableTimeSlots && !showErrorMessage && !isLoadingAvailableTimeSlots;

  return (
    <Card
      title={t`${canChooseProvider ? "3" : "2"}. Available times${
        selectedDate ? ` on ${formatBackendDateToVerbose(selectedDate)}` : ""
      }`}
      className={tw(
        "grow overflow-hidden bg-transparent p-0",
        !canUseCalendar && "pointer-events-none opacity-50",
      )}
    >
      <div className="flex h-32 grow flex-col sm:min-h-0">
        {isLoadingAvailableTimeSlots && <TimeSlotsSkeleton />}
        {showErrorMessage && <TimeSlotErrorMessage />}
        {showPrompt && <TimeSlotPromptMessage />}

        {availableTimeSlots && (
          <div className="flex grow flex-col gap-4 overflow-y-auto">
            <div className="flex flex-wrap gap-2.5">
              {availableTimeSlots.map((timeSlot) => (
                <TimeSlotItem key={timeSlot.dateTime} timeSlot={timeSlot} />
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { SIZE } from "@/shared.constants";
import type { ModalProps } from "@/shared.types";
import { Button, icons, IconWrapper, illustratedIcons, Modal } from "@/ui";

export const WellBeing = ({ show, onClose }: ModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onClose={onClose}
      className="flex w-full max-w-[61rem] flex-col items-start gap-5"
    >
      <div className="flex flex-col gap-4 overflow-auto">
        <div className="flex flex-col gap-2">
          <IconWrapper className="size-12">
            <illustratedIcons.Hearts />
          </IconWrapper>
          <h3 className="font-serif text-3xl text-salmon-10">{t`Prioritizing your well-being`}</h3>
          <p className="text-brown-09">
            {t`Our commitment is to ensure you receive the most appropriate and evidence-based care from our team of medical professionals.`}
            <strong>{t` Unfortunately, the behavioral health history that you have identified is not considered to be best practice when treated through telehealth.`}</strong>
            <br className="block pt-5 content-['']" />
            {t`In such cases, prioritizing your safety, we recommend seeking care from a trusted local provider for more beneficial results. We apologize for any inconvenience this may cause and truly value the opportunity you've given us to support you. `}
          </p>
        </div>

        <div className="w-full border border-brown-04" />

        <div className="flex flex-col justify-start gap-3 text-brown-10">
          <h4 className="font-medium text-salmon-10 md:text-sm">{t`To assist you further, we've compiled a list of resources tailored to your potential needs and concerns mentioned during your visit:`}</h4>

          <div className="flex grid-cols-3 flex-col gap-2 md:grid">
            <div className="flex flex-col gap-1 rounded-lg bg-brown-02 p-2 pb-3.5 md:min-h-32">
              <header className="flex items-center gap-1">
                <IconWrapper size={SIZE.X_SMALL}>
                  <icons.DevicePhone />
                </IconWrapper>
                <h5 className="font-medium md:text-xs">{t`Phone call`}</h5>
              </header>
              <ul className="list-outside list-disc pl-6 text-sm md:text-xs">
                <li>{t`988 - Crisis Support Lifeline`}</li>
                <li>{t`1-800-273-8255 - National Crisis Support`}</li>
                <li>{t`1-866-488-7386 or text START to 678678. A national 24-hour, toll free confidential suicide hotline for LGBTQ youth`}</li>
              </ul>
            </div>

            <div className="flex flex-col gap-1 rounded-lg bg-brown-02 p-2 pb-3.5 md:min-h-32">
              <header className="flex items-center gap-1">
                <IconWrapper size={SIZE.X_SMALL}>
                  <icons.ChatLine />
                </IconWrapper>
                <h5 className="font-medium md:text-xs">{t`Text`}</h5>
              </header>
              <ul className="list-outside list-disc pl-6 text-sm md:text-xs">
                <li>{t`Text MHA to 741741 and you will be connected to a trained Crisis Counselor. Crisis Text Line provides free, text-based support 24/7.`}</li>
                <li>{t`Text 988 for immediate assistance.`}</li>
              </ul>
            </div>

            <div className="flex flex-col gap-1 rounded-lg bg-brown-02 p-2 pb-3.5 md:min-h-32">
              <header className="flex items-center gap-1">
                <IconWrapper size={SIZE.X_SMALL}>
                  <icons.Web />
                </IconWrapper>
                <h5 className="font-medium md:text-xs">{t`Phone call`}</h5>
              </header>
              <ul className="list-outside list-disc pl-6 text-sm md:text-xs">
                <li>
                  <a
                    href="https://988lifeline.org/chat/"
                    className="underline underline-offset-2"
                  >{t`988 Lifeline Chat Online`}</a>
                </li>
                <li>
                  <a
                    href="https://988lifeline.org/"
                    className="underline underline-offset-2"
                  >{t`988 Lifeline`}</a>
                </li>
                <li>
                  <a
                    href="https://www.thetrevorproject.org/"
                    className="underline underline-offset-2"
                  >{t`The Trevor Project`}</a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-1 rounded-lg bg-brown-02 p-2 pb-3.5 md:min-h-32">
              <header className="flex items-center gap-1">
                <IconWrapper size={SIZE.X_SMALL}>
                  <icons.User />
                </IconWrapper>
                <h5 className="font-medium md:text-xs">{t`Reduced cost in-person therapy in your area:`}</h5>
              </header>
              <ul className="list-outside list-disc pl-6 text-sm md:text-xs">
                <li>
                  <a
                    href="https://openpathcollective.org/"
                    className="underline underline-offset-2"
                  >{t`Open Path Collective: Find your therapist`}</a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-1 rounded-lg bg-brown-02 p-2 pb-3.5 md:min-h-32">
              <header className="flex items-center gap-1">
                <IconWrapper size={SIZE.X_SMALL}>
                  <icons.MapPoint />
                </IconWrapper>
                <h5 className="font-medium md:text-xs">{t`Find an in-person provider in your local area:`}</h5>
              </header>
              <ul className="list-outside list-disc pl-6 text-sm md:text-xs">
                <li>
                  <a
                    href="https://www.psychologytoday.com/us"
                    className="underline underline-offset-2"
                  >{t`Psychology Today US`}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Button
        variant="primary"
        size="lg"
        className="w-full"
        onClick={() => navigate(ROUTES.MESSAGES)}
      >
        {t`Contact Fridays support`}
      </Button>
    </Modal>
  );
};

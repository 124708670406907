import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { getPatientQuery, scheduleAppointment } from "@/api";
import { FullScreenLoader, Loader, Schedule } from "@/components";
import { ROUTES } from "@/router";
import { icons, ScreenHeader } from "@/ui";
import { ErrorAlert } from "./ErrorAlert";
import { SuccessAlert } from "./SuccessAlert";

export const ConsultationSchedule = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { data: patientState, isLoading: isLoadingPatientState } = useQuery({
    ...getPatientQuery(),
    select: (data) => data.location?.state,
  });

  const queryClient = useQueryClient();
  const {
    mutate: scheduleAppointmentMutation,
    isPending: isPendingScheduleAppointment,
    variables: sentAppointment,
  } = useMutation({
    mutationFn: scheduleAppointment.mutation,
    onSuccess: () => {
      scheduleAppointment.invalidates(queryClient);
      setShowSuccessModal(true);
    },
    onError: () => setShowErrorModal(true),
  });

  return (
    <div className="relative flex w-full flex-col gap-2 px-4 pt-4 md:px-8 md:pt-12 lg:px-16">
      <ScreenHeader
        variant="secondary"
        title={t`New Consultation`}
        paths={[
          {
            href: ROUTES.CONSULTATIONS.OVERVIEW,
            icon: icons.Calendar,
            label: t`Consultations`,
          },
          {
            href: ROUTES.CONSULTATIONS.SCHEDULE,
            icon: icons.AddCircle,
            label: t`Schedule new consultation`,
          },
        ]}
      />

      {isLoadingPatientState && <Loader />}

      {patientState && (
        <Schedule
          state={patientState}
          onConfirm={scheduleAppointmentMutation}
        />
      )}

      <ErrorAlert
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />

      {sentAppointment && (
        <SuccessAlert
          show={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          appointment={sentAppointment}
        />
      )}

      {isPendingScheduleAppointment && <FullScreenLoader />}
    </div>
  );
};

import { useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";

import {
  AlreadyScheduled,
  BestFit,
  CreateWeightEntry,
  DeleteWeightEntry,
  EditDocument,
  EditWeightEntry,
  LabUploadsModal,
  MedicalRecordUploadsModal,
  MedicationRefills,
  NextStepsModal,
  PrescriptionUploadsModal,
  PreviewDocument,
  RefillAlreadyScheduled,
  RemoveDocument,
  UrgentCare,
  VideoConsultations,
  WellBeing,
} from "@/modals";
import { MODAL_ROUTES } from "./routes";

export const ModalRouter = ({ showModal }: { showModal: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const nodeRef = useRef(null);

  return (
    <TransitionGroup>
      <Transition key={location.pathname} timeout={600} nodeRef={nodeRef}>
        {(state) => {
          const show = state !== "exited" && state !== "exiting";

          if (!showModal) {
            return null;
          }

          const goBack = () => state !== "exiting" && navigate(-1);

          return (
            <div ref={nodeRef}>
              <Routes location={location}>
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.MEDICATION_REFILLS}`}
                  element={<MedicationRefills show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.VIDEO_CONSULTATIONS}`}
                  element={<VideoConsultations show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.ALREADY_SCHEDULED}`}
                  element={<AlreadyScheduled show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.URGENT_CARE}`}
                  element={<UrgentCare show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.BEST_FIT}`}
                  element={<BestFit show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.WELL_BEING}`}
                  element={<WellBeing show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.CONSULTATIONS.REFILL_ALREADY_SCHEDULED}`}
                  element={
                    <RefillAlreadyScheduled show={show} onClose={goBack} />
                  }
                />

                {/* Document Center Modals */}
                <Route
                  path={`${MODAL_ROUTES.DOCUMENT_CENTER.LABS}`}
                  element={<LabUploadsModal show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS}`}
                  element={
                    <PrescriptionUploadsModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS}`}
                  element={
                    <MedicalRecordUploadsModal show={show} onClose={goBack} />
                  }
                />
                <Route
                  path={`${MODAL_ROUTES.DOCUMENT_CENTER.REMOVE_DOCUMENT}/:folder/:documentId`}
                  element={<RemoveDocument show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.DOCUMENT_CENTER.EDIT_DOCUMENT}/:folder/:documentId`}
                  element={<EditDocument show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.DOCUMENT_CENTER.PREVIEW_DOCUMENT}/:folder/:documentId`}
                  element={<PreviewDocument show={show} onClose={goBack} />}
                />

                {/* Progress Tracker Modals */}
                <Route
                  path={`${MODAL_ROUTES.PROGRESS_TRACKER.CREATE_ENTRY}`}
                  element={<CreateWeightEntry show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.PROGRESS_TRACKER.EDIT_ENTRY}/:entryId`}
                  element={<EditWeightEntry show={show} onClose={goBack} />}
                />
                <Route
                  path={`${MODAL_ROUTES.PROGRESS_TRACKER.DELETE_ENTRY}/:entryId`}
                  element={<DeleteWeightEntry show={show} onClose={goBack} />}
                />

                <Route
                  path={`${MODAL_ROUTES.NEXT_STEPS}`}
                  element={<NextStepsModal show={show} onClose={goBack} />}
                />
              </Routes>
            </div>
          );
        }}
      </Transition>
    </TransitionGroup>
  );
};

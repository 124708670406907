import type { QueryClient } from "@tanstack/react-query";

import type {
  Consultation,
  ISODate,
  Provider,
  SelectedAppointment,
  TimeSlot,
  USState,
} from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";
import { HOME_DOMAIN } from "./home";

export const CONSULTATIONS_DOMAIN = "consultations";

export const getPastConsultationsQuery = (consultationsOffset: number) => ({
  queryKey: [
    CONSULTATIONS_DOMAIN,
    "getPastConsultationsQuery",
    consultationsOffset,
  ],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<Consultation[]>>(
      "/appointments",
      { params: { offset: consultationsOffset, upcomingAppointments: false } },
    );

    return data;
  },
});

export const getUpcomingConsultationsQuery = () => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getUpcomingConsultationsQuery"],
  queryFn: async () => {
    const {
      data: { data },
    } = await privateAPI.get<ServiceResponse<Consultation[]>>(
      `/appointments?upcomingAppointments=true`,
    );

    return data;
  },
});

export const getProvidersQuery = (params: { state?: USState["value"] }) => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getProvidersQuery", params.state],
  queryFn: async () => {
    const {
      data: { data },
    } = await publicAPI.get<ServiceResponse<Provider[]>>(`/providers`, {
      params,
    });

    return data;
  },
});

export const getAvailableDaysQuery = (params: {
  dateFromMonth: string;
  userId?: number;
  providerId?: string;
}) => ({
  queryKey: [CONSULTATIONS_DOMAIN, "getAvailableDaysQuery", { ...params }],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<ISODate[]>>(
      `/providers/available-days-in-month`,
      { params },
    );

    return data.data;
  },
});

export const getAvailableTimesQuery = (params: {
  date: string | null;
  userId?: number;
  providerId?: string;
}) => ({
  enabled: !!params.date,
  queryKey: [CONSULTATIONS_DOMAIN, "getAvailableTimesQuery", { ...params }],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<TimeSlot[]>>(
      `/providers/time-slots`,
      { params },
    );

    return data.data;
  },
});

export const scheduleAppointment = {
  mutation: async (params: SelectedAppointment) => {
    const { data } = await privateAPI.post<ServiceResponse<unknown>>(
      "/appointments",
      {
        providerId: params.providerId,
        appointmentDate: `${params.dateTime} ${params.utcDiff}`,
      },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [CONSULTATIONS_DOMAIN] });
  },
};

export const cancelAppointment = {
  mutation: async (appointmentId: string) => {
    const { data } = await privateAPI.delete<ServiceResponse<unknown>>(
      `/appointments/${appointmentId}`,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({
      queryKey: [CONSULTATIONS_DOMAIN, "getUpcomingConsultationsQuery"],
    });
    void queryClient.invalidateQueries({
      queryKey: [HOME_DOMAIN],
    });
  },
};

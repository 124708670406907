import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { AuthData } from "@/api";
import type { User } from "@/shared.types";

export interface UserStoreState {
  user: User | null;
  token: string | null;
  needsChangePassword: boolean;
  setAuth: (authData: AuthData) => void;
  setToken: (token: string | null) => void;
  clearUser: () => void;
  setUser: (user: User | null) => void;
  setNeedsChangePassword: (needsChangePassword: boolean) => void;
}

export const useUserStore = create<UserStoreState>()(
  persist(
    (set) => ({
      user: null,
      patient: null,
      token: null,
      needsChangePassword: false,
      setToken: (token: string | null) => {
        set(() => ({ token }));
      },
      setAuth: (authData) => {
        set(() => ({
          token: authData.accessToken,
          user: authData.user,
          needsChangePassword: authData.needsChangePassword,
        }));
      },
      clearUser: () => {
        set({ user: null, token: null });
      },
      setUser: (user) => {
        set({ user });
      },
      setNeedsChangePassword: (needsChangePassword) => {
        set({ needsChangePassword });
      },
    }),
    {
      name: "feedbackUserStore",
    },
  ),
);

import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { cva } from "class-variance-authority";

import { SIZE } from "@/shared.constants";
import type { Size } from "@/shared.types";
import { tw } from "@/utils";

const labelVariance = cva(
  "flex items-center gap-1 text-sm font-medium text-salmon-10",
  {
    variants: {
      size: {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-md",
        lg: "text-lg",
        xl: "text-xl",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  },
);

export interface LabelProps extends ComponentPropsWithoutRef<"label"> {
  children: ReactNode;
  size?: Size;
}

export const Label = ({
  children,
  className,
  size = SIZE.SMALL,
  ...props
}: LabelProps) => (
  <label {...props} className={tw(labelVariance({ size, className }))}>
    {children}
  </label>
);

import { useNavigate } from "react-router";
import { t } from "ttag";

import { ROUTES } from "@/router";
import type { ModalProps, SelectedAppointment } from "@/shared.types";
import { AlertDialog, Button, icons } from "@/ui";
import { formatAvailableTime, formatToFrontendDate } from "@/utils";

interface SuccessAlertProps extends ModalProps {
  appointment: SelectedAppointment;
}

export const SuccessAlert = ({
  appointment,
  show,
  onClose,
}: SuccessAlertProps) => {
  const { providerName, dateTime, timezone } = appointment;
  const navigate = useNavigate();
  const handleClose = () => {
    onClose();
    navigate(ROUTES.HOME);
  };

  const appointmentDate = new Date(dateTime);

  return (
    <AlertDialog
      show={show}
      onClose={handleClose}
      title={t`Consultation successfully created!`}
      renderActions={({ AlertDialogAction }) => (
        <div className="relative flex w-full flex-col gap-4 rounded-lg bg-salmon-02 p-8">
          <div className="absolute left-0 top-0 h-10 w-10 -translate-x-1.5 -translate-y-1.5 rounded-full bg-salmon-07 fill-salmon-01 p-3">
            <icons.VideoCamera />
          </div>

          <div className="flex flex-col gap-1.5 text-brown-10">
            <h4 className="text-lg font-semibold">{t`Session with ${providerName}`}</h4>
            <p className="font-medium">
              {formatToFrontendDate(appointmentDate)}
            </p>
            <p>{`${formatAvailableTime(dateTime)} ${timezone}`}</p>
          </div>

          <AlertDialogAction asChild>
            <Button variant="secondary" className="w-fit">
              Back to home
            </Button>
          </AlertDialogAction>
        </div>
      )}
    />
  );
};

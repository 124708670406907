import { t } from "ttag";

import type { ModalProps } from "@/shared.types";
import { icons, IconWrapper, illustratedIcons, Modal } from "@/ui";

export const VideoConsultations = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<illustratedIcons.Video />}
      title={t`Video Consultations`}
      description={
        <p>
          {t`You will schedule an appointment to be seen by your provider.`}
          <br />
          {t`They will review the intake information live with you and discuss next steps on your weight management journey!`}
        </p>
      }
    >
      <div className="flex items-center gap-2 rounded-2xl bg-brown-03 p-4.5">
        <IconWrapper size="sm" className="fill-nature-10">
          <icons.Calendar />
        </IconWrapper>
        <p className="text-sm font-medium text-nature-10">{t`If you already have your follow up scheduled you don't have to schedule a new session`}</p>
      </div>
    </Modal>
  );
};

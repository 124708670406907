import type { ReactNode } from "react";

import { Loader } from "@/components";
import { tw } from "@/utils";

interface FullScreenLoaderProps {
  description?: ReactNode;
}

export const FullScreenLoader = ({ description }: FullScreenLoaderProps) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
    <div
      className={tw(
        "flex flex-col items-center gap-8 px-6",
        !description && "-translate-y-1/2",
      )}
    >
      <Loader className="static" iconClassName="h-20" />

      {description}
    </div>
  </div>
);

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { createUser, getStatesQuery } from "@/api";
import { FullScreenLoader } from "@/components";
import { EXTERNAL_LINK, ROUTES } from "@/router";
import { LANGUAGE_OPTIONS } from "@/shared.constants";
import { onboardingPatientSchema } from "@/shared.schemas";
import type { OnboardingPatientFormValues } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { Checkbox, HookedSelect, icons, Input } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";
import { OnboardingActionButtons } from "../OnboardingActionButtons";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";
import { VALIDATION_ERROR } from "./mainInfo.constants";
import type { ValidationError } from "./mainInfo.types";

interface MainInfoFormProps {
  onValidation: (validationError: ValidationError) => void;
  onGoBack: () => void;
}

export const MainInfoForm = ({ onValidation, onGoBack }: MainInfoFormProps) => {
  const [hasConsent, setHasConsent] = useState<CheckedState>(false);
  const setUser = useOnboardingStore((state) => state.setUser);
  const savedUser = useOnboardingStore((state) => state.user);

  const { data: stateOptions, isLoading: isLoadingStates } =
    useQuery(getStatesQuery());

  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    setError,
  } = useForm<OnboardingPatientFormValues>({
    resolver: zodResolver(onboardingPatientSchema),
    defaultValues: { language: "en", hasMedicaidCoverage: false },
    ...(savedUser
      ? {
          values: {
            ...savedUser,
            emailConfirmation: "",
            language: "en",
            hasMedicaidCoverage: false,
          },
        }
      : {}),
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: createUserMutation, isPending } = useMutation({
    mutationFn: createUser.mutation,
    onSuccess: (data) => {
      createUser.invalidates(queryClient);
      setUser(data);
      navigate(ROUTES.ONBOARDING.JOURNEY_BEGIN);
    },
    onError: (error) => {
      handleAxiosFieldErrors(error, setError);
    },
  });

  const preSubmitValidation = (values: OnboardingPatientFormValues) => {
    if (!savedUser) {
      return;
    }

    const { hasMedicaidCoverage } = values;

    if (hasMedicaidCoverage) {
      onValidation(VALIDATION_ERROR.HAS_MEDICAID);
    } else {
      createUserMutation({ values, id: savedUser.id });
    }
  };

  return (
    <OnboardingLayout>
      {isPending && <FullScreenLoader />}
      <div className="flex w-full flex-col gap-6 pb-10 sm:items-center sm:gap-12 sm:pb-20">
        <OnboardingHeader
          title={t`Tell us about yourself`}
          description={t`(*) Mandatory field`}
        />

        <form className="flex w-full max-w-3xl flex-col gap-3.5 sm:gap-6">
          <div className="grid gap-x-5 gap-y-1.5 sm:grid-cols-2">
            <Input
              compact={false}
              id="first-name"
              label={t`First name *`}
              placeholder={t`E.g. John`}
              left={<icons.User />}
              {...register("firstName")}
              error={errors.firstName?.message}
            />
            <Input
              compact={false}
              id="last-name"
              label={t`Last name *`}
              placeholder={t`E.g. Doe`}
              left={<icons.User />}
              {...register("lastName")}
              error={errors.lastName?.message}
            />
            <Input
              compact={false}
              label={t`Email *`}
              placeholder={t`E.g. email@example.com`}
              left={<icons.Envelope />}
              id="email"
              {...register("email")}
              error={errors.email?.message}
            />
            <Input
              compact={false}
              label={t`Confirm email *`}
              placeholder={t`E.g. email@example.com`}
              left={<icons.Envelope />}
              id="emailConfirmation"
              {...register("emailConfirmation")}
              error={errors.emailConfirmation?.message}
            />
            <HookedSelect
              compact={false}
              label={t`Language of preference *`}
              placeholder={t`E.g. English`}
              id="language"
              name="language"
              left={<icons.Translation />}
              control={control}
              options={LANGUAGE_OPTIONS}
              error={errors.language?.message}
            />
            <HookedSelect
              autocomplete
              compact={false}
              label={t`State *`}
              placeholder={t`E.g. New York`}
              id="state"
              name="state"
              left={<icons.MapPoint />}
              control={control}
              options={stateOptions ?? []}
              error={errors.state?.message}
              disabled={isLoadingStates}
            />
          </div>
          <Checkbox
            checked={hasConsent}
            onCheckedChange={setHasConsent}
            id="consent-checkbox"
            label={
              <span className="text-lg font-normal text-brown-09 sm:text-lg">
                {t`I consent to Fridays`}{" "}
                <Link
                  to={EXTERNAL_LINK.PRIVACY_AND_POLICY}
                  target="_blank"
                  className="font-bold text-brown-09"
                >{t`Privacy Policy`}</Link>{" "}
                {t`and`}{" "}
                <Link
                  to={EXTERNAL_LINK.TERMS_AND_CONDITIONS}
                  target="_blank"
                  className="font-bold text-brown-09"
                >{t`Terms of service`}</Link>
              </span>
            }
          />
          <OnboardingActionButtons
            onCancel={onGoBack}
            continueLabel={t`Next`}
            onContinue={handleSubmit(preSubmitValidation)}
            continueDisabled={!hasConsent}
          />
        </form>
      </div>
    </OnboardingLayout>
  );
};

import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getAvailableDaysQuery } from "@/api";
import { useOnboardingStore } from "@/stores";
import { Calendar, Card } from "@/ui";
import { tw } from "@/utils";
import { PROVIDER_PREFERENCE } from "./schedule.constants";
import { useScheduleContext } from "./useSchedule";

export const CalendarSection = ({
  canChooseProvider = true,
}: {
  canChooseProvider?: boolean;
}) => {
  const {
    canUseCalendar,
    providerPreference,
    selectedProvider,
    selectedDate,
    offsetDate,
    onSelectedDateChange,
    onCalendarOffsetChange,
  } = useScheduleContext();
  const userId = useOnboardingStore((state) => state.user?.id);
  const { data: availableDays, isFetching: isFetchingAvailableDays } = useQuery(
    {
      ...getAvailableDaysQuery({
        dateFromMonth: offsetDate,
        providerId: selectedProvider?.id,
        userId,
      }),
      enabled: canUseCalendar,
    },
  );

  return (
    <Card
      title={t`${canChooseProvider ? "2" : "1"}. Choose an available day:`}
      className={tw(
        "gap-4 bg-transparent px-0",
        !canUseCalendar && "pointer-events-none opacity-50",
      )}
    >
      <p className="text-sm font-medium text-brown-09">{t`The highlighted dates are the available ${
        providerPreference === PROVIDER_PREFERENCE.CUSTOM &&
        selectedProvider?.fullName
          ? `on ${selectedProvider.fullName} calendar`
          : "ones"
      }`}</p>

      <Calendar
        value={selectedDate}
        onChange={onSelectedDateChange}
        onOffsetDateChange={onCalendarOffsetChange}
        availableDays={availableDays}
        isLoading={isFetchingAvailableDays && !availableDays?.length}
      />
    </Card>
  );
};

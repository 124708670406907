import { Outlet } from "react-router-dom";

import { FadeOut } from "@/ui";
import ScrollToTop from "@/utils/scrollToTop";
import { Navbar } from "./Navbar";

export const Layout = () => {
  return (
    <FadeOut className="flex h-dvh flex-col overflow-hidden bg-salmon-01 md:flex-row">
      <Navbar />
      <main className="flex grow overflow-y-auto" id="scrollElement">
        <ScrollToTop />
        <Outlet />
      </main>
    </FadeOut>
  );
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/router";
import { HealthiePasswordForm } from "./HealthiePasswordForm";
import { PaymentForm } from "./PaymentForm";

const STEP = {
  PAYMENT_FORM: "payment-form",
  ACCOUNT_FORM: "account-form",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const PaymentAndAccount = () => {
  const [step, setStep] = useState<Step>(STEP.PAYMENT_FORM);
  const navigate = useNavigate();
  return (
    <div>
      {step === STEP.PAYMENT_FORM && (
        <PaymentForm
          onGoBack={() => navigate(ROUTES.ONBOARDING.JOURNEY_BEGIN)}
          onSubmit={() => setStep(STEP.ACCOUNT_FORM)}
        />
      )}

      {step === STEP.ACCOUNT_FORM && (
        <HealthiePasswordForm
          onSubmit={() => {
            navigate(ROUTES.ONBOARDING.INTAKE_FORM);
          }}
        />
      )}
    </div>
  );
};

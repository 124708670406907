import type { Accept } from "react-dropzone";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import {
  ACCEPT_IMAGE_FILE,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import type { UploadFilesValues } from "@/shared.types";
import { Dropzone } from "@/ui";
import { isSameFile } from "@/utils";
import { DOCUMENT_DROPZONE_LABEL } from "../components.constants";
import { FileUpload } from "./FileUpload";

interface FileUploadsProps {
  accept?: Accept;
}

export const FileUploads = ({
  accept = ACCEPT_IMAGE_FILE,
}: FileUploadsProps) => {
  const { control } = useFormContext<UploadFilesValues>();
  const {
    fields: fileFields,
    append,
    remove,
  } = useFieldArray({ control, name: "files", keyName: "fieldId" });
  const folderName = useWatch({ control, name: `type` });

  const handleUploads = (files: File[]) => {
    for (const file of files) {
      if (!fileFields.some((fileField) => isSameFile(fileField.file, file))) {
        append({ file, name: file.name, id: "" });
      }
    }
  };

  return (
    <div className="flex flex-col gap-5 text-salmon-09">
      <Dropzone
        onDrop={handleUploads}
        multiple
        compact
        accept={accept}
        label={DOCUMENT_DROPZONE_LABEL[folderName]}
        placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
      />

      {fileFields.map((fileField, fieldIndex) => (
        <FileUpload
          key={fileField.fieldId}
          fieldIndex={fieldIndex}
          onRemove={() => remove(fieldIndex)}
        />
      ))}
    </div>
  );
};

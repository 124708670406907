import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getWeightEntriesQuery } from "@/api";
import { MODAL_ROUTES, useNavigateModal } from "@/router";
import { NOW, PERIOD } from "@/shared.constants";
import type { Period } from "@/shared.types";
import {
  Button,
  CardPrimitive as Card,
  IconWrapper,
  PlusIcon,
  Select,
} from "@/ui";
import { formatToBackendDate, parseBackendDate } from "@/utils";
import {
  ChartEmpty,
  ChartSkeleton,
  PeriodPicker,
  WeightProgressChart,
} from "./sections";

const VIEW_OPTIONS = [
  { value: PERIOD.MONTHLY, label: "Monthly View" },
  { value: PERIOD.YEARLY, label: "Yearly View (soon)", disabled: true },
] as const;

export const WeightProgress = () => {
  const navigateModal = useNavigateModal();
  const [selectedDate, setSelectedDate] = useState(NOW);
  const [view, setView] = useState<Period>(PERIOD.MONTHLY);

  const { data, isLoading, isSuccess } = useQuery({
    ...getWeightEntriesQuery({
      date: formatToBackendDate(selectedDate),
      periodType: view,
    }),
    select: (data) =>
      data.map((d) => ({
        ...d,
        x: parseBackendDate(d.date).getDate(),
        y: d.weight,
      })),
  });

  return (
    <Card.Root className="grid gap-y-4 bg-salmon-01 xl:grid-cols-[50%_1fr_max-content] xl:p-8">
      <Card.Title className="text-lg font-bold text-brown-10">{t`Weight Progress Tracker`}</Card.Title>
      <Select
        id="chart-view-mode"
        value={view}
        onChange={setView}
        options={VIEW_OPTIONS}
        className="self-end xl:w-48"
      />

      <Button
        size="lg"
        onClick={() =>
          navigateModal(MODAL_ROUTES.PROGRESS_TRACKER.CREATE_ENTRY)
        }
        className="row-start-4 xl:row-start-auto"
      >
        <IconWrapper size="sm">
          <PlusIcon />
        </IconWrapper>
        {t`Log data`}
      </Button>

      <Card.Content className="col-span-full rounded-lg bg-salmon-02">
        <div className="flex w-full justify-center p-4 pb-2.5 xl:justify-end">
          <PeriodPicker
            mode={view}
            date={selectedDate}
            onChange={setSelectedDate}
          />
        </div>

        {isLoading && <ChartSkeleton />}
        {isSuccess && !data.length && <ChartEmpty />}
        {isSuccess && data.length > 0 && <WeightProgressChart data={data} />}
      </Card.Content>
    </Card.Root>
  );
};

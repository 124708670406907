import type { ReactNode } from "react";

import { ALERT_TYPE, SIZE, VARIANT } from "@/shared.constants";
import type { AlertType } from "@/shared.types";
import { tw } from "@/utils";
import { icons, IconWrapper } from "./Icons";

interface AlertProps {
  content: ReactNode;
  type?: AlertType;
  variant?: typeof VARIANT.PRIMARY | typeof VARIANT.SECONDARY;
  customIcon?: JSX.Element;
  className?: string;
}

const ALERT_ICON = {
  [ALERT_TYPE.INFO]: <icons.InformationCircle />,
  [ALERT_TYPE.ERROR]: <icons.XCircle />,
  [ALERT_TYPE.WARNING]: <icons.ExclamationCircle />,
  [ALERT_TYPE.SUCCESS]: <icons.CheckCircle />,
} as const;

export const Alert = (props: AlertProps) => {
  const {
    type = ALERT_TYPE.INFO,
    variant = VARIANT.PRIMARY,
    content,
    customIcon,
    className,
  } = props;

  return (
    <div
      className={tw(
        "rounded-2xl border p-3.5 text-left text-sm font-medium sm:p-4.5",

        type === ALERT_TYPE.INFO &&
          "border-nature-10 bg-brown-03 text-nature-10",
        type === ALERT_TYPE.ERROR && "border-red-08 bg-red-01 text-red-08",
        type === ALERT_TYPE.WARNING &&
          "border-yellow-10 bg-yellow-02 text-yellow-10",
        type === ALERT_TYPE.SUCCESS &&
          "border-green-10 bg-green-01 text-green-10",

        variant === VARIANT.PRIMARY && "border-transparent",
        variant === VARIANT.SECONDARY && "bg-transparent",

        className,
      )}
    >
      <div className="flex gap-2">
        <div className="flex h-5 items-center">
          <IconWrapper size={SIZE.SMALL}>
            {customIcon ?? ALERT_ICON[type]}
          </IconWrapper>
        </div>

        {content}
      </div>
    </div>
  );
};

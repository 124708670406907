import { createContext } from "react";

import type { ISODate, Provider, TimeSlot } from "@/shared.types";
import type { ProviderPreference } from "./schedule.types";

interface ContextValue {
  providers?: Provider[];
  isLoadingProviders: boolean;
  providerPreference: ProviderPreference;
  selectedProvider: Provider | null;
  selectedDate: ISODate | null;
  selectedAppointment: TimeSlot | null;
  offsetDate: ISODate;

  setProviderPreference: (preference: ProviderPreference) => void;
  setSelectedProvider: (provider: Provider | null) => void;
  setSelectedDate: (date: ISODate | null) => void;
  setSelectedAppointment: (timeSlot: TimeSlot | null) => void;
  setOffsetDate: (date: ISODate) => void;
}

export const ScheduleContext = createContext<ContextValue | null>(null);

import { t } from "ttag";

import { Card, icons, Select } from "@/ui";
import {
  PROVIDER_PREFERENCE,
  PROVIDER_PREFERENCE_OPTIONS,
} from "./schedule.constants";
import { useScheduleContext } from "./useSchedule";

export const ProviderPreferenceSection = () => {
  const {
    providerPreference,
    selectedProvider,
    providerOptions,
    isLoadingProviders,
    onProviderPreferenceChange,
    onSelectedProviderChange,
  } = useScheduleContext();

  return (
    <Card title={t`1. Provider preference`} className="gap-6">
      <div className="flex flex-col items-center gap-3 sm:flex-row">
        <Select
          label="Provider preference"
          id="provider-preference-select"
          value={providerPreference}
          onChange={onProviderPreferenceChange}
          options={PROVIDER_PREFERENCE_OPTIONS}
          left={<icons.Magnifier />}
          containerClassName="basis-1/2 w-full"
        />

        {providerPreference === PROVIDER_PREFERENCE.CUSTOM && (
          <Select
            label="Select provider"
            placeholder="Select a provider"
            id="provider-select"
            value={selectedProvider?.id ?? ""}
            onChange={onSelectedProviderChange}
            options={providerOptions}
            autocomplete
            left={<icons.Magnifier />}
            containerClassName="w-full basis-1/2"
            disabled={isLoadingProviders}
          />
        )}
      </div>
    </Card>
  );
};

import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import {
  ACCEPT_IMAGE_FILE,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import { Alert, Card, ControlledDropzone, icons } from "@/ui";
import { SectionHeader } from "./SectionHeader";

export const LabTests = () => {
  const { control } = useFormContext();

  return (
    <section className="flex flex-col gap-6">
      <SectionHeader
        title={t`Would you like to upload any relevant lab results?`}
        description={t`At your initial consultation, your provider may order labs such as CMP, TSH, Hba1C, and Lipid Panel. We've partnered with Quest Diagnostics to facilitate these lab tests for a fee.`}
      >
        <Alert
          type={"info"}
          variant={"primary"}
          customIcon={<icons.MedicalKit />}
          content={
            <span className="font-bold">{t`Optionally, if you have drawn these labs within the last 12 months, you can upload them to your chart here!`}</span>
          }
        />
      </SectionHeader>

      <Card title={t`Lab test`}>
        <ControlledDropzone
          accept={ACCEPT_IMAGE_FILE}
          control={control}
          name="labTest"
          label={t`Drop your lab test here`}
          placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
        />
      </Card>
    </section>
  );
};

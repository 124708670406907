import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPaymentMethodQuery } from "@/api";
import { FullScreenAlert } from "@/components";
import { SIZE, VARIANT } from "@/shared.constants";
import {
  Button,
  Card,
  icons,
  IconWrapper,
  illustratedIcons,
  Skeleton,
} from "@/ui";
import { EditPaymentMethodModal } from "./EditPaymentMethodModal";

export const PaymentMethodSection = () => {
  const [editPaymentMethod, setEditPaymentMethod] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { data: paymentMethod, isLoading } = useQuery(getPaymentMethodQuery());

  return (
    <>
      <Card
        className="h-full"
        title={`Payment method`}
        icon={
          <IconWrapper size={SIZE.SMALL} className="stroke-salmon-10">
            <icons.Clock />
          </IconWrapper>
        }
        actions={
          <Button
            variant={VARIANT.SECONDARY}
            onClick={() => setEditPaymentMethod(true)}
          >
            <IconWrapper size={SIZE.SMALL} className="fill-nature-08">
              <icons.Pencil />
            </IconWrapper>
            {t`Edit`}
          </Button>
        }
      >
        <div className="flex items-center justify-between rounded-lg bg-brown-02 p-4 text-salmon-10">
          {isLoading && <Skeleton className="h-6 w-full" />}
          {!isLoading && (
            <>
              <div className="flex items-center gap-2 font-medium">
                <IconWrapper size={SIZE.SMALL}>
                  <icons.CreditCard />
                </IconWrapper>
                {`**** ${paymentMethod?.lastFourDigits}`}
              </div>
              <div className="text-sm">{t`Expires ${paymentMethod?.expireDate}`}</div>
            </>
          )}
        </div>
      </Card>

      <EditPaymentMethodModal
        show={editPaymentMethod}
        onClose={() => setEditPaymentMethod(false)}
        onSuccess={() => setShowSuccess(true)}
      />

      {showSuccess && (
        <FullScreenAlert
          customIcon={<illustratedIcons.Success />}
          title={t`Card info updated`}
          description={t`Your card information has been successfully updated`}
          customAction={
            <Button
              size={SIZE.LARGE}
              className="md:w-fit"
              onClick={() => setShowSuccess(false)}
            >{t`Go to Billing`}</Button>
          }
        />
      )}
    </>
  );
};

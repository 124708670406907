import { format, isValid, parse } from "date-fns";

import type { ISODate } from "@/shared.types";

export const parseBackendDate = (dateString: string) => {
  const formattedDate = parse(dateString, "yyyy-MM-dd", new Date());

  if (isValid(formattedDate)) {
    return formattedDate;
  }

  return new Date(dateString);
};

export const formatBackendDate = (dateString?: string | null) => {
  if (!dateString) {
    return "";
  }

  return format(parseBackendDate(dateString), "MM/dd/yyyy");
};

export const formatBackendDateToVerbose = (dateString?: string) => {
  if (!dateString) {
    return "";
  }

  return format(parseBackendDate(dateString), "MMM dd, yyyy");
};

export const formatToBackendDate = (date: Date) =>
  format(date, "yyyy-MM-dd") as ISODate;
export const formatToFrontendDate = (date: Date) => format(date, "MM/dd/yy");
export const formatToTime = (date: Date) => format(new Date(date), "hh:mmaaa");
export const formatConsultationTime = (dateString: string) => {
  const dateSplit = dateString.split("T");
  const timeSplit = dateSplit[1]?.split(".");
  const date = `${dateSplit[0]} ${timeSplit![0]}`;
  const newDate = new Date(date);
  return formatToTime(newDate);
};
export const formatAvailableTime = (dateString: string) => {
  const dateSplit = dateString.split(" ");
  const date = `${dateSplit[0]} ${dateSplit[1]}`;
  const newDate = new Date(date);
  return format(newDate, "hh:mm aa");
};

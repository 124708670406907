import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  getFormQuery,
  getUpcomingConsultationsQuery,
  MEDICATIONS_DOMAIN,
  submitHealthieForm,
} from "@/api";
import {
  FormField,
  FullScreenAlert,
  FullScreenLoader,
  Loader,
  MedicationRequestFilter,
} from "@/components";
import { env } from "@/env";
import { RedirectToSync } from "@/modals/Consultations/RedirectToSync";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { useUserStore } from "@/stores";
import { Button, errorToast, icons, IconWrapper, illustratedIcons } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";

const STEP = {
  STATUS: "status",
  FORM: "form",
  SUCCESS: "success",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const RefillForm = ({
  redirectToFollowUp,
}: {
  redirectToFollowUp: () => void;
}) => {
  const [step, setStep] = useState<Step>(STEP.STATUS);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();

  const { user } = useUserStore();
  const { control, handleSubmit, setError } = useForm();
  const { data: fields, isLoading: isLoadingFields } = useQuery({
    ...getFormQuery(env.VITE_HEALTHIE_REFILL_FORM_ID, user?.id),
    select: (data) => data.fields,
    enabled: step === STEP.FORM,
  });

  const {
    data: hasUpcomingConsultations,
    isLoading: isLoadingHasUpcomingConsultations,
  } = useQuery({
    ...getUpcomingConsultationsQuery(),
    select: (data) => !!data.length,
  });

  const isLoading = isLoadingFields || isLoadingHasUpcomingConsultations;

  const queryClient = useQueryClient();
  const {
    mutate: submitFollowUpFormMutation,
    isPending: isPendingSubmitFollowUpFormMutation,
  } = useMutation({
    mutationFn: submitHealthieForm.mutation,
    onSuccess: () => {
      submitHealthieForm.invalidates(queryClient);
      setStep(STEP.SUCCESS);
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  if (isLoading && step === STEP.FORM) {
    return <Loader />;
  }

  if (step === STEP.FORM && user) {
    return (
      <form
        onSubmit={handleSubmit((value) =>
          submitFollowUpFormMutation({
            formId: env.VITE_HEALTHIE_REFILL_FORM_ID,
            patientId: user.id,
            answers: value,
          }),
        )}
        className="flex max-w-2xl grow flex-col gap-8 pb-8 pt-1"
      >
        {fields?.map((field) => (
          <FormField key={field.id} formField={field} control={control} />
        ))}

        {!!fields?.length && (
          <div className="flex items-center justify-between gap-4">
            <Button
              onClick={() => setStep(STEP.STATUS)}
              variant="secondary"
              size="lg"
              disabled={isPendingSubmitFollowUpFormMutation}
            >
              <IconWrapper size="sm">
                <icons.ChevronLeft />
              </IconWrapper>
              {t`Back`}
            </Button>
            <Button
              type="submit"
              size="lg"
              disabled={isPendingSubmitFollowUpFormMutation}
            >
              {t`Next`}
              <IconWrapper size="sm">
                <icons.ChevronRight />
              </IconWrapper>
            </Button>
          </div>
        )}
        {isPendingSubmitFollowUpFormMutation && <FullScreenLoader />}
      </form>
    );
  }

  if (step === STEP.SUCCESS) {
    return (
      <FullScreenAlert
        customIcon={<illustratedIcons.Success />}
        title={t`Submission Successful!`}
        description={t`We have received your medication request. Please allow 2-3 business days for processing. If we need any more information, our team will be in touch!`}
        customAction={
          <Button
            asChild
            size="lg"
            className="md:w-fit"
            onClick={() =>
              void queryClient.invalidateQueries({
                queryKey: [MEDICATIONS_DOMAIN],
              })
            }
          >
            <Link to={ROUTES.MY_MEDS}>{t`Back to home`}</Link>
          </Button>
        }
      />
    );
  }

  const handleFilterResult = (isDisqualified: boolean) => {
    if (!isDisqualified) {
      return setStep(STEP.FORM);
    }

    if (hasUpcomingConsultations) {
      navigateModal(MODAL_ROUTES.CONSULTATIONS.REFILL_ALREADY_SCHEDULED);
    } else {
      setShowModal(true);
    }
  };

  return (
    <div className="pb-6">
      <MedicationRequestFilter
        onBack={() => navigate(ROUTES.CONSULTATIONS.PURPOSE)}
        onNext={handleFilterResult}
      />
      <RedirectToSync
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={redirectToFollowUp}
      />
    </div>
  );
};

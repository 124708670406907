import type { SVGProps } from "@/shared.types";

export const PillIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M14.1322 3.46024C11.1852 0.513254 6.40722 0.513254 3.46024 3.46024C0.513254 6.40722 0.513254 11.1852 3.46024 14.1322L9.86778 20.5398C12.8148 23.4867 17.5928 23.4867 20.5398 20.5398C23.4867 17.5928 23.4867 12.8148 20.5398 9.86778L14.1322 3.46024ZM4.5209 4.5209C6.8821 2.1597 10.7104 2.1597 13.0716 4.5209L15.9689 7.41825C15.9653 7.42974 15.9616 7.44148 15.9578 7.45347C15.8679 7.73472 15.7151 8.15053 15.468 8.66312C14.9744 9.68744 14.1032 11.101 12.6019 12.6023C11.1005 14.1037 9.68709 14.9747 8.66292 15.4683C8.1504 15.7153 7.73465 15.868 7.45346 15.9578C7.44149 15.9616 7.42977 15.9654 7.41829 15.9689L4.5209 13.0716C2.1597 10.7104 2.1597 6.8821 4.5209 4.5209ZM8.58965 17.1403L10.9284 19.4791C13.2896 21.8403 17.1179 21.8403 19.4791 19.4791C21.8403 17.1179 21.8403 13.2896 19.4791 10.9284L17.1402 8.58958C17.0521 8.80835 16.9461 9.05118 16.8193 9.31437C16.2594 10.4761 15.2939 12.0316 13.6625 13.663C12.0312 15.2943 10.4757 16.2598 9.31411 16.8195C9.05105 16.9463 8.80833 17.0522 8.58965 17.1403Z"
    />
  </svg>
);

export const PillsIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path d="M12.4697 4.46967C12.7626 4.17678 13.2374 4.17678 13.5303 4.46967L15.0303 5.96967C15.3232 6.26256 15.3232 6.73744 15.0303 7.03033C14.7374 7.32322 14.2626 7.32322 13.9697 7.03033L12.4697 5.53033C12.1768 5.23744 12.1768 4.76256 12.4697 4.46967Z" />
    <path
      fillRule="evenodd"
      d="M7.06213 3.06213C9.47829 0.645958 13.3957 0.645958 15.8118 3.06213L20.9379 8.18816C23.354 10.6043 23.354 14.5217 20.9379 16.9379C19.1526 18.7231 16.5477 19.1893 14.3345 18.3364C13.3838 20.9127 10.9071 22.7499 8 22.7499C4.27208 22.7499 1.25 19.7278 1.25 15.9999C1.25 13.0927 3.08726 10.6161 5.6636 9.66543C4.81071 7.45217 5.27689 4.84737 7.06213 3.06213ZM14.7512 4.12279C12.9208 2.2924 9.95317 2.2924 8.12279 4.12279C6.2924 5.95317 6.29241 8.9208 8.12279 10.7512L10.3759 13.0042C10.5925 12.9324 10.8966 12.8171 11.2652 12.6395C12.0708 12.2513 13.1874 11.5639 14.3754 10.3758C15.5635 9.18774 16.251 8.07109 16.6393 7.26537C16.817 6.89669 16.9323 6.59252 17.0042 6.37582L14.7512 4.12279ZM18.1644 7.53603C18.1126 7.65603 18.0549 7.78314 17.9906 7.91662C17.536 8.85971 16.7542 10.1184 15.4361 11.4365C14.118 12.7545 12.8594 13.5363 11.9164 13.9908C11.783 14.055 11.656 14.1127 11.5361 14.1645L13.2488 15.8772C15.0792 17.7076 18.0468 17.7076 19.8772 15.8772C21.7076 14.0468 21.7076 11.0792 19.8772 9.24882L18.1644 7.53603ZM6.38067 11.0042C4.27349 11.6866 2.75 13.6661 2.75 15.9999C2.75 18.8994 5.1005 21.2499 8 21.2499C10.3339 21.2499 12.3133 19.7264 12.9957 17.6193C12.7123 17.4188 12.4419 17.1917 12.1882 16.9379L7.06213 11.8118C6.80831 11.558 6.58116 11.2876 6.38067 11.0042Z"
    />
  </svg>
);

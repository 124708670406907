import { Link } from "react-router-dom";
import { t } from "ttag";

import { SIZE, VARIANT } from "@/shared.constants";
import { Badge, Card, icons, IconWrapper } from "@/ui";

export const News = () => {
  return (
    <Card
      title={t`Exciting news`}
      colorVariant={VARIANT.QUINARY}
      className="relative w-full flex-1 overflow-hidden"
      icon={
        <IconWrapper size={SIZE.SMALL}>
          <icons.Confetti />
        </IconWrapper>
      }
      titleClassName="text-base"
    >
      <div className="-bottom-7 right-0 hidden h-full items-center opacity-90 md:absolute md:flex">
        <icons.NewsBackground className="h-40 w-52" />
      </div>

      <div className="z-10 flex flex-row items-center gap-2">
        <h2 className="text-2xl font-bold text-salmon-10">{t`Fridays Español`}</h2>
        <Badge variant="secondary" className="z-10">{t`Coming Soon!`}</Badge>
      </div>

      <span className="z-10 w-[25ch] text-sm text-nature-08">{t`Stay tuned to know more about Fridays Spanish!`}</span>

      <div className="z-10 flex flex-row items-center gap-2">
        <Link to="https://www.instagram.com/joinfridays/" target="_blank">
          <IconWrapper size={SIZE.MEDIUM}>
            <icons.Social1 />
          </IconWrapper>
        </Link>

        <Link to="https://www.tiktok.com/discover/join-fridays" target="_blank">
          <IconWrapper size={SIZE.MEDIUM}>
            <icons.Social2 />
          </IconWrapper>
        </Link>
      </div>
    </Card>
  );
};

import type {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
} from "react";
import { forwardRef } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import { tw } from "@/utils";
import { CloseIcon } from "../icons";

const Root = DialogPrimitive.Root;
Root.displayName = "Dialog.Root";

const Trigger = DialogPrimitive.Trigger;
Trigger.displayName = "Dialog.Trigger";

const Overlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={tw(
      "fixed inset-0 z-50 bg-black/60 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
));
Overlay.displayName = "Dialog.Overlay";

const CloseButton = forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Close>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close
    ref={ref}
    className={tw(
      "absolute right-2.5 top-2.5 z-10 size-5 rounded-sm text-nature-10 opacity-70 ring-gray-400 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:pointer-events-none",
      className,
    )}
    {...props}
  >
    <CloseIcon />
    <span className="sr-only">Close</span>
  </DialogPrimitive.Close>
));
CloseButton.displayName = "Dialog.CloseButton";

const Content = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Portal>
    <Overlay />
    <DialogPrimitive.Content
      ref={ref}
      className={tw(
        "fixed left-1/2 top-1/2 z-50 flex max-h-[calc(100dvh-2rem)] min-w-min max-w-[calc(100dvw-2rem)] -translate-x-1/2 -translate-y-1/2 flex-col overflow-hidden rounded-3xl bg-salmon-01 text-gray-50 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-2xl",
        className,
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPrimitive.Portal>
));
Content.displayName = DialogPrimitive.Content.displayName;

const Header = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={tw("flex flex-col items-center gap-y-2", className)}
    {...props}
  />
);
Header.displayName = "Dialog.Header";

const Icon = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={tw("size-16", className)} {...props} />
);
Icon.displayName = "Dialog.Icon";

const Footer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={tw(
      "flex flex-col-reverse gap-3.5 md:flex-row md:justify-center",
      className,
    )}
    {...props}
  />
);
Footer.displayName = "Dialog.Footer";

const Title = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={tw(
      "truncate text-center font-serif text-2xl leading-10 text-salmon-10 md:text-left md:text-3xl",
      className,
    )}
    {...props}
  />
));
Title.displayName = "Dialog.Title";

const Description = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={tw("text-center font-medium text-brown-09", className)}
    {...props}
  />
));
Description.displayName = "Dialog.Description";

export {
  Root,
  Overlay,
  Trigger,
  CloseButton,
  Content,
  Header,
  Icon,
  Footer,
  Title,
  Description,
};

import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";
import { z } from "zod";

import { forgotPassword } from "@/api";
import { Logo } from "@/components";
import { ROUTES } from "@/router";
import { Button, icons, IconWrapper, Input } from "@/ui";

interface ErrorType {
  response: { data: { error: { message: string } } };
}

const forgotPasswordSchema = z.object({
  email: z
    .string()
    .min(1, { message: t`Your email is required` })
    .email(t`Please enter a valid email`),
});

export const ForgotPasswordForm = () => {
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const { mutate: forgotPasswordMutation, isPending } = useMutation({
    mutationFn: forgotPassword.mutation,
    onSuccess: () => {
      setEmailSent(true);
    },
    onError: (error: ErrorType) => {
      setError("email", {
        type: "manual",
        message: error.response.data.error.message,
      });
    },
  });

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    setError,
  } = useForm<z.infer<typeof forgotPasswordSchema>>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  return (
    <div className="flex flex-col sm:h-full sm:w-152">
      <header className="relative flex h-fit flex-col gap-3 px-6 pb-20 pt-14 text-nature-02 sm:max-h-80 sm:justify-between sm:gap-5 sm:px-14 sm:pb-8 sm:text-brown-09">
        <Logo className="h-20 self-center fill-nature-08 md:self-start" />
        {!emailSent && (
          <>
            <div className="flex flex-col gap-2 text-center sm:text-left">
              <h2 className="text-2xl tracking-wide sm:text-brown-10">
                {t`Forgot password?`}
              </h2>
              <p className="font-light sm:font-normal">
                {t`Please enter your registered email address.`}
                <br />
                {t`We will send you a link to reset your password.`}
              </p>
            </div>

            <div className="absolute bottom-0 left-1/2 -z-10 h-219 w-219 -translate-x-1/2 rounded-full bg-nature-07 sm:hidden" />
          </>
        )}
      </header>
      {emailSent && (
        <>
          <div className="flex flex-col gap-6 px-14">
            <IconWrapper className="h-20 w-20">
              <icons.Success className="h-20 w-20" />
            </IconWrapper>
            <div className="flex flex-col gap-2 text-center sm:text-left">
              <h2 className="text-2xl tracking-wide sm:text-brown-10">
                {t`Email successfully sent`}
              </h2>
              <p className="font-light sm:font-normal">
                {t`If an account is registered you will receive an email with a link to reset your password.`}
                <br />
                <br />
                {t`Please check your inbox.`}
              </p>
            </div>
            <Button
              size="lg"
              onClick={() => navigate(ROUTES.LOGIN)}
              className="w-full"
            >
              {t`Back to log in`}
            </Button>
          </div>
        </>
      )}
      {!emailSent && (
        <form
          onSubmit={handleSubmit((values) => forgotPasswordMutation(values))}
          className="flex flex-col gap-16 px-6 py-10 sm:gap-10 sm:px-14 sm:pt-0"
        >
          <div className="flex flex-col gap-4">
            <Input
              compact={false}
              id="login-email"
              autoComplete="email"
              label={t`Email`}
              placeholder={t`email@example.com`}
              left={<icons.CheckBadge />}
              {...register("email")}
              error={errors.email?.message}
            />
          </div>
          <div className="flex flex-col gap-3 py-2">
            <Button
              type="submit"
              className="w-full"
              size="lg"
              disabled={!isDirty || isPending}
            >
              {t`Send email`}
            </Button>
            <Button
              size="lg"
              variant="tertiary"
              onClick={() => navigate(ROUTES.LOGIN)}
              className="w-full"
            >
              {t`Go back`}
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

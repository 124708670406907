import { t } from "ttag";

import { illustratedIcons } from "@/ui";

export const TimeSlotErrorMessage = () => {
  return (
    <div className="flex flex-col items-center gap-4 rounded-2xl bg-salmon-02 p-6 text-center">
      <illustratedIcons.HeartsCircle className="h-14 w-14" />

      <h5 className="text-lg font-bold text-brown-10">{t`Oops seems like something went wrong`}</h5>

      <p className="leading-5 text-salmon-10">
        {t`Our team is already working on fixing it.`}
        <br />
        <strong>{t`Try to select another date.`}</strong>
        <br />
        {t`Thank you for your patience!`}
      </p>
    </div>
  );
};

import type { SVGProps } from "@/shared.types";

export const AltArrowRightIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.5119 4.43057C8.8264 4.161 9.29987 4.19743 9.56944 4.51192L15.5694 11.5119C15.8102 11.7928 15.8102 12.2072 15.5694 12.4881L9.56944 19.4881C9.29987 19.8026 8.8264 19.839 8.5119 19.5695C8.19741 19.2999 8.16099 18.8264 8.43056 18.5119L14.0122 12L8.43056 5.48811C8.16099 5.17361 8.19741 4.70014 8.5119 4.43057Z"
    />
  </svg>
);

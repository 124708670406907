import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPastMedicationsQuery } from "@/api/medications";
import { MedicationStatusChip } from "@/components";
import { SIZE } from "@/shared.constants";
import {
  icons,
  IconWrapper,
  illustratedIcons,
  Table,
  TableBody,
  TableCard,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui";
import { formatBackendDate } from "@/utils";

export const PastMedicationRequests = () => {
  const { data: pastMedicationRequests, isLoading } = useQuery(
    getPastMedicationsQuery(),
  );

  return (
    <section className="flex grow flex-col gap-4 text-brown-10">
      <h3 className="flex items-center gap-2 text-lg font-bold">
        <IconWrapper size={SIZE.SMALL}>
          <icons.History />
        </IconWrapper>
        {t`Past medication requests`}
      </h3>
      {!!pastMedicationRequests?.length && (
        <>
          <div className="hidden md:table">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t`Drug name`}</TableHead>
                  <TableHead>{t`Dose`}</TableHead>
                  <TableHead>{t`Date`}</TableHead>
                  <TableHead className="text-center">{t`Status`}</TableHead>
                  <TableHead className="truncate text-right">{t`Tracking #`}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {pastMedicationRequests.map(
                  ({
                    drugName,
                    dose,
                    dateOfRequest,
                    status,
                    trackingNumber,
                  }) => (
                    <TableRow key={`past-medication-request-${trackingNumber}`}>
                      <TableCell>{drugName ?? "-"}</TableCell>
                      <TableCell>{dose ?? "-"}</TableCell>
                      <TableCell>{formatBackendDate(dateOfRequest)}</TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          <MedicationStatusChip
                            status={status}
                            className="truncate"
                          />
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        {trackingNumber}
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </div>

          <TableCard.Root className="bg-white">
            {pastMedicationRequests.map(
              ({ drugName, dose, dateOfRequest, status, trackingNumber }) => (
                <TableCard.Row
                  key={`past-medication-request-${trackingNumber}`}
                >
                  <TableCard.Cell className="justify-between">
                    <TableCard.Data className="text-lg">
                      {formatBackendDate(dateOfRequest)}
                    </TableCard.Data>
                    <MedicationStatusChip status={status} />
                  </TableCard.Cell>

                  {trackingNumber && (
                    <TableCard.Cell>
                      <TableCard.Label>{t`Tracking`}</TableCard.Label>
                      <TableCard.Data>{trackingNumber}</TableCard.Data>
                    </TableCard.Cell>
                  )}

                  <TableCard.Cell>
                    <TableCard.Label>{t`Drug`}</TableCard.Label>
                    <TableCard.Data>{drugName ?? "-"}</TableCard.Data>
                  </TableCard.Cell>
                  <TableCard.Cell>
                    <TableCard.Label>{t`Dose`}</TableCard.Label>
                    <TableCard.Data>{dose ?? "-"}</TableCard.Data>
                  </TableCard.Cell>
                </TableCard.Row>
              ),
            )}
          </TableCard.Root>
        </>
      )}

      {(isLoading || pastMedicationRequests?.length === 0) && (
        <div className="flex grow items-center justify-center rounded-2xl border border-nature-06">
          <div className="flex flex-col items-center gap-2 text-center">
            <illustratedIcons.LightBulb />
            <p className="text-xl font-medium text-brown-05">{t`You don't have past medication requests`}</p>
          </div>
        </div>
      )}
    </section>
  );
};

import { differenceInYears, parse } from "date-fns";

export const calculateHeight = (feet: number, inches: number) => {
  return feet * 0.3048 + inches * 0.0254;
};

export const calculateBMI = ({
  heightFeet,
  heightInches,
  weightPounds,
}: {
  heightFeet: number;
  heightInches: number;
  weightPounds: number;
}) => {
  const heightMeters = calculateHeight(heightFeet, heightInches);
  const weightKg = weightPounds * 0.45359237;
  const bmi = heightMeters && weightKg / Math.pow(heightMeters, 2);

  return isNaN(bmi) ? 0 : parseFloat(bmi.toFixed(1));
};

export const calculateAge = (date: string) => {
  const dob = parse(date, "yyyy-MM-dd", new Date());
  const age = differenceInYears(new Date(), dob);

  return age;
};

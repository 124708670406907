import { t } from "ttag";

import { icons, IconWrapper } from "@/ui";
import { formatAvailableTime, formatBackendDate } from "@/utils";
import { useScheduleContext } from "./useSchedule";

export const AppointmentSummary = () => {
  const { selectedAppointment, selectedDate } = useScheduleContext();

  if (!selectedAppointment) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3 rounded-lg border border-brown-05 p-4 text-lg text-brown-10 sm:gap-2 sm:text-sm sm:font-medium">
      <div className="flex gap-1">
        <label
          htmlFor="chosen-date"
          className="flex items-center gap-1 fill-brown-07 text-brown-07"
        >
          <IconWrapper size="sm">
            <icons.Calendar />
          </IconWrapper>
          {t`Chosen date:`}
        </label>
        {formatBackendDate(selectedDate)}
      </div>

      <div className="flex gap-1">
        <label
          htmlFor="chosen-date"
          className="flex items-center gap-1 fill-brown-07 text-brown-07"
        >
          <IconWrapper size="sm">
            <icons.Clock />
          </IconWrapper>
          {t`Chosen time:`}
        </label>
        {`${formatAvailableTime(selectedAppointment.dateTime)} ${
          selectedAppointment.timezone
        }`}
      </div>

      <div className="flex gap-1">
        <label
          htmlFor="chosen-date"
          className="flex items-center gap-1 fill-brown-07 text-brown-07"
        >
          <IconWrapper size="sm">
            <icons.User />
          </IconWrapper>
          {t`Provider:`}
        </label>
        {selectedAppointment.providerName}
      </div>
    </div>
  );
};

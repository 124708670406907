import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { getUpcomingConsultationsQuery } from "@/api";
import { CallToAction } from "@/components";
import { ROUTES } from "@/router";
import { icons, IconWrapper, ScreenHeader } from "@/ui";
import { PastVideoConsultations } from "./PastConsultations";
import { UpcomingConsultations } from "./UpcomingConsultations";

export const Consultations = () => {
  const navigate = useNavigate();

  const { data: upcomingConsultations } = useQuery(
    getUpcomingConsultationsQuery(),
  );

  return (
    <div className="flex grow flex-col gap-7 px-4 pt-4 md:px-8 md:pt-12 lg:px-16">
      <ScreenHeader
        title={t`Consultations`}
        paths={[
          {
            href: ROUTES.CONSULTATIONS.OVERVIEW,
            icon: icons.Calendar,
            label: t`Consultations`,
          },
        ]}
        actions={
          <Link
            to={ROUTES.CONSULTATIONS.PURPOSE}
            className="flex items-center gap-2 rounded-lg bg-salmon-01 px-4 py-2.5 text-xs font-medium text-brown-10 md:h-auto md:w-auto"
          >
            <IconWrapper size="sm" className="hidden sm:block">
              <icons.Calendar className="fill-brown-07" />
            </IconWrapper>
            <span className="hidden sm:block">{t`New consultation`}</span>
            <span className="block text-sm sm:hidden">{t`Book`}</span>
          </Link>
        }
      />

      <UpcomingConsultations upcomingConsultations={upcomingConsultations} />

      <CallToAction
        title={t`Want to review your refill medication requests?`}
        description={t`Navigate to the "My Meds" section`}
        buttonText={t`Go to My Meds`}
        onClick={() => navigate(ROUTES.MY_MEDS)}
      />

      <PastVideoConsultations />
    </div>
  );
};

import type { QueryClient } from "@tanstack/react-query";

import type {
  OnboardingImageUploadsValues,
  OnboardingPatientFormValues,
  OnboardingUser,
  Patient,
  PatientFormValues,
} from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";

const DOMAIN = "patient";

export const getPatientQuery = () => ({
  queryKey: [DOMAIN, "getPatientQuery"],
  queryFn: async () => {
    const { data } =
      await privateAPI.get<ServiceResponse<Patient>>(`/patients`);

    return data.data;
  },
});

const parsePatientFormValues = (values: PatientFormValues) => {
  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    phoneNumber,

    city,
    state,
    zip,
    line1,
    line2,

    timezone,

    cardFrontImage,
    cardBackImage,
    cardFrontId,
    cardBackId,
  } = values;

  return {
    email,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    phoneNumber,
    location: {
      city,
      state,
      zip,
      line1,
      line2,
    },
    timezone,
    policy: {
      cardFrontImage,
      cardBackImage,
      cardFrontId,
      cardBackId,
    },
  };
};

export const updatePatient = {
  mutation: async (params: PatientFormValues) => {
    const { data } = await privateAPI.put<ServiceResponse<Patient>>(
      "/patients",
      parsePatientFormValues(params),
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

interface UploadInsuranceCardParams {
  insuranceCardFront?: File;
  insuranceCardBack?: File;
}

export const uploadInsuranceCard = {
  mutation: async (insuranceCard: UploadInsuranceCardParams) => {
    const { data } = await privateAPI.post<
      ServiceResponse<{ cardFrontUrl?: string; cardBackUrl?: string }>
    >(
      "/patients/upload-insurance-card",
      { ...insuranceCard },
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const createUser = {
  mutation: async ({
    values,
    id,
  }: {
    values: OnboardingPatientFormValues;
    id: number;
  }) => {
    const { emailConfirmation: _, ...restParams } = values;

    const { data } = await publicAPI.put<ServiceResponse<OnboardingUser>>(
      `/users/${id}`,
      restParams,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

interface CreateHealthieUserParams {
  userId: number;
  providerId?: string;
  appointmentDate?: string;
  line1: string;
  line2?: string;
  zip: string;
  state: string;
  city: string;
  phoneNumber: string;
}

export const createHealthieUser = {
  mutation: async (params: CreateHealthieUserParams) => {
    const { userId, ...userParams } = params;
    const { data } = await privateAPI.post<ServiceResponse<{ token: string }>>(
      `/patients/${userId}/create-healthie-user`,
      userParams,
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

export const updateThirdInjectionDate = {
  mutation: async ({ thirdInjectionDate }: { thirdInjectionDate: string }) => {
    const { data } = await privateAPI.put<ServiceResponse<void>>(
      "/patients/third-injection",
      { thirdInjectionDate },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

interface uploadOnboardingImagesParams extends OnboardingImageUploadsValues {
  userId: OnboardingUser["id"];
}

// TODO: This needs to be fixed. We decided to make multiple calls for each file
// because if all files are uploaded in a single request, it gets rejected due to the total size.
// We need to figure out how to resolve this in the future.
export const uploadOnboardingImages = {
  mutation: async (params: uploadOnboardingImagesParams) => {
    const { userId, ...files } = params;

    // Create an array of promises, one for each file upload
    const filesEntries = Object.entries(files).filter(
      ([_, file]) => file !== null,
    ) as [string, File][];

    const uploadPromises = filesEntries.map(async ([key, value]) => {
      const formData = new FormData();
      formData.append(key, value);

      await publicAPI.post<ServiceResponse<null>>(
        `/patients/${userId}/onboarding-files`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      );
    });

    // Wait for all upload operations to complete
    const results = await Promise.all(uploadPromises);

    return results;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [DOMAIN] });
  },
};

import { icons } from "../Icons";

const toastIcons = {
  info: <icons.InformationCircle className="h-6 w-6 stroke-blue-400" />,
  success: <icons.CheckCircle className="h-6 w-6 stroke-green-400" />,
  warning: <icons.ExclamationCircle className="h-6 w-6 stroke-yellow-400" />,
  error: <icons.XCircle className="h-6 w-6 stroke-red-400" />,
};

export default toastIcons;

import { Link, useLocation } from "react-router-dom";
import { t } from "ttag";

import { Logo } from "@/components";
import { ROUTES } from "@/router";
import { useUserStore } from "@/stores";
import { Avatar, Button, icons } from "@/ui";
import { tw } from "@/utils";
import { NAVIGATION } from "./navigation";

export const Sidebar = ({
  onCloseSidebar,
}: {
  onCloseSidebar?: () => void;
}) => {
  const { pathname: currentPath } = useLocation();
  const { user, clearUser } = useUserStore();

  const hasCurrentPathName = (path: string) => {
    return currentPath.includes(path);
  };

  return (
    <div className="relative flex h-dvh w-67 flex-col gap-8 overflow-y-auto bg-nature-08 px-10 py-14">
      <Logo className="h-9 max-w-fit shrink-0 self-start" />

      {user && (
        <nav className="grow">
          <ul className="flex h-full flex-col gap-6 text-salmon-01">
            <li className="grow">
              <ul className="flex flex-col gap-2">
                {NAVIGATION.map((item) => (
                  <li key={item.label} className="relative">
                    {item.comingSoon && (
                      <div className="px-absolute absolute -right-4 -top-1 flex h-4.5 w-9 items-center justify-center rounded border border-brown-01 bg-nature-09">
                        <p className="text-[8px] font-bold uppercase leading-[normal]">
                          {t`soon`}
                        </p>
                      </div>
                    )}
                    {!item.disabled &&
                    item.subscriptionStatus.includes(user.subscriptionStatus) &&
                    (!item.needsIntakeForm || user.intakeFormCompleted) ? (
                      <Link
                        to={item.path}
                        onClick={onCloseSidebar}
                        draggable="false"
                        className={tw(
                          "block truncate rounded-lg p-2.5 font-medium",
                          hasCurrentPathName(item.path)
                            ? "bg-nature-10"
                            : "hover:bg-nature-09",
                        )}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <span className="block select-none truncate p-2.5 font-medium text-nature-05">
                        {item.label}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <ul className="flex flex-col font-medium">
                <li>
                  <Link
                    to={ROUTES.PROFILE.OVERVIEW}
                    onClick={onCloseSidebar}
                    className={tw(
                      "flex items-center gap-2 truncate rounded-lg p-2.5 font-medium",
                      currentPath.includes(ROUTES.PROFILE.OVERVIEW)
                        ? "bg-nature-10"
                        : "hover:bg-nature-09",
                    )}
                  >
                    <Avatar
                      imgURL={user.picture}
                      name={user.name}
                      variant="secondary"
                      size="xs"
                    />
                    <span
                      className="overflow-hidden text-ellipsis"
                      aria-hidden="true"
                    >
                      {user.name}
                    </span>
                  </Link>
                </li>

                <li>
                  <Button
                    type="button"
                    size="lg"
                    variant="tertiary"
                    onClick={() => {
                      clearUser();
                      onCloseSidebar?.();
                    }}
                    className="group px-0 text-salmon-01 underline-offset-2 focus:underline focus:ring-0"
                  >
                    {t`Log Out`}
                    <icons.ArrowRightOnCircle className="group-hover:fill-secondary-beige-01 fill-salmon-01 group-hover:fill-nature-07" />
                  </Button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

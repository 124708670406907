import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import { uploadFilesSchema } from "@/shared.schemas";
import type { DocumentFolder, UploadFilesValues } from "@/shared.types";
import { DocumentUploadsSubmit } from "./DocumentUploadsSubmit";
import { FileUploads } from "./FileUploads";

interface DocumentUploadsProps {
  folder: DocumentFolder;

  onCancel: () => void;
  onSuccess: () => void;
}

export const DocumentUploads = (props: DocumentUploadsProps) => {
  const { onCancel, onSuccess, folder } = props;

  const methods = useForm<UploadFilesValues>({
    resolver: zodResolver(uploadFilesSchema),
    defaultValues: { type: folder, files: [] },
  });

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col gap-5">
        <FileUploads />

        <DocumentUploadsSubmit onCancel={onCancel} onSuccess={onSuccess} />
      </form>
    </FormProvider>
  );
};

import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import {
  ACCEPT_IMAGE_FILE,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import { Card, ControlledDropzone } from "@/ui";
import { SectionHeader } from "./SectionHeader";

export const GLPMedication = () => {
  const { control } = useFormContext<OnboardingImageUploadsValues>();

  return (
    <section className="flex flex-col gap-6">
      <SectionHeader
        title={t`Would you like to upload a picture of your current GLP-1 medication pen or vial?`}
        description={t`While optional, having a photo of your medication can assist your healthcare provider in better understanding your treatment regimen during consultations.`}
      />

      <Card title={t`GLP-1 medication pen or vial`}>
        <ControlledDropzone
          accept={ACCEPT_IMAGE_FILE}
          control={control}
          name="glpMedication"
          label={t`Drop your photo here`}
          placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
        />
      </Card>
    </section>
  );
};

import type { ReactNode } from "react";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { tw } from "@/utils";
import { OnboardingProgressBar } from "./OnboardingProgressBar";

interface OnboardingLayoutProps {
  showProgressBar?: boolean;
  children: ReactNode;
  background?: ReactNode;
  variant?: Exclude<
    Variant,
    typeof VARIANT.TERTIARY | typeof VARIANT.QUATERNARY
  >;
}

export const OnboardingLayout = ({
  showProgressBar = true,
  children,
  background,
  variant = VARIANT.PRIMARY,
}: OnboardingLayoutProps) => (
  <div
    className={tw(
      "flex h-dvh w-screen justify-center overflow-hidden",
      variant === VARIANT.PRIMARY && "bg-salmon-01",
      variant === VARIANT.SECONDARY && "bg-nature-08",
    )}
  >
    {background && (
      <div className="fixed top-1/2 h-desktop w-desktop -translate-y-1/2">
        {background}
      </div>
    )}

    <div
      className={tw(
        "z-10 flex h-full w-full max-w-desktop grow flex-col overflow-y-auto px-6 pt-10 sm:my-auto sm:max-h-desktop sm:items-center sm:gap-11 sm:px-10 sm:pt-20",
        variant === VARIANT.PRIMARY && "gap-9",
        variant === VARIANT.SECONDARY && "relative gap-20 sm:gap-32",
      )}
    >
      {showProgressBar && <OnboardingProgressBar variant={variant} />}

      {children}
    </div>
  </div>
);

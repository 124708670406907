import type { ReactNode } from "react";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { VARIANT } from "@/shared.constants";
import { Breadcrumb, icons, shapes } from "@/ui";
import { GoBackButton } from "../../ui/common/GoBackButton";

interface HomeScreenHeaderProps {
  title: string;
  description?: ReactNode;
  actions?: ReactNode;
}

export const HomeScreenHeader = ({
  title,
  description,
  actions,
}: HomeScreenHeaderProps) => {
  const isSubPath = location.pathname.split("/").length > 2;

  return (
    <header className="relative grid shrink-0 items-center overflow-hidden rounded-2xl bg-nature-08 p-5 text-brown-01 md:gap-3">
      <div className="-right-14 top-0 hidden h-full items-center opacity-90 md:absolute md:flex">
        <shapes.PartialDoughnut2
          direction="left"
          className="scale-95 opacity-40"
          shapeColor="quaternary"
        />
        <shapes.CompositeSquareBy3
          direction="bottom"
          className="scale-95 opacity-20"
        />
      </div>

      <div className="flex flex-col items-start md:flex-row md:justify-between">
        <div>
          <Breadcrumb
            links={[
              {
                href: ROUTES.HOME,
                icon: icons.User,
                label: t`Home`,
              },
            ]}
            color={VARIANT.PRIMARY}
          />

          <h1 className="row-start-1 flex items-start gap-1 font-serif text-2xl md:row-start-2 md:text-3xl md:leading-10">
            {isSubPath && <GoBackButton />}
            {title}
          </h1>

          {description && (
            <div className="row-start-2 text-sm font-medium md:row-start-3">
              {description}
            </div>
          )}
        </div>

        <div className="md:w-empty z-10 flex w-full items-end justify-end pb-0.5 md:w-fit">
          {actions}
        </div>
      </div>
    </header>
  );
};

import { t } from "ttag";

import { FIELD_TYPE } from "@/shared.constants";

export const MEDICATION_REQUEST_QUESTIONS = [
  {
    id: "q1",
    type: FIELD_TYPE.CHECKBOX,
    question: t`Please indicate any relevant medical history or conditions from the following list`,
    clarification: null,
    answers: [
      {
        value: "drug-or-alcohol",
        label: t`Drug or Alcohol Misuse`,
        disqualifier: true,
      },
      {
        value: "thyroid-or-cancer",
        label: t`History of thyroid issues or cancer in you or your family?`,
        clarification: t`Thyroid cyst/nodule, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2`,
        disqualifier: true,
      },
      {
        value: "tumor-or-infection",
        label: t`Tumor or infection in your brain or spinal cord`,
        disqualifier: true,
      },
      {
        value: "resting-heart-rate",
        label: t`Elevated resting heart rate or a diagnosis of tachycardia`,
        disqualifier: true,
      },
      {
        value: "heart-disease",
        label: t`Coronary artery disease, stroke, or recent heart attack`,
        clarification: t`By recent we mean the last 24 months.`,
        disqualifier: true,
      },
      {
        value: "heart-failure",
        label: t`History of congestive heart failure`,
        disqualifier: true,
      },
      {
        value: "hospitalization",
        label: t`Recent hospitalization within the last 12 months`,
        disqualifier: true,
      },
      {
        value: "none",
        label: t` None of the above.`,
        unique: true,
        disqualifier: false,
      },
    ],
  },
  {
    id: "q2",
    type: FIELD_TYPE.RADIO,
    question: t`What is your current or average blood pressure range?`,
    clarification: null,
    answers: [
      {
        value: "normal-blood-pressure",
        label: t`<120/80`,
        clarification: t`Normal`,
        disqualifier: false,
      },
      {
        value: "elevated-blood-pressure",
        label: t`120-129/<80`,
        clarification: t`Elevated`,
        disqualifier: false,
      },
      {
        value: "high-stage1-blood-pressure",
        label: t`130-139/80-89`,
        clarification: t`High stage 1`,
        disqualifier: false,
      },
      {
        value: "high-stage2-blood-pressure",
        label: t`≥140/90`,
        clarification: t`High Stage 2`,
        disqualifier: true,
        showWarning: true,
      },
    ],
  },
  {
    id: "q3",
    type: FIELD_TYPE.RADIO,
    question: t`What is your current or average heart rate range?`,
    clarification: null,
    answers: [
      {
        value: "slow-bpm",
        label: t`<60 bpm`,
        clarification: t`Slow`,
        disqualifier: false,
      },
      {
        value: "normal-bpm",
        label: t`60-100 bpm`,
        clarification: t`Normal`,
        disqualifier: false,
      },
      {
        value: "slightly-fast-bpm",
        label: t`100-110 bpm`,
        clarification: t`Slightly fast`,
        showWarning: true,
        disqualifier: true,
      },
      {
        value: "fast-bpm",
        label: t`>110 bpm`,
        clarification: t`Fast`,
        showWarning: true,
        disqualifier: true,
      },
    ],
  },
  {
    id: "q4",
    type: FIELD_TYPE.RADIO,
    question: t`Are you currently taking, plan to take, or have recently taken opiate pain medications and/or opiate-based street drugs?`,
    clarification: t`(within the last 3 months)`,
    answers: [
      {
        value: "yes",
        label: t`Yes`,
        disqualifier: true,
      },
      {
        value: "no",
        label: t`No`,
        disqualifier: false,
      },
    ],
  },
  {
    id: "q5",
    type: FIELD_TYPE.RADIO,
    question: t`Have you had bariatric (weight loss) surgery or any abdominal/pelvic surgeries?`,
    clarification: null,
    answers: [
      {
        value: "yes",
        label: t`Yes`,
        disqualifier: true,
      },
      {
        value: "no",
        label: t`No`,
        disqualifier: false,
      },
    ],
  },
] as const;

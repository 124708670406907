import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { t } from "ttag";

import { uploadOnboardingImages } from "@/api";
import { FullScreenLoader } from "@/components";
import { onboardingImageUploadsSchema } from "@/shared.schemas";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { errorToast } from "@/ui";
import { OnboardingActionButtons } from "../../OnboardingActionButtons";
import { OnboardingLayout } from "../../OnboardingLayout";
import { GLPMedication } from "./GLPMedication";
import { InsuranceCard } from "./InsuranceCard";
import { LabTests } from "./LabTests";

interface ImageUploadsProps {
  onGoBack: () => void;
  onNext: () => void;
}

const defaultValues: OnboardingImageUploadsValues = {
  glpMedication: null,
  insuranceCardBack: null,
  insuranceCardFront: null,
  labTest: null,
};

export const ImageUploads = ({ onGoBack, onNext }: ImageUploadsProps) => {
  const userId = useOnboardingStore((state) => state.user?.id);

  const queryClient = useQueryClient();
  const { mutate: uploadOnboardingImagesMutation, isPending } = useMutation({
    mutationFn: uploadOnboardingImages.mutation,
    onSuccess: () => {
      uploadOnboardingImages.invalidates(queryClient);
      onNext();
    },
    onError: errorToast,
  });

  const methods = useForm<OnboardingImageUploadsValues>({
    resolver: zodResolver(onboardingImageUploadsSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = methods;

  const handleCancel = () => {
    reset(defaultValues);
    onGoBack();
  };

  const handleContinue = handleSubmit((values) => {
    userId && uploadOnboardingImagesMutation({ userId, ...values });
  });

  return (
    <OnboardingLayout showProgressBar={false}>
      <FormProvider {...methods}>
        <form className="flex max-w-3xl flex-col gap-4.5 pb-10 text-center sm:gap-12 sm:px-7 sm:pb-20">
          <LabTests />

          <InsuranceCard />

          <GLPMedication />

          <OnboardingActionButtons
            continueLabel={isDirty ? t`Continue` : t`Skip`}
            onCancel={handleCancel}
            onContinue={handleContinue}
          />
        </form>
      </FormProvider>

      {isPending && <FullScreenLoader />}
    </OnboardingLayout>
  );
};

import { useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { t } from "ttag";

import { getFormQuery, submitHealthieForm } from "@/api";
import { FormField, Loader } from "@/components";
import { env } from "@/env";
import { ROUTES } from "@/router";
import { useOnboardingStore } from "@/stores";
import { Button, Checkbox, errorToast, icons, IconWrapper } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";

interface HealthieFormProps {
  onSubmit: () => void;
}

export const HealthieForm = ({ onSubmit }: HealthieFormProps) => {
  const [openLoopConsent, setOpenLoopConsent] = useState<CheckedState>();
  const [medicationConsent, setMedicationConsent] = useState<CheckedState>();

  const patientId = useOnboardingStore((state) => state.patientId);
  const appointment = useOnboardingStore((state) => state.appointment);
  const formId = appointment
    ? env.VITE_HEALTHIE_ONBOARDING_SYNC_FORM_ID
    : env.VITE_HEALTHIE_ONBOARDING_ASYNC_FORM_ID;

  const { control, handleSubmit, setError } = useForm();

  const { data: fields, isLoading } = useQuery({
    ...getFormQuery(formId, patientId),
    select: (data) => data.fields,
  });

  const queryClient = useQueryClient();
  const { mutate: submitHealthieFormMutation, isPending } = useMutation({
    mutationFn: submitHealthieForm.mutation,
    onSuccess: () => {
      submitHealthieForm.invalidates(queryClient);
      onSubmit();
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  if (!patientId) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return (
    <OnboardingLayout showProgressBar={false}>
      <div className="relative flex flex-col gap-6 pb-10 sm:gap-12 sm:pb-20">
        <OnboardingHeader
          title={t`Intake form questions`}
          description={t`(*) Mandatory field`}
        />

        <form
          onSubmit={handleSubmit((value) =>
            submitHealthieFormMutation({
              formId,
              patientId,
              answers: value,
            }),
          )}
          className="flex max-w-3xl flex-col gap-8"
        >
          {isLoading && <Loader />}

          {fields?.map((field) => (
            <FormField key={field.id} formField={field} control={control} />
          ))}

          {!!fields?.length && (
            <>
              <div className="flex flex-col gap-3 text-brown-10">
                <Checkbox
                  checked={openLoopConsent}
                  onCheckedChange={setOpenLoopConsent}
                  id=""
                  label={
                    <span>
                      {t`I consent to OpenLoop Health's `}
                      <Link
                        to="https://openloophealth.com/privacy-policy"
                        className="font-bold underline"
                      >{t`Privacy Policy`}</Link>
                      {", "}
                      <Link
                        to="https://openloophealth.com/terms-of-use"
                        className="font-bold underline"
                      >{t`Terms & Conditions`}</Link>
                      {", and "}
                      <Link
                        to="https://openloophealth.com/telehealth-consent"
                        className="font-bold underline"
                      >{t`Telehealth Consent`}</Link>
                    </span>
                  }
                />
                <Checkbox
                  checked={medicationConsent}
                  onCheckedChange={setMedicationConsent}
                  id="medication-consent-checkbox"
                  label={t`I consent to only get weight-loss medication from this platform`}
                />
              </div>

              <div className="flex items-center justify-end pt-7">
                <Button
                  disabled={
                    isPending || !(openLoopConsent && medicationConsent)
                  }
                  type="submit"
                  size="lg"
                  className="w-full sm:w-fit"
                >
                  {t`Next`}
                  <IconWrapper size="sm" className="hidden sm:block">
                    <icons.ChevronRight />
                  </IconWrapper>
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </OnboardingLayout>
  );
};

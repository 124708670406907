import { t } from "ttag";
import { z } from "zod";

import {
  ACCEPTED_IMAGE_TYPES,
  DOCUMENT_FOLDER,
  MAX_UPLOAD_SIZE,
} from "./shared.constants";
import { getEnumValues } from "./utils";

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
);

export const passwordSchema = z
  .object({
    // password is validated by Healthie
    password: z.string().min(1, { message: t`Password is required` }),
    passwordConfirmation: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: t`Passwords must match`,
    path: ["passwordConfirmation"],
  });

export const onboardingPatientSchema = z
  .object({
    firstName: z.string().min(1, { message: t`First name is required` }),
    lastName: z.string().min(1, { message: t`Last name is required` }),
    email: z
      .string()
      .min(1, { message: t`Email is required` })
      .email({ message: t`Invalid email` }),
    emailConfirmation: z.string(),
    language: z.string().min(1, { message: t`Language is required` }),
    state: z.string().min(1, { message: t`State is required` }),
    hasMedicaidCoverage: z.boolean(),
  })
  .refine((data) => data.email === data.emailConfirmation, {
    message: t`Emails must match`,
    path: ["emailConfirmation"],
  });

export const birthInfoSchema = z.object({
  gender: z.string().min(1, { message: t`Gender is required` }),
  dateOfBirth: z.string().min(1, { message: t`Date of birth is required` }),
});

export const locationSchema = z.object({
  line1: z
    .string()
    .min(1, { message: t`House and street number are required` }),
  line2: z.string().optional(),
  zip: z.string().min(1, { message: t`Zip code is required` }),
  state: z.string().min(1, { message: t`State is required` }),
  city: z.string().min(1, { message: t`City code is required` }),
});

export const policySchema = z.object({
  cardFrontImage: z.string().optional(),
  cardBackImage: z.string().optional(),
  cardFrontId: z.string().optional().nullable(),
  cardBackId: z.string().optional().nullable(),
});

export const patientSchema = z
  .object({
    firstName: z.string().min(1, { message: t`First name is required` }),
    lastName: z.string().min(1, { message: t`Second name is required` }),
    gender: z.string().min(1, { message: t`Gender is required` }),
    dateOfBirth: z.string().min(1, { message: t`Date of birth is required` }),
    email: z
      .string()
      .min(1, { message: t`Email is required` })
      .email({ message: t`Invalid email` }),
    phoneNumber: z
      .string()
      .refine((value) => !value || phoneRegex.test(value), {
        message: t`Invalid phone number`,
      }),
    timezone: z.string().min(1, { message: t`Timezone is required` }),
    pharmacyName: z.string().optional(),
    pharmacyAddress: z.string().optional(),
    insuranceProvider: z.string().optional(),
    insuranceMemberId: z.string().optional(),
  })
  .merge(locationSchema)
  .merge(policySchema);

export const paymentSchema = z
  .object({
    creditCard: z.literal<boolean>(true, {
      errorMap: () => ({ message: "Credit card is required" }),
    }),
    phoneNumber: z
      .string()
      .min(1, { message: t`Phone number is required` })
      .refine((value) => !value || phoneRegex.test(value), {
        message: t`Invalid phone number format`,
      }),
    couponCode: z.string().optional(),
  })
  .merge(locationSchema);

const fileSchema = z
  .instanceof(File)
  .optional()
  .refine((file) => {
    return !file || file.size <= MAX_UPLOAD_SIZE;
  }, "File size must be less than 2MB")
  .refine((file) => {
    return !file?.type || ACCEPTED_IMAGE_TYPES.includes(file.type);
  }, "File must be a PNG, JPEG, or PDF");

export const onboardingImageUploadsSchema = z.object({
  labTest: fileSchema.nullable(),
  insuranceCardFront: fileSchema.nullable(),
  insuranceCardBack: fileSchema.nullable(),
  glpMedication: fileSchema.nullable(),
});

export const uploadFilesSchema = z.object({
  type: z.enum(getEnumValues(DOCUMENT_FOLDER)),
  files: z.array(
    z.object({
      id: z.string().min(1),
      name: z.string().min(1, { message: t`File name is required` }),
      file: z
        .instanceof(File)
        .refine(
          (file) => {
            return !file || file.size <= MAX_UPLOAD_SIZE;
          },
          t`Oops! File too large. Please try reducing the file size before uploading again`,
        )
        .refine(
          (file) => {
            return !file?.type || ACCEPTED_IMAGE_TYPES.includes(file.type);
          },
          t`File must be a PNG, JPEG, or PDF`,
        ),
    }),
  ),
});

export const weightEntrySchema = z.object({
  id: z.string().min(1, { message: "Id is required" }),
  date: z.string().min(1, { message: "Date is required" }).date(),
  weight: z
    .number({ invalid_type_error: t`Weight must be a number` })
    .positive({ message: t`A positive weight is required` })
    .gte(60, { message: t`The minimum weight is 60 lbs` })
    .lte(800, { message: t`The maximum weight is 800 lbs` }),
  note: z.string().optional(),
});

import { useContext } from "react";

import type { TimeSlot } from "@/shared.types";
import { PROVIDER_PREFERENCE } from "./schedule.constants";
import { ScheduleContext } from "./ScheduleContext";

export const useScheduleContext = () => {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error(
      "Schedule.* component must be rendered as child of Schedule component",
    );
  }

  const {
    providers,
    isLoadingProviders,
    selectedAppointment,
    providerPreference,
    offsetDate,
    selectedProvider,
    selectedDate,
    setProviderPreference,
    setSelectedProvider,
    setSelectedDate,
    setSelectedAppointment,
    setOffsetDate,
  } = context;

  const canUseCalendar =
    providerPreference === PROVIDER_PREFERENCE.EARLIEST ||
    (providerPreference === PROVIDER_PREFERENCE.CUSTOM && !!selectedProvider);

  const providerOptions =
    providers?.map((provider) => ({
      value: provider.id,
      label: provider.fullName,
    })) ?? [];

  const getProvider = (id?: string) => {
    return providers?.find((provider) => provider.id === id) ?? null;
  };

  const resetSelectedDate = () => {
    setSelectedDate(null);
    setSelectedAppointment(null);
  };

  const onProviderPreferenceChange = (
    preference: typeof providerPreference,
  ) => {
    if (preference === providerPreference) {
      return;
    }
    resetSelectedDate();
    setProviderPreference(preference);
    setSelectedProvider(null);
  };

  const onSelectedProviderChange = (id: string) => {
    resetSelectedDate();
    setSelectedProvider(getProvider(id));
  };

  const onSelectedDateChange = (date: typeof selectedDate) => {
    setSelectedDate(date);
    setSelectedAppointment(null);
  };

  const onCalendarOffsetChange = (date: typeof offsetDate) => {
    if (offsetDate === date) {
      return;
    }
    setOffsetDate(date);
    setSelectedDate(null);
  };

  const onSelectAppointment = (timeSlot: TimeSlot) => {
    setSelectedAppointment(timeSlot);
  };

  const customSelectedAppointment = selectedAppointment && {
    ...selectedAppointment,
    providerName:
      getProvider(selectedAppointment.providerId)?.fullName ??
      (providerPreference === PROVIDER_PREFERENCE.EARLIEST
        ? "Earliest available"
        : selectedAppointment.providerId),
  };

  return {
    selectedAppointment: customSelectedAppointment,
    providerPreference,
    selectedProvider,
    selectedDate,
    offsetDate,
    canUseCalendar,
    providerOptions,
    isLoadingProviders,

    onProviderPreferenceChange,
    onSelectedProviderChange,
    onSelectedDateChange,
    onSelectAppointment,
    onCalendarOffsetChange,
    getProvider,
  };
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { AnswerCheckboxGroup } from "@/components";
import { AnswerRadioGroup } from "@/components/AnswerRadioGroup";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { FIELD_TYPE } from "@/shared.constants";
import { Button, icons, IconWrapper, Label } from "@/ui";
import { SYMPTOM_QUESTIONS } from "./symptomQuestions";
import type { SymptomAnswerValue } from "./symptomQuestions";

export const Symptoms = ({ onSubmit }: { onSubmit: () => void }) => {
  const [answers, setAnswers] = useState<{
    answer1?: SymptomAnswerValue;
    answer2?: SymptomAnswerValue[];
  }>();
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();

  const handleNext = () => {
    if (answers?.answer1 === "none" && answers?.answer2?.[0] === "none") {
      onSubmit();
    }
    if (answers?.answer1 === "suicidalThoughts") {
      navigateModal(MODAL_ROUTES.CONSULTATIONS.WELL_BEING);
    }
    if (answers?.answer1 === "chestPain" || answers?.answer1 === "seizures") {
      navigateModal(MODAL_ROUTES.CONSULTATIONS.URGENT_CARE);
    }
    if (answers?.answer1 === "none" && answers?.answer2?.[0] !== "none") {
      navigateModal(MODAL_ROUTES.CONSULTATIONS.BEST_FIT);
    }
  };

  const canProceed = !!answers?.answer1 && !!answers?.answer2?.length;
  return (
    <div className="flex flex-col gap-4 pb-8">
      {SYMPTOM_QUESTIONS.map((question) => (
        <div
          key={question.question}
          className="flex flex-col gap-6 rounded-2xl bg-brown-02 p-4 pb-6"
        >
          {question.type === FIELD_TYPE.RADIO && (
            <>
              <Label htmlFor={question.id} className="text-brown-09">
                {question.question}
              </Label>

              <AnswerRadioGroup
                id={question.id}
                onValueChange={(value: SymptomAnswerValue) =>
                  setAnswers({ ...answers, answer1: value })
                }
                options={question.answers}
              />
            </>
          )}

          {question.type === FIELD_TYPE.CHECKBOX && (
            <>
              <Label htmlFor={question.id} className="text-brown-09">
                {question.question}
              </Label>
              <AnswerCheckboxGroup
                id={question.id}
                options={question.answers}
                value={answers?.answer2 ?? []}
                onChange={(value) =>
                  setAnswers({
                    ...answers,
                    answer2: value,
                  })
                }
              />
            </>
          )}
        </div>
      ))}

      <div className="flex gap-2 rounded-2xl bg-brown-03 p-4">
        <div className="pt-0.5">
          <IconWrapper size="sm" className="fill-nature-10">
            <icons.InformationCircle />
          </IconWrapper>
        </div>
        <div className="text-nature-10">
          <h5 className="font-bold">{t`Note to female patients of reproductive potential`}</h5>
          <p>
            {t`Please note that we strongly recommend that you use an effective method of contraception during treatment with a GLP-1 medication and for at least 2 months thereafter. For those taking Zepbound or Mounjaro (tirzepatide), we recommend switching to a non-oral contraceptive method or adding a barrier method of contraception for four weeks after initiation and for four weeks after each dose escalation.`}
          </p>
        </div>
      </div>

      <div className="flex flex-col-reverse items-stretch justify-end gap-4 md:flex-row md:items-center">
        <Button
          onClick={() => navigate(ROUTES.CONSULTATIONS.PURPOSE)}
          variant="secondary"
          size="lg"
        >
          <IconWrapper size="sm" className="hidden md:block">
            <icons.ChevronLeft />
          </IconWrapper>
          {t`Back`}
        </Button>
        <Button
          onClick={handleNext}
          type="submit"
          size="lg"
          disabled={!canProceed}
        >
          {t`Next`}
          <IconWrapper size="sm" className="hidden md:block">
            <icons.ChevronRight />
          </IconWrapper>
        </Button>
      </div>
    </div>
  );
};

import { t } from "ttag";

import {
  DOCUMENT_FOLDER,
  PROVIDER_FOLDER,
  READ_ONLY_DOCUMENT_FOLDER,
} from "@/shared.constants";
import {
  DocumentMedicineIcon,
  FolderCheckIcon,
  LogoCircleIcon,
  MeditationRoundIcon,
  StarRainbowIcon,
  StarsIcon,
  StethoscopeIcon,
  TestTubeIcon,
  TranslateIcon,
} from "@/ui";

export const COACHING_SESSION_ICON = {
  [PROVIDER_FOLDER.NUTRITIONAL]: <StarsIcon />,
  [PROVIDER_FOLDER.MINDSET]: <StarRainbowIcon />,
  [PROVIDER_FOLDER.SPANISH]: <TranslateIcon />,
  [PROVIDER_FOLDER.MOVEMENT]: <MeditationRoundIcon />,
} as const;

export const COACHING_SESSION_LABEL = {
  [PROVIDER_FOLDER.NUTRITIONAL]: "nutrition",
  [PROVIDER_FOLDER.MINDSET]: "mindset",
  [PROVIDER_FOLDER.SPANISH]: '"español"',
  [PROVIDER_FOLDER.MOVEMENT]: "movement",
} as const;

export const DOCUMENT_DROPZONE_LABEL = {
  [DOCUMENT_FOLDER.LABS]: t`Lab test here`,
  [DOCUMENT_FOLDER.PRESCRIPTIONS]: t`Previous prescriptions here`,
  [DOCUMENT_FOLDER.OTHER]: t`Medical records here`,
} as const;

export const DOCUMENT_EDIT_NAME_LABEL = {
  [DOCUMENT_FOLDER.LABS]: t`Test name`,
  [DOCUMENT_FOLDER.PRESCRIPTIONS]: t`Prescription name`,
  [DOCUMENT_FOLDER.OTHER]: t`Record name`,
} as const;

export const DOCUMENT_LABEL = {
  [DOCUMENT_FOLDER.LABS]: t`lab tests`,
  [DOCUMENT_FOLDER.PRESCRIPTIONS]: t`prescriptions`,
  [DOCUMENT_FOLDER.OTHER]: t`medical records`,
} as const;

export const DOCUMENT_ICON = {
  ...COACHING_SESSION_ICON,
  [DOCUMENT_FOLDER.LABS]: <TestTubeIcon />,
  [DOCUMENT_FOLDER.PRESCRIPTIONS]: <StethoscopeIcon />,
  [DOCUMENT_FOLDER.OTHER]: <FolderCheckIcon />,
  [READ_ONLY_DOCUMENT_FOLDER.DOCTOR_NOTES]: <DocumentMedicineIcon />,
  [READ_ONLY_DOCUMENT_FOLDER.FRIDAYS_RESOURCES]: (
    <LogoCircleIcon className="fill-brown-01" />
  ),
} as const;

import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative z-10 flex flex-col gap-3 rounded-2xl bg-salmon-03 p-4 text-salmon-10",
        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "CardPrimitive.Root";

const Header = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex flex-col gap-3", className)}
      {...props}
    />
  ),
);
Header.displayName = "CardPrimitive.Header";

const Title = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={tw(
      "flex items-center gap-2 text-lg font-bold leading-6",
      className,
    )}
    {...props}
  >
    {children}
  </h3>
));
Title.displayName = "CardPrimitive.Title";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={tw("text-sm", className)} {...props} />
));
Description.displayName = "CardPrimitive.Description";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={className} {...props} />
  ),
);
Content.displayName = "CardPrimitive.Content";

const Footer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={tw("flex items-center", className)} {...props} />
  ),
);
Footer.displayName = "CardPrimitive.Footer";

const Background = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "pointer-events-none absolute inset-0 -z-10 flex h-full w-full justify-end gap-2.5 overflow-hidden rounded-2xl bg-nature-08 text-nature-05",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  ),
);
Background.displayName = "CardPrimitive.Background";

export { Root, Header, Footer, Title, Description, Content, Background };

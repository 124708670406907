import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { getProvidersQuery } from "@/api";
import { NOW } from "@/shared.constants";
import type { ISODate, Provider, TimeSlot, USState } from "@/shared.types";
import { formatToBackendDate, tw } from "@/utils";
import { AppointmentSummary } from "./AppointmentSummary";
import { CalendarSection } from "./CalendarSection";
import { ConfirmSection } from "./ConfirmSection";
import { ProviderPreferenceSection } from "./ProviderPreferenceSection";
import { PROVIDER_PREFERENCE } from "./schedule.constants";
import type { ActionProps, ProviderPreference } from "./schedule.types";
import { ScheduleContext } from "./ScheduleContext";
import { TimeSlotSection } from "./TimeSlotSection";

const defaultOffsetDate = formatToBackendDate(
  new Date(NOW.getFullYear(), NOW.getMonth(), 1),
);

interface ScheduleProps extends ActionProps {
  state?: USState["value"];
  canChooseProvider?: boolean;
}

export const Schedule = ({
  onConfirm,
  onGoBack,
  state,
  canChooseProvider = true,
}: ScheduleProps) => {
  const [providerPreference, setProviderPreference] =
    useState<ProviderPreference>(PROVIDER_PREFERENCE.EARLIEST);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    null,
  );
  const [selectedDate, setSelectedDate] = useState<ISODate | null>(null);
  const [selectedAppointment, setSelectedAppointment] =
    useState<TimeSlot | null>(null);
  const [offsetDate, setOffsetDate] = useState(defaultOffsetDate);

  const { data: providers, isLoading: isLoadingProviders } = useQuery(
    getProvidersQuery({ state }),
  );

  const contextValue = {
    providers,
    isLoadingProviders,
    providerPreference,
    selectedProvider,
    selectedDate,
    selectedAppointment,
    offsetDate,

    setProviderPreference,
    setSelectedProvider,
    setSelectedDate,
    setSelectedAppointment,
    setOffsetDate,
  };

  return (
    <ScheduleContext.Provider value={contextValue}>
      <div className="relative flex w-full max-w-6xl flex-col gap-6">
        {canChooseProvider && <ProviderPreferenceSection />}

        <div className="grid gap-4 md:grid-cols-2 md:grid-rows-[auto_1fr_auto]">
          <div className="md:row-span-full">
            <CalendarSection canChooseProvider={canChooseProvider} />
          </div>

          <div className="flex max-h-96 flex-col gap-3 md:row-span-2 md:max-h-fit md:pt-4">
            <TimeSlotSection canChooseProvider={canChooseProvider} />
            <AppointmentSummary />
          </div>

          <div
            className={tw(
              "h-fit pb-4 md:col-start-2 md:row-start-3",
              Boolean(onGoBack) &&
                "md:col-span-full md:col-start-1 md:row-start-4 md:pt-1",
            )}
          >
            <ConfirmSection onConfirm={onConfirm} onGoBack={onGoBack} />
          </div>
        </div>
      </div>
    </ScheduleContext.Provider>
  );
};

import type { TooltipProps } from "recharts";
import type {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { MODAL_ROUTES, useNavigateModal } from "@/router";
import type { WeightEntry } from "@/shared.types";
import {
  CalendarMinimalisticIcon,
  CloseIcon,
  DocumentMedicineIcon,
  IconWrapper,
  PenIcon,
  TrashBin2Icon,
} from "@/ui";
import { formatBackendDate } from "@/utils";

interface WeightTooltipContentProps {
  tooltipData: TooltipProps<ValueType, NameType>;
  onClose: () => void;
}

export const WeightTooltipContent = ({
  tooltipData,
  onClose,
}: WeightTooltipContentProps) => {
  const navigateModal = useNavigateModal();
  const { payload } = tooltipData;
  const data = payload?.find((e) => e.name === "x");

  if (!data?.payload) {
    return null;
  }

  const weightEntry = data.payload as WeightEntry;

  return (
    <div>
      <div className="pointer-events-auto flex flex-col">
        <div className="relative flex w-72 flex-col gap-4 overflow-hidden rounded-2xl border border-salmon-05 bg-salmon-10 p-4 py-6 text-brown-01">
          <IconWrapper
            onClick={onClose}
            size="sm"
            as="button"
            type="button"
            className="absolute right-1.5 top-1.5"
          >
            <CloseIcon />
          </IconWrapper>

          <header className="flex justify-between">
            <h5>{weightEntry.weight} lb</h5>

            <div className="flex items-center gap-1 text-salmon-06">
              <IconWrapper size="xs">
                <CalendarMinimalisticIcon />
              </IconWrapper>
              <div>{formatBackendDate(weightEntry.date)}</div>
            </div>
          </header>

          <div className="flex flex-col gap-2">
            {weightEntry.note && (
              <div className="flex gap-1">
                <IconWrapper size="sm" className="mt-0.5 text-salmon-06">
                  <DocumentMedicineIcon />
                </IconWrapper>

                <p className="line-clamp-3 text-sm">{weightEntry.note}</p>
              </div>
            )}

            <div className="flex items-center gap-2 self-end">
              <IconWrapper
                size="lg"
                as="button"
                type="button"
                onClick={() =>
                  navigateModal(
                    `${MODAL_ROUTES.PROGRESS_TRACKER.DELETE_ENTRY}/${weightEntry.id}`,
                    { weightEntry },
                  )
                }
                className="rounded-lg border border-transparent p-1 hover:opacity-80"
              >
                <TrashBin2Icon />
              </IconWrapper>

              <IconWrapper
                size="lg"
                as="button"
                type="button"
                onClick={() =>
                  navigateModal(
                    `${MODAL_ROUTES.PROGRESS_TRACKER.EDIT_ENTRY}/${weightEntry.id}`,
                    { weightEntry },
                  )
                }
                className="rounded-lg border border-brown-01 p-1.5 hover:opacity-80"
              >
                <PenIcon />
              </IconWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect } from "react";
import type { ForwardedRef } from "react";
import type { DropzoneOptions, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";
import { t } from "ttag";

import { MAX_UPLOAD_SIZE, SIZE } from "@/shared.constants";
import { bytesToMegabytes, formatBytes, forwardRef, tw } from "@/utils";
import { icons, IconWrapper, useToastStore } from "../common";
import { Message } from "./Message";

interface DropzoneProps extends DropzoneOptions {
  id?: string;
  compact?: boolean;
  error?: string | boolean;
  label?: string;
  message?: string;
  placeholder?: string;
  value?: FileWithPath | FileWithPath[] | null;
}

function maxSizeValidator(file: FileWithPath) {
  if (file.size > MAX_UPLOAD_SIZE) {
    return {
      code: "file-too-large",
      message: t`Oops! File too large (Max. ${formatBytes(MAX_UPLOAD_SIZE)}). Please try reducing the file size before uploading again.`,
    };
  }

  return null;
}

export const Dropzone = forwardRef(
  (props: DropzoneProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { pushToast } = useToastStore();
    const {
      value,
      id,
      compact,
      error,
      label = t`Drop your files here`,
      message,
      multiple = false,
      placeholder = t`Drag 'n' drop some files here, or click to select files`,
    } = props;

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
      ...props,
      multiple,
      validator: maxSizeValidator,
    });

    const selectedFiles =
      (Array.isArray(value) ? value : value && [value]) ?? [];

    const files = selectedFiles.map((file: FileWithPath) => (
      <span key={file.path}>
        {file.path}
        {multiple && ` - ${bytesToMegabytes(file.size)} MB`}
      </span>
    ));

    const fileSize =
      selectedFiles[0]?.size && bytesToMegabytes(selectedFiles[0].size);

    useEffect(() => {
      fileRejections.forEach(
        ({ file, errors }) =>
          void pushToast({
            type: "error",
            title: file.name,
            message: errors.map((e) => e.message).join("\n"),
          }),
      );
    }, [fileRejections, pushToast]);

    return (
      <div className="flex grow flex-col gap-1.5 text-center">
        <div
          {...getRootProps({ ref })}
          className={tw(
            "flex grow flex-col items-center justify-center gap-4 rounded-2xl border border-dashed border-salmon-10 bg-salmon-01 p-6 focus:outline-none focus:ring-2 focus:ring-nature-04 focus:ring-opacity-70",
            Boolean(files.length) && "bg-salmon-02",
          )}
        >
          <div>
            <label htmlFor={id} className="text-brown-10">
              {files.length ? files : label}
            </label>
            <input {...getInputProps({ id })} />

            <p className="text-sm font-medium text-brown-06">
              {fileSize ?? placeholder}
            </p>
          </div>

          {files.length ? (
            <IconWrapper
              size={SIZE.LARGE}
              className="rounded-full border-2 border-salmon-06 bg-salmon-05 p-1.5 text-salmon-10"
            >
              <icons.Check />
            </IconWrapper>
          ) : (
            <IconWrapper
              size={SIZE.LARGE}
              className="rounded-lg border border-salmon-08 p-2 text-salmon-08"
            >
              <icons.Plus />
            </IconWrapper>
          )}
        </div>

        {(!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);

interface ControlledDropzoneProps<TFieldValues extends FieldValues>
  extends Omit<DropzoneProps, "value"> {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}
export const ControlledDropzone = <TFieldValues extends FieldValues>({
  name,
  control,
  multiple,
  ...props
}: ControlledDropzoneProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, value, ref } = field;
        const { error } = fieldState;
        return (
          <Dropzone
            {...props}
            id={name}
            value={value}
            onDrop={(acceptedFiles) =>
              onChange(multiple ? acceptedFiles : acceptedFiles[0])
            }
            error={error?.message}
            ref={ref}
          />
        );
      }}
    />
  );
};

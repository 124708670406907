import { useState } from "react";
import { t } from "ttag";

import { AnswerCheckboxGroup } from "@/components";
import { AnswerRadioGroup } from "@/components/AnswerRadioGroup";
import { FIELD_TYPE } from "@/shared.constants";
import { BasicTooltip, Button, icons, IconWrapper, Label } from "@/ui";
import { MEDICATION_REQUEST_QUESTIONS } from "./questions";

const defaultValue = MEDICATION_REQUEST_QUESTIONS.reduce(
  (acc, question) => {
    acc[question.id] = question.type === FIELD_TYPE.CHECKBOX ? [] : "";
    return acc;
  },
  {} as Record<
    (typeof MEDICATION_REQUEST_QUESTIONS)[number]["id"],
    string | string[]
  >,
);

export const MedicationRequestFilter = ({
  onBack,
  onNext,
}: {
  onBack: () => void;
  onNext: (isDisqualified: boolean) => void;
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const canProceed = Object.values(selectedValue).every(
    (answer) => answer.length,
  );

  const handleNext = () => {
    const isDisqualified = Object.keys(selectedValue).some((questionId) => {
      const question = MEDICATION_REQUEST_QUESTIONS.find(
        (q) => q.id === questionId,
      );
      const selectedAnswers = question?.answers.filter((answer) =>
        selectedValue[question.id].includes(answer.value),
      );
      return selectedAnswers?.some((answer) => answer.disqualifier);
    });

    onNext(isDisqualified);
  };

  return (
    <div className="flex flex-col gap-4">
      {MEDICATION_REQUEST_QUESTIONS.map((question) => (
        <div
          key={question.id}
          className="flex flex-col gap-6 rounded-2xl bg-brown-02 p-4 pb-6"
        >
          {question.type === FIELD_TYPE.RADIO && (
            <>
              <Label htmlFor={question.id} className="text-brown-09">
                {question.question}
              </Label>
              {question.clarification && (
                <Label htmlFor={question.id} className="text-brown-09">
                  {question.clarification}
                </Label>
              )}
              <AnswerRadioGroup
                id={question.id}
                onValueChange={(value) =>
                  setSelectedValue({ ...selectedValue, [question.id]: value })
                }
                options={question.answers}
                warning={
                  <BasicTooltip
                    triggerContent={
                      <IconWrapper className="fill-salmon-01 p-1.5">
                        <icons.VideoCamera />
                      </IconWrapper>
                    }
                    content={t`This answer divert to video consultation`}
                  />
                }
              />
            </>
          )}

          {question.type === FIELD_TYPE.CHECKBOX && (
            <>
              <Label htmlFor={question.id} className="text-brown-09">
                {question.question}
              </Label>
              <AnswerCheckboxGroup
                id={question.id}
                options={question.answers}
                value={selectedValue[question.id] as string[]}
                onChange={(value) =>
                  setSelectedValue({
                    ...selectedValue,
                    [question.id]: value,
                  })
                }
              />
            </>
          )}
        </div>
      ))}

      <div className="flex items-center justify-between gap-4 pb-10 sm:pb-20">
        <Button onClick={onBack} variant="secondary" size="lg">
          <IconWrapper size="sm">
            <icons.ChevronLeft />
          </IconWrapper>
          {t`Back`}
        </Button>
        <Button
          onClick={handleNext}
          type="submit"
          size="lg"
          disabled={!canProceed}
        >
          {t`Next`}
          <IconWrapper size="sm">
            <icons.ChevronRight />
          </IconWrapper>
        </Button>
      </div>
    </div>
  );
};

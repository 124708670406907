import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import {
  createConversationMutation,
  getConversationTagsQuery,
} from "@/api/messages";
import type { Tag } from "@/shared.types";
import { errorToast } from "@/ui";
import { ConversationTag } from "./ConversationTag";
import { NewChatSkeleton } from "./NewChatSkeleton";

export const CreateNewChat = ({
  handleChatCreated,
}: {
  handleChatCreated: (conversationId: string) => void;
}) => {
  const queryClient = useQueryClient();
  const { data: tags } = useQuery(getConversationTagsQuery());
  const [missingTagError, setMissingTagError] = useState<string | null>(null);
  const { mutate: createConversationMutate, isPending } = useMutation({
    mutationFn: createConversationMutation.mutation,
    onSuccess: (data) => {
      createConversationMutation.invalidates(queryClient);
      handleChatCreated(data.id);
    },
    onError: (err) => {
      errorToast(err);
    },
  });

  const createConversation = (tag: Tag) => {
    setMissingTagError(null);
    createConversationMutate(tag);
  };

  if (isPending) {
    return (
      <div className="h-dvh bg-salmon-02 pl-4 pt-8">
        <NewChatSkeleton />
      </div>
    );
  }

  return (
    <div className="flex h-dvh flex-col justify-between gap-6 overflow-scroll rounded-b-2xl bg-salmon-02 py-8 pl-4 pr-8">
      <div>
        <div className="my-4 text-center text-2xl font-bold text-salmon-08">{t`Help us direct your message to the right place`}</div>
        <div className="flex flex-col gap-6">
          {tags?.map((tag) => (
            <ConversationTag
              key={tag.id}
              tag={tag}
              onClick={() => createConversation(tag)}
            />
          ))}
        </div>
      </div>
      {missingTagError && (
        <div className="self-center text-red-07">{missingTagError}</div>
      )}
    </div>
  );
};

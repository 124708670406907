import { AnimatePresence } from "framer-motion";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import type { Location } from "react-router-dom";

import { Layout } from "@/layouts";
import {
  Billing,
  CoachingHubOverview,
  ConsultationForm,
  ConsultationPurpose,
  Consultations,
  ConsultationSchedule,
  DoctorNotes,
  DocumentCenterOverview,
  EditProfile,
  FridaysResources,
  IntakeForm,
  JourneyBegin,
  LabsDetails,
  Login,
  MainInfo,
  MedicalRecordsDetails,
  Messages,
  MyMeds,
  MyProfile,
  PastSessions,
  PaymentAndAccount,
  PrescriptionsDetails,
  Welcome,
} from "@/screens";
import { ForgotPassword } from "@/screens/ForgotPassword/ForgotPassword";
import { Home } from "@/screens/Home/Home";
import { MissingIntakeForm } from "@/screens/Home/MissingIntakeForm";
import { ResetPassword } from "@/screens/ResetPassword/ResetPassword";
import { ResetPasswordLinkUsed } from "@/screens/ResetPassword/ResetPasswordLinkUsed";
import { useOnboardingStore, useUserStore } from "@/stores";
import { ModalRouter } from "./ModalRouter";
import { ROUTES } from "./routes";

export const Router = () => {
  const location = useLocation();
  const { previousLocation } = (location.state ?? {}) as {
    previousLocation?: Location;
  };
  const intakeFormCompleted = useUserStore(
    (state) => state.user?.intakeFormCompleted,
  );
  const userState = useUserStore((state) =>
    state.token ? "loggedIn" : "loggedOut",
  );
  const userIsActive = useUserStore(
    (state) =>
      state.user?.subscriptionStatus === "active" ||
      state.user?.subscriptionStatus === "paid_in_healthie",
  );

  const userCanAccessMessages = useUserStore(
    (state) =>
      state.user?.subscriptionStatus === "active" ||
      state.user?.subscriptionStatus === "payment_failed" ||
      state.user?.subscriptionStatus === "paid_in_healthie",
  );

  const hasOnboardingUser = useOnboardingStore(
    (state) => !!state.user || !!state.bmi || !!state.birthInfo,
  );

  const onboardingRedirectRoutes = Object.values(ROUTES.ONBOARDING).map(
    (value) => ({
      path: value,
      element: <Navigate replace to={ROUTES.ONBOARDING.MAIN_INFO} />,
    }),
  );

  return (
    <AnimatePresence mode="wait">
      {/* PUBLIC ONLY ROUTES */}
      <Routes location={previousLocation ?? location} key={userState}>
        {userState === "loggedOut" && (
          <>
            <Route
              element={<ResetPasswordLinkUsed />}
              path={ROUTES.RESET_PASSWORD_USED}
            />
            <Route element={<Login />} path={ROUTES.LOGIN} />
            <Route element={<ResetPassword />} path={ROUTES.RESET_PASSWORD} />
            <Route element={<ForgotPassword />} path={ROUTES.FORGOT_PASSWORD} />
            <Route element={<Welcome />} path={ROUTES.ONBOARDING.WELCOME} />
            <Route element={<MainInfo />} path={ROUTES.ONBOARDING.MAIN_INFO} />

            {hasOnboardingUser ? (
              <>
                <Route
                  element={<JourneyBegin />}
                  path={ROUTES.ONBOARDING.JOURNEY_BEGIN}
                />
                <Route
                  element={<PaymentAndAccount />}
                  path={ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT}
                />
                <Route
                  element={<IntakeForm />}
                  path={ROUTES.ONBOARDING.INTAKE_FORM}
                />
              </>
            ) : (
              <>
                {onboardingRedirectRoutes.map((route, index) => (
                  <Route
                    key={index}
                    element={route.element}
                    path={route.path}
                  />
                ))}
              </>
            )}

            <Route element={<Navigate replace to={ROUTES.LOGIN} />} path="*" />
          </>
        )}

        {/* PRIVATE ONLY ROUTES */}
        {userState === "loggedIn" && (
          <Route element={<Layout />}>
            <Route element={<Navigate to={ROUTES.HOME} />} path={ROUTES.BASE} />

            {userIsActive && intakeFormCompleted && (
              <>
                <Route element={<MyMeds />} path={ROUTES.MY_MEDS} />
                <Route
                  element={<Consultations />}
                  path={ROUTES.CONSULTATIONS.OVERVIEW}
                />
                <Route
                  element={<ConsultationPurpose />}
                  path={ROUTES.CONSULTATIONS.PURPOSE}
                />
                <Route
                  element={<ConsultationForm />}
                  path={ROUTES.CONSULTATIONS.FORM}
                />
                <Route
                  element={<ConsultationSchedule />}
                  path={ROUTES.CONSULTATIONS.SCHEDULE}
                />
              </>
            )}

            <Route element={<Billing />} path={ROUTES.BILLING} />
            <Route element={<MyProfile />} path={ROUTES.PROFILE.OVERVIEW} />
            <Route element={<EditProfile />} path={ROUTES.PROFILE.EDIT} />
            <Route element={<Home />} path={ROUTES.HOME} />
            <Route
              element={<CoachingHubOverview />}
              path={ROUTES.COACHING_HUB.OVERVIEW}
            />
            <Route
              element={<PastSessions />}
              path={`${ROUTES.COACHING_HUB.PAST_SESSIONS}/:sessionType`}
            />
            {userCanAccessMessages && (
              <Route element={<Messages />} path={ROUTES.MESSAGES} />
            )}

            <Route element={<MissingIntakeForm />} path={ROUTES.INTAKE_FORM} />

            <Route
              element={<DocumentCenterOverview />}
              path={ROUTES.DOCUMENT_CENTER.OVERVIEW}
            />
            <Route
              element={<LabsDetails />}
              path={ROUTES.DOCUMENT_CENTER.LABS}
            />
            <Route
              element={<PrescriptionsDetails />}
              path={ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS}
            />
            <Route
              element={<DoctorNotes />}
              path={ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES}
            />
            <Route
              element={<MedicalRecordsDetails />}
              path={ROUTES.DOCUMENT_CENTER.MEDICAL_RECORDS}
            />
            <Route
              element={<FridaysResources />}
              path={ROUTES.DOCUMENT_CENTER.FRIDAYS_RESOURCES}
            />

            <Route element={<Navigate replace to={ROUTES.HOME} />} path="*" />
          </Route>
        )}
      </Routes>

      {/* MODALS ROUTES */}
      <Routes>
        <Route
          path="*"
          element={<ModalRouter showModal={!!previousLocation} />}
        />
      </Routes>
    </AnimatePresence>
  );
};

import { t } from "ttag";

import {
  DISCOUNT_DURATION,
  SUBSCRIPTION_CANCELLATION_EMAIL,
  SUBSCRIPTION_PRICE,
} from "@/shared.constants";
import type { Coupon } from "@/shared.types";
import { Alert } from "@/ui";
import { getFinalChargedAmount } from "@/utils";

interface CouponDetailsProps {
  coupon?: Coupon;
}

export const PaymentDetails = ({ coupon }: CouponDetailsProps) => {
  if (
    coupon?.duration === DISCOUNT_DURATION.FOREVER &&
    (coupon?.percentOff === 100 || coupon?.amountOff >= SUBSCRIPTION_PRICE)
  ) {
    return null;
  }

  const renderContent = () => {
    if (!coupon?.isValid) {
      return (
        <p>
          {t`By selecting “Pay” you agree to be charged $${SUBSCRIPTION_PRICE}, plus applicable taxes, today and every 28 days thereafter until you cancel. You can cancel at any time by contacting `}
          <strong className="text-nature-08">
            {SUBSCRIPTION_CANCELLATION_EMAIL}
          </strong>

          {t`. No refunds or credits for partial subscription periods are given unless required by law. The monthly subscription fee does not cover the costs of lab tests or medication—those will be charged separately.`}
        </p>
      );
    } else if (coupon.duration === DISCOUNT_DURATION.ONCE) {
      return (
        <p>
          {t`By selecting “Pay” you agree to be charged $${getFinalChargedAmount(coupon)}, plus applicable taxes, for your Fridays membership today and $99, plus applicable taxes, every 28 days thereafter until you cancel. You can cancel at any time by contacting `}
          <strong className="text-nature-08">
            {SUBSCRIPTION_CANCELLATION_EMAIL}
          </strong>

          {t`. No refunds or credits for partial subscription periods are given unless required by law. The monthly subscription fee does not cover the costs of lab tests or medication—those will be charged separately.`}
        </p>
      );
    } else if (coupon.duration === DISCOUNT_DURATION.FOREVER) {
      return (
        <p>
          {t`By selecting “Pay” you agree to be charged $${getFinalChargedAmount(coupon)}, plus applicable taxes, every 28 days thereafter until you cancel.`}
          <br />

          {t`You can cancel at any time by contacting `}
          <strong className="text-nature-08">
            {SUBSCRIPTION_CANCELLATION_EMAIL}
          </strong>

          {t`. No refunds or credits for partial subscription periods are given unless required by law. The monthly subscription fee does not cover the costs of lab tests or medication—those will be charged separately.`}
        </p>
      );
    }
  };

  return <Alert content={renderContent()} />;
};

import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { getHomeDataQuery, getPatientQuery } from "@/api";
import { MedicationRequestCard, WeightProgress } from "@/components";
import { ScreenLayout } from "@/layouts";
import { SetUpFridaysPasswordModal } from "@/modals/SetUpFridaysPasswordModal";
import { SetUpPaymentMethodModal } from "@/modals/SetUpPaymentMethodModal";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { SIZE, SUBSCRIPTION_STATUS, VARIANT } from "@/shared.constants";
import { useUserStore } from "@/stores";
import {
  Button,
  CardPrimitive as Card,
  DollarMinimalisticIcon,
  Header,
  icons,
  IconWrapper,
  LightbulbMinimalisticIcon,
  ScreenHeader,
} from "@/ui";
import { Loading } from "@/ui/common/Loading";
import { UpcomingConsultations } from "../Consultations/Overview/UpcomingConsultations";
import { AlertCard } from "./AlertCard";
import { HomeScreenHeader } from "./HomeScreenHeader";
import { News } from "./News";

export const Home = () => {
  const { data: patient, isLoading: isPatientLoading } =
    useQuery(getPatientQuery());

  const { data, isLoading } = useQuery(getHomeDataQuery());
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();

  const intakeFormCompleted = useUserStore(
    (state) => state.user?.intakeFormCompleted,
  );

  const { subscriptionStatus, upcomingConsultations } = data ?? {};

  const headerTitle = t`Welcome${
    patient?.firstName ? `, ${patient.firstName}` : ""
  }!`;

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loading variant={VARIANT.PRIMARY} />
      </div>
    );
  }

  if (
    subscriptionStatus === "inactive" ||
    subscriptionStatus === "cancelled" ||
    subscriptionStatus === "payment_failed"
  ) {
    return (
      <div className="flex max-h-full w-full flex-col gap-7 px-3 pb-4 pt-4 md:px-8 md:pb-4 md:pt-12 lg:px-16">
        <ScreenHeader
          title={headerTitle}
          paths={[
            {
              href: ROUTES.HOME,
              icon: icons.User,
              label: t`Home`,
            },
          ]}
        />
        {subscriptionStatus === SUBSCRIPTION_STATUS.PAYMENT_FAILED && (
          <AlertCard
            title={"Payment failed"}
            description={
              "There was an issue processing your payment. Please check your details and try again, or use a different payment method."
            }
            button={
              <Button
                onClick={() => navigate(ROUTES.BILLING)}
                className="mt-2 h-fit self-center"
              >
                <div className="flex gap-2">
                  <IconWrapper size={SIZE.SMALL} className="fill-white">
                    <DollarMinimalisticIcon />
                  </IconWrapper>
                  {t`Update payment info`}
                </div>
              </Button>
            }
          />
        )}
        {subscriptionStatus !== SUBSCRIPTION_STATUS.PAYMENT_FAILED && (
          <AlertCard
            title={"Oops! Your membership has been cancelled."}
            description={
              "Don't worry, we're here to assist you, please check your messages."
            }
            button={
              <Button
                onClick={() => navigate(ROUTES.BILLING)}
                className="mt-2 h-fit self-center"
              >
                <div className="flex gap-2">
                  <IconWrapper size={SIZE.SMALL} className="fill-white">
                    <DollarMinimalisticIcon />
                  </IconWrapper>
                  {t`Update payment info`}
                </div>
              </Button>
            }
          />
        )}
      </div>
    );
  }

  return (
    <>
      <ScreenLayout className="bg-brown-02">
        <HomeScreenHeader
          title={headerTitle}
          description={
            <div className="leading-1 mt-3 flex flex-col">
              <span className="text-sm font-bold text-white">
                {t`Start your journey towards a healthier you, today!`}
              </span>
              <span className="text-sm text-nature-03">
                {t`Discover personalized nutrition plans, expert coaching, and more to support your weight loss goals.`}
              </span>
              <span className="text-sm font-bold text-white">
                {t`Join the community and transform your life with Fridays. Let's do this together!`}
              </span>
            </div>
          }
          actions={
            <div className="pl-2 pt-3 sm:pt-4">
              <Header.Button
                onClick={() => navigateModal(MODAL_ROUTES.NEXT_STEPS)}
              >
                <IconWrapper>
                  <LightbulbMinimalisticIcon />
                </IconWrapper>
                {t`Next steps`}
              </Header.Button>
            </div>
          }
        />
        {!intakeFormCompleted && (
          <AlertCard
            title="Please complete the intake form to proceed."
            description="Please complete the Intake Form to be able to join video consultations or order medication."
            button={
              <Button
                onClick={() => navigate(ROUTES.INTAKE_FORM)}
                className="mt-2 h-fit w-full self-center md:w-fit"
              >
                <div className="flex gap-2">
                  <IconWrapper size={SIZE.SMALL} className="fill-white">
                    <icons.HandShake />
                  </IconWrapper>
                  {t`Intake form`}
                </div>
              </Button>
            }
          />
        )}
        {intakeFormCompleted && (
          <div className="flex flex-col gap-3">
            <div className="flex w-full flex-col gap-3 xl:flex-row">
              <Card.Root className="flex-1 bg-salmon-01">
                <Card.Header className="items-center gap-2 gap-x-4 md:flex-row">
                  <Card.Title>{t`Medication refill requests`}</Card.Title>
                  <Link
                    to={ROUTES.MY_MEDS}
                    className="hidden font-medium text-nature-08 md:block"
                  >
                    {t`View all requests`}
                  </Link>
                </Card.Header>

                <Card.Content className="text-center md:text-left">
                  <MedicationRequestCard className="border-transparent md:border-salmon-07" />

                  <div className="flex w-full items-center justify-center pb-2 md:hidden">
                    <Link
                      to={ROUTES.MY_MEDS}
                      className="font-medium text-nature-08"
                    >
                      {t`View all requests`}
                    </Link>
                  </div>
                </Card.Content>
              </Card.Root>
              <News />
            </div>

            <UpcomingConsultations
              upcomingConsultations={upcomingConsultations}
            />
          </div>
        )}

        <WeightProgress />
      </ScreenLayout>

      <SetUpPaymentMethodModal patient={patient} isLoading={isPatientLoading} />
      <SetUpFridaysPasswordModal />
    </>
  );
};

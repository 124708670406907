import { DISCOUNT_TYPE, SUBSCRIPTION_PRICE } from "@/shared.constants";
import type { Coupon } from "@/shared.types";

export const getFinalChargedAmount = (coupon: Coupon) => {
  switch (coupon.type) {
    case DISCOUNT_TYPE.PERCENTAGE:
      return (SUBSCRIPTION_PRICE * coupon.percentOff) / 100;
    case DISCOUNT_TYPE.FIXED_AMOUNT:
      return SUBSCRIPTION_PRICE - coupon.amountOff;
    default:
      return SUBSCRIPTION_PRICE;
  }
};

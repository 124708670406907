const BASE_ROUTE = {
  CONSULTATIONS: "/consultations",
  PROFILE: "/profile",
  ONBOARDING: "/onboarding",
  DOCUMENT_CENTER: "/document-center",
  COACHING_HUB: "/coaching-hub",
  PROGRESS_TRACKER: "/progress-tracker",
} as const;

export const ROUTES = {
  BASE: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_USED: "/reset-password-used",
  FORGOT_PASSWORD: "/forgot-password",
  HOME: "/home",
  INTAKE_FORM: "/intake-form",
  CONSULTATIONS: {
    OVERVIEW: `${BASE_ROUTE.CONSULTATIONS}`,
    PURPOSE: `${BASE_ROUTE.CONSULTATIONS}/purpose`,
    FORM: `${BASE_ROUTE.CONSULTATIONS}/form`,
    SCHEDULE: `${BASE_ROUTE.CONSULTATIONS}/schedule`,
  },
  COACHING_HUB: {
    OVERVIEW: BASE_ROUTE.COACHING_HUB,
    PAST_SESSIONS: `${BASE_ROUTE.COACHING_HUB}/past-sessions`,
  },
  DOCUMENT_CENTER: {
    OVERVIEW: BASE_ROUTE.DOCUMENT_CENTER,
    LABS: `${BASE_ROUTE.DOCUMENT_CENTER}/labs`,
    PRESCRIPTIONS: `${BASE_ROUTE.DOCUMENT_CENTER}/previous-prescriptions`,
    MEDICAL_RECORDS: `${BASE_ROUTE.DOCUMENT_CENTER}/medical-records`,
    DOCTOR_NOTES: `${BASE_ROUTE.DOCUMENT_CENTER}/doctor-notes`,
    FRIDAYS_RESOURCES: `${BASE_ROUTE.DOCUMENT_CENTER}/fridays-resources`,
  },
  //   TODO: For messages
  MESSAGES: "/messages",
  BILLING: "/billing",
  PROFILE: {
    OVERVIEW: `${BASE_ROUTE.PROFILE}`,
    EDIT: `${BASE_ROUTE.PROFILE}/edit`,
  },
  MY_MEDS: "/my-meds",
  ONBOARDING: {
    WELCOME: `${BASE_ROUTE.ONBOARDING}/welcome`,
    MAIN_INFO: `${BASE_ROUTE.ONBOARDING}/main-info`,
    JOURNEY_BEGIN: `${BASE_ROUTE.ONBOARDING}/journey-begin`,
    PAYMENT_AND_ACCOUNT: `${BASE_ROUTE.ONBOARDING}/payment-and-account`,
    INTAKE_FORM: `${BASE_ROUTE.ONBOARDING}/intake-form`,
  },
} as const;

export const MODAL_ROUTES = {
  CONSULTATIONS: {
    MEDICATION_REFILLS: `${BASE_ROUTE.CONSULTATIONS}/medication-refills`,
    VIDEO_CONSULTATIONS: `${BASE_ROUTE.CONSULTATIONS}/video-consultations`,
    REFILL_ALREADY_SCHEDULED: `${BASE_ROUTE.CONSULTATIONS}/refill-already-scheduled`,
    ALREADY_SCHEDULED: `${BASE_ROUTE.CONSULTATIONS}/already-scheduled`,
    URGENT_CARE: `${BASE_ROUTE.CONSULTATIONS}/urgent-care`,
    BEST_FIT: `${BASE_ROUTE.CONSULTATIONS}/best-fit`,
    WELL_BEING: `${BASE_ROUTE.CONSULTATIONS}/well-being`,
    REDIRECT_TO_SYNC: `${BASE_ROUTE.CONSULTATIONS}/redirect-to-sync`,
  },
  DOCUMENT_CENTER: {
    LABS: `${BASE_ROUTE.DOCUMENT_CENTER}/labs`,
    PRESCRIPTIONS: `${BASE_ROUTE.DOCUMENT_CENTER}/previous-prescriptions`,
    MEDICAL_RECORDS: `${BASE_ROUTE.DOCUMENT_CENTER}/medical-records`,
    DOCTOR_NOTES: `${BASE_ROUTE.DOCUMENT_CENTER}/doctor-notes`,
    REMOVE_DOCUMENT: `${BASE_ROUTE.DOCUMENT_CENTER}/remove-document`,
    EDIT_DOCUMENT: `${BASE_ROUTE.DOCUMENT_CENTER}/edit-document`,
    PREVIEW_DOCUMENT: `${BASE_ROUTE.DOCUMENT_CENTER}/preview-document`,
  },
  PROGRESS_TRACKER: {
    CREATE_ENTRY: `${BASE_ROUTE.PROGRESS_TRACKER}/create-entry`,
    EDIT_ENTRY: `${BASE_ROUTE.PROGRESS_TRACKER}/edit-entry`,
    DELETE_ENTRY: `${BASE_ROUTE.PROGRESS_TRACKER}/delete-entry`,
  },
  NEXT_STEPS: "/next-steps",
} as const;

const EXTERNAL_BASE_LINK = "https://www.joinfridays.com";
export const EXTERNAL_LINK = {
  HOME: EXTERNAL_BASE_LINK,
  PRIVACY_AND_POLICY: `${EXTERNAL_BASE_LINK}/privacy-policy`,
  TERMS_AND_CONDITIONS: `${EXTERNAL_BASE_LINK}/terms-conditions`,
  TRACKING: "https://www.google.com/search?q=",
  SESH: "https://www.seshfitnessapp.com/landing/fridays-x-sesh",
} as const;

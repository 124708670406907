import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import type { ConfirmCardPaymentData, StripeError } from "@stripe/stripe-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import {
  applyCode,
  createHealthieUser,
  createStripeSubscription,
  getStatesQuery,
  updatePaymentMethod,
} from "@/api";
import { FullScreenLoader, HookedCreditCardInput } from "@/components";
import { SIZE } from "@/shared.constants";
import { paymentSchema } from "@/shared.schemas";
import type { PaymentFormValues } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import {
  Button,
  Checkbox,
  DollarMinimalisticIcon,
  errorToast,
  HookedSelect,
  icons,
  IconWrapper,
  Input,
  useToastStore,
} from "@/ui";
import { convert, getCouponCode, tw } from "@/utils";
import { getCouponMessageFromResponse } from "@/utils/getCouponText";
import { pixelTracking } from "@/utils/pixelTracking";
import { OnboardingHeader } from "../../OnboardingHeader";
import { OnboardingLayout } from "../../OnboardingLayout";
import {
  BillingDetails,
  MembershipInfo,
  PaymentDetails,
  PaymentSuccessAlert,
} from "./components";

interface PaymentFormProps {
  onGoBack: () => void;
  onSubmit: () => void;
}

export const PaymentForm = ({ onSubmit, onGoBack }: PaymentFormProps) => {
  const [showDiscount, setShowDiscount] = useState(true);
  const [couponIsValid, setCouponIsValid] = useState(false);
  const [couponMessage, setCouponMessage] = useState<string>();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [hasAuthorization, setHasAuthorization] = useState<CheckedState>(false);
  const pushToast = useToastStore.getState().pushToast;

  const [canChangeDiscountCode] = useState(!getCouponCode());

  const couponCode = getCouponCode();

  const queryClient = useQueryClient();
  const user = useOnboardingStore((state) => state.user);
  const appointment = useOnboardingStore((state) => state.appointment);
  const setHealthieUserToken = useOnboardingStore(
    (state) => state.setHealthieUserToken,
  );
  const { data: stateOptions, isLoading: isLoadingStates } =
    useQuery(getStatesQuery());

  const elements = useElements();
  const stripe = useStripe();
  const cardElement = elements?.getElement(CardElement);

  const {
    formState: { errors },
    handleSubmit,
    register,
    getValues,
    control,
    setError,
    setValue,
  } = useForm<PaymentFormValues>({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      state: user?.state,
      couponCode: couponCode ?? "",
    },
  });

  const {
    mutate: applyCodeMutation,
    isPending: isPendingApplyCodeMutation,
    data: coupon,
  } = useMutation({
    mutationFn: applyCode.mutation,
    onSuccess: (coupon) => {
      if (coupon.isValid) {
        setShowDiscount(false);
        setCouponMessage(getCouponMessageFromResponse(coupon));
      } else {
        setCouponMessage(t`Coupon code is invalid`);
        setValue("couponCode", "");
      }
      setCouponIsValid(coupon.isValid);
    },
  });

  const {
    mutate: createHealthieUserMutation,
    isPending: isPendingCreateHealthieUserMutation,
  } = useMutation({
    mutationFn: createHealthieUser.mutation,
    onSuccess: (data) => {
      createHealthieUser.invalidates(queryClient);
      setHealthieUserToken(data.token);
      setShowSuccessAlert(true);
    },
    onError: errorToast,
  });

  const {
    mutate: confirmCardPaymentMutation,
    isPending: isPendingConfirmCardPaymentMutation,
  } = useMutation({
    mutationFn: async (params: {
      clientSecret: string;
      data?: ConfirmCardPaymentData;
    }) => {
      if (!stripe) return stripe;
      const response = await stripe.confirmCardPayment(
        params.clientSecret,
        params.data,
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (!getValues("couponCode")) {
        convert(user?.email);
      }

      try {
        pixelTracking(response.paymentIntent.id, 99);
      } catch {
        null;
      }
      return response.paymentIntent;
    },
    onSuccess: () => {
      createStripeSubscription.invalidates(queryClient);
      user &&
        createHealthieUserMutation({
          userId: user.id,
          providerId: appointment?.providerId,
          appointmentDate: appointment?.dateTime,
          ...getValues(),
        });
    },
    onError: (err: StripeError) => errorToast(err.message),
  });

  const {
    mutate: updatePaymentMethodMutation,
    isPending: isPendingCreatePaymentMethodMutation,
  } = useMutation({
    mutationFn: updatePaymentMethod.mutation,
    onSuccess: () => {
      createStripeSubscription.invalidates(queryClient);
      user &&
        createHealthieUserMutation({
          userId: user.id,
          providerId: appointment?.providerId,
          appointmentDate: appointment?.dateTime,
          ...getValues(),
        });
    },
    onError: () => {
      errorToast(
        t`Error updating payment method, try again later or use a different card.`,
      );
    },
  });

  const {
    mutate: createStripeSubscriptionMutation,
    isPending: isPendingCreateStripeSubscriptionMutation,
  } = useMutation({
    mutationFn: createStripeSubscription.mutation,
    onSuccess: (data) => {
      createStripeSubscription.invalidates(queryClient);
      const { zip, creditCard, phoneNumber, ...locationValues } = getValues();
      const canConfirmPayment = cardElement && creditCard && phoneNumber;

      canConfirmPayment &&
        data.clientSecret &&
        confirmCardPaymentMutation({
          clientSecret: data.clientSecret,
          data: {
            payment_method: {
              card: cardElement,
              billing_details: {
                address: {
                  line1: locationValues.line1,
                  state: locationValues.state,
                  city: locationValues.city,
                  line2: locationValues.line2,
                  country: "US",
                  postal_code: zip,
                },
                email: user?.email,
                name: `${user?.firstName}${
                  user?.lastName ? ` ${user.lastName}` : ""
                }`,
              },
            },
          },
        });

      if (canConfirmPayment && !data.clientSecret && user) {
        stripe
          ?.createPaymentMethod({
            type: "card",
            card: cardElement,
          })
          .then((result) => {
            updatePaymentMethodMutation({
              paymentMethodId: result.paymentMethod?.id,
              userId: user.id,
            });
          })
          .catch(() => {
            errorToast(
              t`Error updating payment method, try again later or use a different card.`,
            );
          });
      }
    },
    onError: (
      error: AxiosError<{ status: number; error: { code: string } }>,
    ) => {
      const errorData = error.response?.data;

      const isReferralCodeError =
        errorData &&
        errorData.status === 503 &&
        errorData.error.code === "stripe_subscription_exception";

      if (isReferralCodeError) {
        setError("couponCode", { message: "Invalid coupon code" });
        void pushToast({
          type: "error",
          title: t`Coupon code is invalid`,
          message: t`If you want to continue enter a valid coupon or remove it`,
        });
      } else {
        errorToast(error);
      }
    },
  });

  const handleOnSubmit = () => {
    user?.id &&
      createStripeSubscriptionMutation({
        userId: user.id,
        couponCode: getValues("couponCode") ?? couponCode,
      });
  };

  const isPending =
    isPendingCreateStripeSubscriptionMutation ||
    isPendingConfirmCardPaymentMutation ||
    isPendingCreateHealthieUserMutation ||
    isPendingCreatePaymentMethodMutation;

  return (
    <>
      <OnboardingLayout>
        <div className="relative flex w-full max-w-4xl flex-col items-center gap-6 pb-10 sm:gap-8 sm:pb-20">
          <OnboardingHeader
            title={t`Payment details`}
            description={t`(*) Mandatory field`}
          />

          <div className="flex flex-col gap-8 sm:gap-10">
            <MembershipInfo showDiscount={showDiscount} />

            <form
              id="payment-form"
              onSubmit={handleSubmit(handleOnSubmit)}
              className="grid grid-cols-2 gap-x-5 gap-y-1.5"
            >
              <h3 className="col-span-2 mb-2 text-lg font-medium">{t`Shipping Address`}</h3>

              <Input
                autoComplete="billing street-address"
                compact={false}
                id="line1"
                label={t`Address *`}
                placeholder={t`E.g. 123, Main Street`}
                left={<icons.Home />}
                {...register("line1")}
                error={errors.line1?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />

              <Input
                autoComplete="address-line2"
                compact={false}
                id="line2"
                label={t`Apartment Number`}
                placeholder={t`E.g. 1A`}
                left={<icons.Home />}
                {...register("line2")}
                error={errors.line2?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />

              <Input
                autoComplete="postal-code"
                compact={false}
                id="zip"
                label={t`Zip code *`}
                placeholder={t`E.g. 12345`}
                left={<icons.Home />}
                {...register("zip")}
                error={errors.zip?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <Input
                autoComplete="home city"
                compact={false}
                id="city"
                label={t`City *`}
                placeholder={t`E.g. Phoenix`}
                left={<icons.Home />}
                {...register("city")}
                error={errors.city?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <HookedSelect
                disabled={isLoadingStates}
                autocomplete
                compact={false}
                label={t`State *`}
                placeholder={t`E.g. New York`}
                id="state"
                name="state"
                left={<icons.Home />}
                control={control}
                options={stateOptions ?? []}
                error={errors.state?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <Input
                autoComplete="phoneNumber"
                compact={false}
                id="phoneNumber"
                label={t`Phone number *`}
                placeholder={t`E.g. (123) 456-7890`}
                left={<icons.DevicePhone />}
                {...register("phoneNumber")}
                error={errors.phoneNumber?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <h3 className="col-span-2 mb-2 text-lg font-medium">{t`Payment Information`}</h3>
              <HookedCreditCardInput
                id="onboarding-cc-number"
                name="creditCard"
                label={t`Card number *`}
                control={control}
                error={errors.creditCard?.message}
                containerClassName="col-span-2 sm:col-span-1"
              />
              <div className="col-span-2 flex flex-col gap-2 sm:col-span-1 md:flex-row">
                <Input
                  id="couponCode"
                  label={t`Coupon code`}
                  placeholder={t`Optional coupon code`}
                  {...register("couponCode")}
                  error={errors.couponCode?.message}
                  containerClassName="col-span-2 sm:col-span-1"
                  message={couponMessage}
                  messageClassName={
                    couponIsValid ? "text-green-08" : "text-red-08"
                  }
                  left={<DollarMinimalisticIcon />}
                  disabled={!canChangeDiscountCode}
                />
                <div
                  className={tw(
                    "flex h-fit sm:mt-1.5 sm:pt-5",
                    canChangeDiscountCode && "pb-5",
                  )}
                >
                  <Button
                    size={SIZE.LARGE}
                    onClick={() => applyCodeMutation(getValues("couponCode"))}
                    disabled={
                      isPendingApplyCodeMutation || !canChangeDiscountCode
                    }
                    className="w-full sm:w-fit"
                  >
                    {t`Apply`}
                  </Button>
                </div>
              </div>
              {!canChangeDiscountCode && (
                <div className="col-span-2 pb-5 text-left text-sm md:col-span-1 md:col-start-2">{t`You already have a referral discount applied!`}</div>
              )}
              <Checkbox
                id="authorization"
                checked={hasAuthorization}
                onCheckedChange={setHasAuthorization}
                label={t`I authorize the use of this credit card for my membership, medication and lab charges`}
                containerClassName="col-span-2"
              />
            </form>

            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-4">
                <BillingDetails />

                <PaymentDetails coupon={coupon} />
              </div>

              <div className="flex items-center justify-between gap-4">
                <Button
                  disabled={isPending}
                  variant="secondary"
                  size="lg"
                  onClick={onGoBack}
                >
                  <IconWrapper size="sm">
                    <icons.ChevronLeft />
                  </IconWrapper>
                  {t`Back`}
                </Button>
                <Button
                  disabled={!hasAuthorization || isPending}
                  type="submit"
                  form="payment-form"
                  size="lg"
                >
                  {t`Pay`}
                  <IconWrapper size="sm">
                    <icons.ChevronRight />
                  </IconWrapper>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </OnboardingLayout>

      {isPending && (
        <FullScreenLoader
          description={
            <p className="text-center text-2xl font-medium text-salmon-01">
              {t`We are processing your payment, just give us a moment.`}
              <br />
              {t`You'll be able to create your Fridays account shortly!`}
            </p>
          }
        />
      )}

      <PaymentSuccessAlert
        show={showSuccessAlert}
        onContinue={() => {
          setShowSuccessAlert(false);
          onSubmit();
        }}
      />
    </>
  );
};

import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { Shape10 } from "@/ui";
import { tw } from "@/utils";
import { IsoLogo } from "./IsoLogo";

const Cover = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "relative z-10 flex shrink-0 items-center justify-center overflow-hidden bg-nature-09 p-4.5",
        className,
      )}
      {...props}
    >
      <Shape10 className="absolute -top-2.5 left-2 -z-10 size-20 text-nature-06 opacity-30" />
      <IsoLogo className="h-12" />
    </div>
  ),
);
Cover.displayName = "FridaysCard.Cover";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex overflow-hidden rounded-2xl bg-nature-03", className)}
      {...props}
    >
      <Cover />
      <div className="flex grow flex-col lg:flex-row">{children}</div>
    </div>
  ),
);
Root.displayName = "FridaysCard.Root";

const Content = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("flex grow flex-col gap-2 px-6 py-4", className)}
      {...props}
    />
  ),
);
Content.displayName = "FridaysCard.Content";

const Title = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h3
    ref={ref}
    className={tw("text-lg font-bold leading-6 text-nature-10", className)}
    {...props}
  >
    {children}
  </h3>
));
Title.displayName = "FridaysCard.Title";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={tw("font-medium leading-5 text-nature-08", className)}
    {...props}
  />
));
Description.displayName = "FridaysCard.Description";

const Actions = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={tw(
        "flex w-full items-center justify-center p-4 pt-0 lg:w-fit lg:py-0 lg:pl-0 [&>*]:w-full",
        className,
      )}
      {...props}
    />
  ),
);
Actions.displayName = "FridaysCard.Actions";

export { Root, Content, Title, Description, Actions };

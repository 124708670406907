import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { getProviderDocumentsQuery } from "@/api";
import { COACHING_SESSION_ICON, COACHING_SESSION_LABEL } from "@/components";
import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import type { CoachingSessionType } from "@/shared.types";
import {
  AltArrowRightIcon,
  Badge,
  Button,
  CardPrimitive as Card,
  EmptyState,
  IconWrapper,
  Skeleton,
} from "@/ui";
import { formatBackendDate } from "@/utils";

const SESSIONS_LIMIT = 6;

interface CoachingCardProps {
  sessionType: CoachingSessionType;
}

export const CoachingSessionCard = ({ sessionType }: CoachingCardProps) => {
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();

  const { data: sessions, isLoading } = useQuery(
    getProviderDocumentsQuery({
      providerFolder: sessionType,
      limit: SESSIONS_LIMIT,
    }),
  );

  return (
    <Card.Root className="grid bg-brown-02 text-brown-10">
      <Card.Title className="gap-1 text-xl capitalize">
        <IconWrapper>{COACHING_SESSION_ICON[sessionType]}</IconWrapper>
        {t`${COACHING_SESSION_LABEL[sessionType]} Coaching`}
      </Card.Title>

      <div className="col-span-full row-start-2 pb-1 md:col-span-2 md:pb-0">
        <div className="grid rounded-2xl bg-brown-01 px-4 py-2 lg:grid-cols-3 lg:gap-4 lg:bg-transparent lg:p-0">
          {isLoading &&
            Array.from({ length: 3 }, (_, index) => `${index}`).map((i) => (
              <Card.Root
                key={i}
                className="flex flex-col gap-3 rounded-none border-b bg-brown-01 last:border-b-0 lg:rounded-2xl lg:border-b-0"
              >
                <Skeleton className="h-6 w-44 py-1" />
                <Skeleton className="h-6 w-20 py-1" />
              </Card.Root>
            ))}

          {sessions?.length === 0 && (
            <EmptyState.Root className="col-span-full border-opacity-0 lg:border-opacity-35">
              <EmptyState.Description>
                {t`There are no past ${COACHING_SESSION_LABEL[sessionType]} sessions yet`}
              </EmptyState.Description>
            </EmptyState.Root>
          )}

          {sessions?.map((session) => (
            <Card.Root
              key={session.id}
              onClick={() =>
                navigateModal(
                  `${MODAL_ROUTES.DOCUMENT_CENTER.PREVIEW_DOCUMENT}/${sessionType}/${session.id}`,
                )
              }
              className="cursor-pointer justify-between rounded-none border-b bg-brown-01 last:border-b-0 lg:rounded-2xl lg:border-b-0"
            >
              <Card.Title className="line-clamp-2 text-base">
                {session.name}
              </Card.Title>
              <Badge variant="outline" size="md">
                {formatBackendDate(session.createdAt)}
              </Badge>
            </Card.Root>
          ))}
        </div>
      </div>

      <Button
        variant="secondary"
        size="lg"
        onClick={() =>
          navigate(`${ROUTES.COACHING_HUB.PAST_SESSIONS}/${sessionType}`)
        }
        className="col-span-full row-start-3 md:col-span-1 md:col-start-2 md:row-start-1 md:w-fit md:justify-self-end md:border-none md:p-2 md:text-xs"
      >
        {t`View more`}
        <IconWrapper size="xs" className="hidden md:block">
          <AltArrowRightIcon />
        </IconWrapper>
      </Button>
    </Card.Root>
  );
};

import { t } from "ttag";

import type { ModalProps } from "@/shared.types";
import { LightbulbFixedIconMono, Modal, Stepper } from "@/ui";
import { Step1, Step2, Step3, Step4 } from "./components";

const STEPS = [
  { value: "step-1", children: <Step1 /> },
  { value: "step-2", children: <Step2 /> },
  { value: "step-3", children: <Step3 /> },
  { value: "step-4", children: <Step4 /> },
] as const;

export const NextStepsModal = ({ show, onClose }: ModalProps) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      icon={<LightbulbFixedIconMono />}
      title={t`Next steps`}
    >
      <Stepper.Root steps={STEPS} className="-mt-6 gap-6">
        <div className="flex w-full flex-col items-center">
          <Stepper.Bar />

          <Stepper.Content />
        </div>

        <Stepper.Actions onComplete={onClose} completeLabel={t`Close`} />
      </Stepper.Root>
    </Modal>
  );
};

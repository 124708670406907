import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "ttag";

import { submitDocuments } from "@/api";
import type { UploadFilesValues } from "@/shared.types";
import {
  Button,
  errorToast,
  IconWrapper,
  Loader,
  OverlayLoader,
  useToastStore,
} from "@/ui";
import { tw } from "@/utils";
import { DOCUMENT_LABEL } from "../components.constants";

interface DocumentUploadsSubmitProps {
  onCancel: () => void;
  onSuccess: () => void;
}

export const DocumentUploadsSubmit = ({
  onCancel,
  onSuccess,
}: DocumentUploadsSubmitProps) => {
  const { pushToast } = useToastStore();
  const {
    handleSubmit,
    formState: { errors, isDirty, isValidating },
    control,
  } = useFormContext<UploadFilesValues>();
  const folderName = useWatch({ control, name: `type` });

  const queryClient = useQueryClient();
  const { mutate: submitDocumentsMutation, isPending } = useMutation({
    mutationFn: submitDocuments.mutation,
    onSuccess: () => {
      onSuccess();
      submitDocuments.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Document successfully uploaded!`,
      });
    },
    onError: errorToast,
  });

  const handleSave = handleSubmit((values) => {
    submitDocumentsMutation(values);
  });

  const hasUploadErrors = Boolean(errors.files);
  const canSubmit = !hasUploadErrors && isDirty && !isValidating && !isPending;

  return (
    <div className="flex items-center justify-center gap-3.5">
      <Button onClick={onCancel} size="lg" variant="secondary">
        Cancel
      </Button>

      <Button
        size="lg"
        disabled={!canSubmit}
        onClick={handleSave}
        className={tw("relative", isPending && "text-transparent")}
      >
        {t`Save ${DOCUMENT_LABEL[folderName]}`}
        {isPending && (
          <IconWrapper className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
            <Loader variant="dark" />
          </IconWrapper>
        )}
      </Button>

      {isPending && <OverlayLoader />}
    </div>
  );
};

import { Skeleton } from "@/ui";
import { tw } from "@/utils";

interface CardSkeletonProps {
  className?: string;
}

export const CardSkeleton = ({ className }: CardSkeletonProps) => {
  return (
    <div className={tw("rounded-2xl bg-salmon-02 p-4", className)}>
      <div className="flex gap-7">
        <div className="flex flex-1 flex-col gap-3">
          <Skeleton className="h-5 rounded-full" />
          <Skeleton className="h-5 rounded-full" />
          <Skeleton className="h-5 rounded-full" />
        </div>

        <div className="flex w-1/4 flex-col justify-between">
          <Skeleton className="h-5 rounded-full bg-salmon-05" />
          <Skeleton className="h-5 w-2/3 self-end rounded-full" />
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { CONSULTATION_PURPOSE, FORM_STEPS } from "@/shared.constants";
import { useConsultationStore } from "@/stores";
import { icons, ScreenHeader } from "@/ui";
import { tw } from "@/utils";
import { FollowUp } from "./FollowUp";
import { Injections } from "./Injections";
import { InjectionsNote } from "./InjectionsNote";
import { RefillForm } from "./RefillForm";
import { Symptoms } from "./Symptoms";

type Step = "symptoms" | "injections" | "followUp" | "refill";

export const ConsultationForm = () => {
  const [step, setStep] = useState<Step>(FORM_STEPS.SYMPTOMS);
  const refill = useConsultationStore(
    (state) => state.purpose === CONSULTATION_PURPOSE.REFILL,
  );
  const setPurpose = useConsultationStore((state) => state.setPurpose);

  return (
    <main
      className={tw(
        "relative flex w-full flex-col px-4 pt-4 md:px-8 md:pt-12 lg:px-16",
        step === FORM_STEPS.INJECTIONS && "gap-6",
      )}
    >
      <ScreenHeader
        variant="secondary"
        title={refill ? t`Medication refill request` : t`Follow up form`}
        description={
          (step === FORM_STEPS.SYMPTOMS &&
            t`These questions are required before further medication can be prescribed. Keep in mind that if you select any of the symptoms listed on this screen, you won't be able to continue with the Fridays program.`) ||
          (step === FORM_STEPS.INJECTIONS &&
            t`This question is required before further medication can be prescribed.`) ||
          (step === FORM_STEPS.FOLLOW_UP && (
            <div className="mt-2 flex max-w-2xl flex-col gap-3 sm:mt-0">
              <InjectionsNote />
              <span>{t`Please take a moment to provide your responses to these follow-up questions. Your input is essential in guiding us to offer optimal care tailored to your progress.`}</span>

              <span>{t`(*) Required fields`}</span>
            </div>
          )) ||
          (step === FORM_STEPS.REFILL && (
            <div className="mt-2 flex max-w-2xl flex-col gap-3 sm:mt-0">
              <InjectionsNote />
              <span>{t`Please spare a moment to fill out the medication refill request form. Your input is vital in facilitating a smooth and efficient refill process customized to your requirements.`}</span>
              <span>{t`(*) Required fields`}</span>
            </div>
          )) ||
          ""
        }
        paths={[
          {
            href: ROUTES.CONSULTATIONS.OVERVIEW,
            icon: icons.Calendar,
            label: t`Consultations`,
          },
          {
            href: ROUTES.CONSULTATIONS.PURPOSE,
            icon: icons.HandShake,
            label: t`Consultation Purpose`,
          },
          {
            href: ROUTES.CONSULTATIONS.FORM,
            icon: icons.DocumentAdd,
            label: refill ? t`Medication refill request` : t`Follow up form`,
          },
        ]}
      />

      {step === FORM_STEPS.SYMPTOMS && (
        <Symptoms onSubmit={() => setStep(FORM_STEPS.INJECTIONS)} />
      )}
      {step === FORM_STEPS.INJECTIONS && (
        <Injections
          onSubmit={() =>
            setStep(refill ? FORM_STEPS.REFILL : FORM_STEPS.FOLLOW_UP)
          }
        />
      )}

      {step === FORM_STEPS.FOLLOW_UP && <FollowUp />}
      {step === FORM_STEPS.REFILL && (
        <RefillForm
          redirectToFollowUp={() => {
            setPurpose(CONSULTATION_PURPOSE.CONSULTATION);
            setStep(FORM_STEPS.FOLLOW_UP);
          }}
        />
      )}
    </main>
  );
};

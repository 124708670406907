import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import { getChatQuery, sendMessageMutation } from "@/api/messages";
import { ACCEPT_IMAGE_FILE, ATTACHMENT_TYPES } from "@/shared.constants";
import type { ChatMessage } from "@/shared.types";
import { useUserStore } from "@/stores";
import {
  CloseIcon,
  DocumentIcon,
  Dropzone,
  errorToast,
  icons,
  IconWrapper,
  Input,
  PlaneIcon,
  PlusIcon,
} from "@/ui";
import { bytesToMegabytes, tw } from "@/utils";
import { CreateNewChat } from "./CreateNewChat";
import { Message } from "./Message";
import { NewChatSkeleton } from "./NewChatSkeleton";

export default function Chat({
  conversationId,
  createNewChat,
  handleChatCreated,
  newConversationId,
  goBack,
}: {
  conversationId?: string | null;
  createNewChat: boolean;
  handleChatCreated: (conversationId: string) => void;
  newConversationId: string | null;
  goBack: () => void;
}) {
  const queryClient = useQueryClient();
  const { user } = useUserStore();
  const {
    data: chat,
    isLoading,
    refetch,
  } = useQuery({
    ...getChatQuery(conversationId),
    enabled: !!conversationId,
    refetchOnWindowFocus: false,
  });

  const { mutate: sendMessageMutate } = useMutation({
    mutationFn: sendMessageMutation.mutation,
    onError: (err) => {
      errorToast(err);
    },
    onSuccess: () => {
      sendMessageMutation.invalidates(queryClient, conversationId);
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
  } = useForm<{ body: string; attachmentFiles?: File }>({});
  const [messageHasAttachment, setMessageHasAttachment] = useState(false);

  const onSubmit = (data: { body: string; attachmentFiles?: File }) => {
    if (!conversationId) return;
    if (!data.body.trim() && !data.attachmentFiles) return;

    sendMessageMutate({
      conversationId: conversationId,
      ...data,
    });
    setMessageHasAttachment(false);
    chat?.messages.push({
      id: (chat?.messages.length + 1).toString(),
      body: data.body,
      timestamp: format(new Date(), "MM/dd/yyyy HH:mm"),
      author: user?.name,
      isFromUser: true,
      attachmentType: messageHasAttachment
        ? ATTACHMENT_TYPES.PLACEHOLDER
        : null,
    } as ChatMessage);
    reset();
  };

  const ref = useRef<HTMLDivElement>(null);

  const [lastMessageLoaded, setLastMessageLoaded] = useState(false);
  useEffect(() => {
    ref.current?.scrollIntoView({ block: "center" });
  }, [ref, lastMessageLoaded, chat, chat?.messages.length]);

  const setLastMessageLoadedHandler = (value: boolean) => {
    setTimeout(() => {
      setLastMessageLoaded(value);
    }, 100);
  };

  const checkSetRef = (index: number) => {
    const isLastMessage = index === (chat?.messages.length ?? 0) - 1;
    if (isLastMessage) {
      setLastMessageLoadedHandler(true);
      return ref;
    }
    return null;
  };

  const isNewMessage = !chat?.title && (createNewChat || newConversationId);

  return (
    <div
      className={tw(
        "col-span-3 flex h-full max-h-full flex-col overflow-auto bg-salmon-02 md:bg-transparent",
        !conversationId &&
          !createNewChat &&
          !newConversationId &&
          "hidden md:flex",
      )}
    >
      <div className="mx-4 mt-4 flex flex-col gap-2 rounded-2xl bg-nature-08 pb-4 pl-4 pr-8 pt-6 md:mx-0 md:mt-0 md:rounded-b-none md:pr-12">
        <div className="flex items-center justify-start md:justify-between">
          <IconWrapper
            className={tw(
              "cursor-pointer text-nature-01 md:hidden",
              createNewChat && "hidden",
            )}
          >
            <icons.ChevronLeft onClick={goBack} />
          </IconWrapper>
          <div className="flex items-center gap-2 text-lg font-bold text-nature-01">
            <IconWrapper>
              <icons.Envelope className="stroke-nature-01" />
            </IconWrapper>
            {conversationId && chat?.title && `${chat?.title}`}
            {isNewMessage
              ? t`New message`
              : !conversationId && t`Your inbox awaits!`}
          </div>
        </div>
        <div className="ml-8 text-xs font-medium text-nature-03">
          {conversationId &&
            chat?.createdAt &&
            t`Issue created on ${chat?.createdAt}`}
        </div>
      </div>
      {!createNewChat && (
        <div className="flex h-full flex-col gap-4 overflow-auto bg-salmon-02 py-8 pl-4 pr-8">
          {isLoading && <NewChatSkeleton />}
          {conversationId ? (
            <>
              {!isLoading &&
                chat?.messages.map((message, index) => (
                  <div ref={checkSetRef(index)} key={message.id}>
                    <Message key={message.id} message={message} />
                  </div>
                ))}
            </>
          ) : (
            !newConversationId && (
              <div className="flex h-full flex-col justify-around text-xl font-medium text-brown-08">
                <div className="flex flex-col items-center px-10 text-center">
                  <IconWrapper className="h-40 w-40">
                    <icons.NewMessage className="h-40 w-40 stroke-transparent" />
                  </IconWrapper>
                  {t`Dive into your conversations to catch up on what's happening. Select a chat to begin or explore new messages. Let's get talking!`}
                </div>
              </div>
            )
          )}
        </div>
      )}
      {createNewChat && (
        <CreateNewChat
          handleChatCreated={(newConversationId: string) => {
            handleChatCreated(newConversationId);
            void refetch();
          }}
        />
      )}
      {chat && (
        <form
          className="flex gap-2 rounded-b-2xl bg-salmon-03 p-4 pr-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="hidden">
            <Dropzone
              id="chat-image-upload"
              accept={ACCEPT_IMAGE_FILE}
              onDrop={(files) => {
                setValue("attachmentFiles", files[0]);
                setMessageHasAttachment(true);
              }}
            />
          </div>
          {/* eslint-disable-next-line */}
          <label
            className={tw(
              "flex size-11 cursor-pointer items-center justify-center rounded-lg bg-nature-08",
              messageHasAttachment && "bg-brown-04",
            )}
            htmlFor="chat-image-upload"
          >
            <IconWrapper className="items-center justify-center text-salmon-01">
              <PlusIcon className="size-4" />
            </IconWrapper>
          </label>
          <div className="flex w-full flex-col gap-1">
            <Input
              id="body"
              placeholder={t`Type a message`}
              right={
                <IconWrapper as="button" className="size-4 text-brown-09">
                  <PlaneIcon />
                </IconWrapper>
              }
              {...register("body")}
              error={errors.body?.message}
            />
            {messageHasAttachment && (
              <div className="flex w-fit items-center gap-1 rounded-lg bg-salmon-05 p-2 text-salmon-10">
                <IconWrapper className="size-4">
                  <DocumentIcon />
                </IconWrapper>
                {getValues("attachmentFiles")?.name}{" "}
                {bytesToMegabytes(getValues("attachmentFiles")?.size ?? 0)}
                <button
                  className="ml-2"
                  onClick={() => {
                    setValue("attachmentFiles", undefined);
                    setMessageHasAttachment(false);
                  }}
                >
                  <IconWrapper className="size-4">
                    <CloseIcon />
                  </IconWrapper>
                </button>
              </div>
            )}
          </div>
        </form>
      )}
    </div>
  );
}

import { t } from "ttag";

import { VARIANT } from "@/shared.constants";
import { Button } from "@/ui";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";
import { IntakeFormBackground } from "./Background";

interface IntakeFormCoverProps {
  onContinue: () => void;
}

export const IntakeFormCover = ({ onContinue }: IntakeFormCoverProps) => {
  return (
    <OnboardingLayout
      showProgressBar={false}
      variant={VARIANT.SECONDARY}
      background={<IntakeFormBackground />}
    >
      <div className="flex h-full grow flex-col items-center gap-8 pb-10 sm:max-w-3xl sm:pb-20">
        <OnboardingHeader
          title={
            <>
              {t`You are almost there!`}
              <br />
              {t`Complete your intake form to save your spot`}
            </>
          }
          variant={VARIANT.SECONDARY}
        />

        <p className="max-h-80 grow text-center text-lg text-nature-02 sm:-mt-2 sm:h-fit sm:max-w-2xl sm:grow-0 sm:text-xl">{t`To ensure your provider can review your information thoroughly, we kindly ask for a few more details. Without these, we won't be able to provide medication or schedule appointments with you.`}</p>

        <Button
          onClick={onContinue}
          size="lg"
          className="w-full bg-salmon-02 text-salmon-10 hover:bg-salmon-03 disabled:bg-nature-02 disabled:opacity-30 sm:w-fit"
        >{t`Continue`}</Button>
      </div>
    </OnboardingLayout>
  );
};

import type { ReactNode } from "react";
import { t } from "ttag";

import { SIZE } from "@/shared.constants";
import { icons, IconWrapper } from "@/ui";

interface AlertCardProps {
  title: string;
  description: string;
  button?: ReactNode;
}

export const AlertCard = ({ title, description, button }: AlertCardProps) => {
  return (
    <div className="flex flex-col gap-2 rounded-2xl bg-yellow-02 p-4 text-yellow-10 md:flex-row">
      <div className="flex gap-1">
        <IconWrapper size={SIZE.LARGE} className="mt-1 fill-yellow-10">
          <icons.ExclamationCircle />
        </IconWrapper>

        <div className="flex flex-col text-xl">
          <p className="font-bold">{t`${title}`}</p>
          <p>{t`${description}`}</p>
        </div>
      </div>

      {button}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { isAfter, isBefore, subMinutes } from "date-fns";

import { getUpcomingConsultationsQuery } from "@/api";

const MINUTES_BEFORE_CALL_STARTS = 10;
const CHECK_INTERVAL_DURATION = 1000 * 60; // Check every minute

export const useCanJoin = (
  startTime: string,
  endTime: string,
  enabled: boolean,
) => {
  const [canJoin, setCanJoin] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const start = new Date(startTime);
    const end = new Date(endTime);
    const enableTime = subMinutes(start, MINUTES_BEFORE_CALL_STARTS);

    const updateCanJoin = () => {
      const now = new Date();
      if (isAfter(now, enableTime) && isBefore(now, end)) {
        setCanJoin(true);
      } else {
        setCanJoin(false);
      }

      if (isAfter(now, end)) {
        void queryClient.invalidateQueries({
          queryKey: getUpcomingConsultationsQuery().queryKey,
        });
      }
    };

    updateCanJoin();
    const intervalId = setInterval(updateCanJoin, CHECK_INTERVAL_DURATION);

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [startTime, endTime, enabled, queryClient]);

  return { canJoin };
};

import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { Schedule } from "@/components";
import { ROUTES } from "@/router";
import { useOnboardingStore } from "@/stores";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";

interface JourneyBeginScheduleProps {
  onGoBack: () => void;
}

export const JourneyBeginSchedule = ({
  onGoBack,
}: JourneyBeginScheduleProps) => {
  const { setAppointment, user } = useOnboardingStore();
  const navigate = useNavigate();

  return (
    <OnboardingLayout>
      <div className="flex w-full flex-col items-center gap-6 pb-10 sm:gap-8 sm:pb-20">
        <OnboardingHeader
          title={t`Lets meet!`}
          description={t`Choose the perfect time and date`}
        />

        <Schedule
          state={user?.state}
          onConfirm={(appointment) => {
            setAppointment(appointment);
            navigate(ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT);
          }}
          onGoBack={onGoBack}
          canChooseProvider={false}
        />
      </div>
    </OnboardingLayout>
  );
};

import * as Tooltip from "@radix-ui/react-tooltip";
import { t } from "ttag";

import { getDiscountText } from "@/utils";
import { MEMBERSHIP_BENEFITS } from "../membershipBenefits";

export const MembershipInfo = ({ showDiscount }: { showDiscount: boolean }) => {
  const discountText = getDiscountText();

  return (
    <div className="flex w-full flex-col items-center gap-5 rounded-2xl bg-salmon-02 p-4.5 md:flex-row">
      <div className="self-start sm:self-auto">
        <h3 className="text-lg font-medium text-salmon-08">{t`Membership`}</h3>
        <p className="font-serif text-salmon-08">
          <span className="text-5xl leading-snug text-salmon-10">$99</span>
          <span className="text-5xl">/</span>
          <span className="text-3xl">{t`month`}</span>
        </p>
        {showDiscount && discountText && (
          <p className="mt-2 rounded-2xl bg-salmon-01 p-4 text-center font-medium text-brown-09">
            {t`🎉 As a special treat for your first month, you're getting a discount of `}
            {discountText}
            {t` off your purchase.`}
          </p>
        )}
      </div>

      <div
        role="separator"
        aria-hidden="true"
        className="h-full w-full border-t border-salmon-06 md:w-0 md:border-r"
      />

      <div className="flex h-full grow flex-col gap-3">
        <h3 className="text-lg font-medium text-salmon-08">{t`What does it include?`}</h3>
        <div className="flex flex-wrap gap-x-1 gap-y-2">
          <Tooltip.Provider delayDuration={150}>
            {MEMBERSHIP_BENEFITS.map((benefit) => (
              <Tooltip.Root key={`membership-benefit-${benefit.label}`}>
                <Tooltip.Trigger className="rounded-l-full rounded-r-full bg-salmon-04 px-2.5 py-0.5 text-sm text-salmon-10 duration-150 data-[state=delayed-open]:bg-salmon-10 data-[state=instant-open]:bg-salmon-10 data-[state=delayed-open]:text-salmon-01 data-[state=instant-open]:text-salmon-01">
                  {benefit.label}
                </Tooltip.Trigger>
                <Tooltip.Content className="max-w-60 rounded-2xl border border-salmon-05 bg-salmon-10 p-3.5 text-xs text-brown-01 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=top]:slide-in-from-bottom-2">
                  {benefit.detail}
                  <Tooltip.Arrow
                    className="z-10 -translate-y-0.5 fill-salmon-10"
                    width={23}
                    height={12}
                  />
                </Tooltip.Content>
              </Tooltip.Root>
            ))}
          </Tooltip.Provider>
        </div>
      </div>
    </div>
  );
};

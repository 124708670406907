import { zodResolver } from "@hookform/resolvers/zod";
import { endOfMonth, subYears } from "date-fns";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import { GENDER_OPTIONS, NOW } from "@/shared.constants";
import { birthInfoSchema } from "@/shared.schemas";
import type { BirthData, OnboardingBirthInfoFormValues } from "@/shared.types";
import { HookedDatePicker, HookedSelect, icons } from "@/ui";
import { calculateAge, isValidAge } from "@/utils";
import { OnboardingActionButtons } from "../OnboardingActionButtons";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";
import { VALIDATION_ERROR } from "./mainInfo.constants";
import type { ValidationError } from "./mainInfo.types";

interface BirthInfoFormProps {
  savedData?: BirthData | null;
  onValidation: (validationError: ValidationError) => void;
  onGoBack: () => void;
  onSuccess: (birthInfo: BirthData) => void;
}

export const BirthInfo = ({
  savedData,
  onValidation,
  onGoBack,
  onSuccess,
}: BirthInfoFormProps) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<OnboardingBirthInfoFormValues>({
    resolver: zodResolver(birthInfoSchema),
    defaultValues: { ...savedData },
  });

  const preSubmitValidation = (values: OnboardingBirthInfoFormValues) => {
    const { dateOfBirth } = values;
    const age = calculateAge(dateOfBirth);

    if (!isValidAge(age)) {
      onValidation(VALIDATION_ERROR.AGE_RANGE);
    } else {
      onSuccess(values);
    }
  };

  return (
    <OnboardingLayout>
      <div className="flex min-h-modal w-full flex-col gap-6 pb-10 sm:min-h-0 sm:items-center sm:gap-12 sm:pb-20 sm:pt-16">
        <OnboardingHeader
          title={t`Your main info`}
          description={t`(*) Mandatory field`}
        />

        <form className="flex w-full max-w-3xl flex-col gap-3.5 sm:gap-6">
          <div className="grid gap-x-5 gap-y-1.5 sm:grid-cols-2">
            <HookedDatePicker
              compact={false}
              label={t`Date of birth *`}
              placeholder={t`E.g. 01/01/1990`}
              id="dateOfBirth"
              name="dateOfBirth"
              left={<icons.Calendar />}
              control={control}
              error={errors.dateOfBirth?.message}
              config={{
                years: {
                  mode: "exact",
                  numberOfYears: 100,
                  step: 0,
                },
                dates: {
                  maxDate: endOfMonth(NOW),
                  minDate: endOfMonth(subYears(NOW, 99)),
                },
              }}
            />
            <HookedSelect
              compact={false}
              label={t`Sex assigned at birth *`}
              placeholder={t`Select a gender`}
              id="gender"
              name="gender"
              left={<icons.Gender />}
              control={control}
              options={GENDER_OPTIONS}
              error={errors.gender?.message}
            />
          </div>
          <OnboardingActionButtons
            onCancel={onGoBack}
            continueLabel={t`Next`}
            onContinue={handleSubmit(preSubmitValidation)}
          />
        </form>
      </div>
    </OnboardingLayout>
  );
};

import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  getCurrentMedicationRequestQuery,
  getPatientQuery,
  getUpcomingConsultationsQuery,
} from "@/api";
import { MODAL_ROUTES, ROUTES } from "@/router";
import { useNavigateModal } from "@/router/useNavigateModal";
import { CONSULTATION_PURPOSE, NOW, VARIANT } from "@/shared.constants";
import { useConsultationStore } from "@/stores";
import { Alert, Button, Card, icons, ScreenHeader } from "@/ui";
import { ConsultationExistsAlert } from "./ConsultationExistsAlert";
import { MedRequestExistsAlert } from "./MedRequestExistsAlert";
import { PurposeOptions } from "./PurposeOptions";
import { PurposeOptionsSkeleton } from "./PurposeOptionsSkeleton";

export const ConsultationPurpose = () => {
  const navigate = useNavigate();
  const navigateModal = useNavigateModal();

  const purpose = useConsultationStore((state) => state.purpose);
  const setPurpose = useConsultationStore((state) => state.setPurpose);

  const [showMedRequestExistsAlert, setShowMedRequestExistsAlert] =
    useState(false);
  const [showConsultationExistsAlert, setShowConsultationExistsAlert] =
    useState(false);

  const {
    data: hasUpcomingConsultations,
    isLoading: isLoadingHasUpcomingConsultations,
  } = useQuery({
    ...getUpcomingConsultationsQuery(),
    select: (data) => !!data.length,
  });
  const { data: patient, isLoading: isPatientLoading } =
    useQuery(getPatientQuery());
  const {
    data: currentMedicationRequest,
    isLoading: isLoadingCurrentMedicationRequest,
  } = useQuery(getCurrentMedicationRequestQuery());

  const isLoading =
    isLoadingHasUpcomingConsultations ||
    isPatientLoading ||
    isLoadingCurrentMedicationRequest;

  const hasUpcomingThirdInjection = Boolean(
    patient?.thirdInjectionDate &&
      isAfter(new Date(patient.thirdInjectionDate), NOW),
  );

  const isRefillDisabled =
    isLoading || Boolean(currentMedicationRequest) || hasUpcomingThirdInjection;

  useEffect(() => {
    setPurpose(
      isRefillDisabled
        ? CONSULTATION_PURPOSE.CONSULTATION
        : CONSULTATION_PURPOSE.REFILL,
    );
  }, [isRefillDisabled, setPurpose]);

  useEffect(() => {
    if (!isLoading) {
      if (currentMedicationRequest?.dateOfRequest) {
        setShowMedRequestExistsAlert(true);
      } else if (hasUpcomingConsultations) {
        setShowConsultationExistsAlert(true);
      }
    }
  }, [
    isLoading,
    currentMedicationRequest,
    hasUpcomingConsultations,
    navigateModal,
  ]);

  return (
    <main className="flex w-full flex-col gap-6 px-4 pt-4 md:px-8 md:pt-12 lg:px-16">
      <MedRequestExistsAlert show={showMedRequestExistsAlert} />
      <ConsultationExistsAlert show={showConsultationExistsAlert} />
      <ScreenHeader
        variant="secondary"
        title={t`What's the purpose of your consultation?`}
        description={
          <p className="mt-4 text-base font-medium leading-5 md:mt-0">
            {t`Please choose the option that best matches your reason for reaching out today.`}
            <br />
            {t`Understanding your needs helps us provide the best support possible.`}
          </p>
        }
        paths={[
          {
            href: ROUTES.CONSULTATIONS.OVERVIEW,
            icon: icons.Calendar,
            label: t`Consultations`,
          },
          {
            href: ROUTES.CONSULTATIONS.PURPOSE,
            icon: icons.HandShake,
            label: t`Consultation Purpose`,
          },
        ]}
      />

      <form
        className="flex flex-col gap-4 pb-8"
        onSubmit={(e) => {
          e.preventDefault();
          if (
            hasUpcomingConsultations &&
            purpose === CONSULTATION_PURPOSE.CONSULTATION
          ) {
            navigateModal(MODAL_ROUTES.CONSULTATIONS.ALREADY_SCHEDULED);
          } else {
            navigate(ROUTES.CONSULTATIONS.FORM);
          }
        }}
      >
        <Card
          title={t`Select the option that best applies to you`}
          colorVariant={VARIANT.TERTIARY}
        >
          {isLoading ? (
            <PurposeOptionsSkeleton />
          ) : (
            <PurposeOptions isRefillDisabled={isRefillDisabled} />
          )}
        </Card>

        {purpose === CONSULTATION_PURPOSE.REFILL && (
          <Alert
            content={t`You will be able to review your medication request by visiting the “Your Meds” section`}
          />
        )}

        <div className="flex flex-col-reverse items-stretch justify-end gap-4 md:flex-row md:items-center">
          <Button
            size="lg"
            onClick={() => navigate(ROUTES.CONSULTATIONS.OVERVIEW)}
            variant="secondary"
          >
            {t`Cancel`}
          </Button>
          <Button size="lg" type="submit" disabled={isLoading || !purpose}>
            {t`Next`}
          </Button>
        </div>
      </form>
    </main>
  );
};

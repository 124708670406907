import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenAlert, MedicationRequestFilter } from "@/components";
import { ROUTES } from "@/router";
import { VARIANT } from "@/shared.constants";
import { illustratedIcons } from "@/ui";
import { DisqualifiedActionButtons } from "../DisqualifiedActionButtons";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";

interface DeliveryProps {
  onGoBack: () => void;
  onDisqualified: () => void;
}

export const Delivery = ({ onGoBack, onDisqualified }: DeliveryProps) => {
  const [showDisqualifiedScreen, setShowDisqualifiedScreen] = useState(false);
  const navigate = useNavigate();

  return (
    <OnboardingLayout>
      <div className="flex flex-col gap-6">
        <OnboardingHeader title={`Begin filling out your request form`} />
        <MedicationRequestFilter
          onBack={onGoBack}
          onNext={(isDisqualified) =>
            isDisqualified
              ? setShowDisqualifiedScreen(true)
              : navigate(ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT)
          }
        />
      </div>

      {showDisqualifiedScreen && (
        <FullScreenAlert
          customIcon={<illustratedIcons.Schedule variant={VARIANT.SECONDARY} />}
          title={t`Prior Consultation with Provider Required`}
          description={t`Due to your current medical situation, at this time we cannot make a refill request, you must see a provider first.`}
          customAction={
            <DisqualifiedActionButtons
              onGoBack={() => setShowDisqualifiedScreen(false)}
              onContinue={onDisqualified}
            />
          }
        />
      )}
    </OnboardingLayout>
  );
};

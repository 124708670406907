import { t } from "ttag";

export const SUBSCRIPTION_PRICE = 99;
export const SUBSCRIPTION_CANCELLATION_EMAIL = "cancellation@joinfridays.com";
export const SUPPORT_EMAIL = "support@joinfridays.com";
export const DEFAULT_DEBOUNCE_TIME = 300;

export const DIRECTION = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left",
} as const;

export const VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  QUATERNARY: "quaternary",
  QUINARY: "quinary",
} as const;

export const SIZE = {
  X_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  X_LARGE: "xl",
} as const;

export const ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
} as const;

export const ALERT_TYPE = {
  INFO: "info",
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
} as const;

export const PERIOD = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
} as const;

export const GENDER_OPTIONS = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
] as const;

export const LANGUAGE_OPTIONS = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
] as const;

export const FIELD_TYPE = {
  CHECKBOX: "checkbox",
  DROPDOWN: "dropdown",
  HORIZONTAL_RADIO: "horizontal_radio",
  RADIO: "radio",
  READ_ONLY: "read_only",
  READ_ONLY_LINK: "read_only_link",
  TEXT: "text",
  TEXTAREA: "textarea",
  FILE: "document_upload",
} as const;

export const FILTER_TYPE = {
  IS_LESS_THAN: "is_less_than",
  IS_LEES_THAN_OR_EQUAL_TO: "is_less_than_or_equal_to",
  IS_GREATER_THAN: "is_greater_than",
  IS_GREATER_THAN_OR_EQUAL_TO: "is_greater_than_or_equal_to",
  IS_EQUAL_TO: "is_equal_to",
  IS_NOT_EQUAL_TO: "is_not_equal_to",
  INCLUDES: "includes",
  CONTAINS: "contains",
} as const;

export const TIMEZONE_OPTIONS = [
  { value: "America/New_York", label: "Eastern Time" },
  { value: "America/Chicago", label: "Central Time" },
  { value: "America/Denver", label: "Mountain Time" },
  { value: "America/Los_Angeles", label: "Pacific Time" },
  { value: "America/Phoenix", label: "Arizona Time" },
  { value: "America/Anchorage", label: "Alaska Time" },
  { value: "Pacific/Honolulu", label: "Hawaii Time" },
] as const;

export const NOW = new Date();

export const PAYMENT_STATUS = {
  FAILED: "failed",
  SUCCESS: "completed",
  PENDING: "processing",
} as const;

export const FORM_STEPS = {
  SYMPTOMS: "symptoms",
  INJECTIONS: "injections",
  FOLLOW_UP: "followUp",
  REFILL: "refill",
} as const;

export const MEDICATION_REQUEST_STATUS = {
  PAID: "paid",
  PENDING: "pending",
  SHIPPED: "shipped",
  ON_ITS_WAY: "on its way",
  CANCELLED: "cancelled",
  DENIED: "denied",
  REFUND_REQUESTED: "refund request",
  PROCESS_COMPLETE: "process complete",
  BRANDED_MEDICATION: "no more data",
} as const;

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  CANCELLED: "cancelled",
  PAYMENT_FAILED: "payment_failed",
  PAID_IN_HEALTHIE: "paid_in_healthie",
} as const;

export const CONSULTATION_PURPOSE = {
  REFILL: "refill",
  CONSULTATION: "consultation",
} as const;

// The BE is supposedly receiving up to 5 MB
// but it's not working properly, so we're going to reduce it to 2 MB.
export const MAX_UPLOAD_SIZE = 1024 * 1024 * 2; // 2MB
export const MAX_UPLOAD_SIZE_PLACEHOLDER = t`Maximum size: 2MB`;
export const HEALTHIE_FILE_STALE_TIME = 9000;

export const IMAGE_FILE_TYPES = {
  PNG: "image/png" as Blob["type"],
  JPEG: "image/jpeg" as Blob["type"],
  PDF: "application/pdf" as Blob["type"],
} as const;

export const ACCEPT_IMAGE_FILE = {
  [IMAGE_FILE_TYPES.PNG]: [],
  [IMAGE_FILE_TYPES.JPEG]: [],
  [IMAGE_FILE_TYPES.PDF]: [],
};

export const ACCEPTED_IMAGE_TYPES: Blob["type"][] =
  Object.keys(ACCEPT_IMAGE_FILE);

export const COACHING_SESSION_TYPE = {
  NUTRITIONAL: "nutritional",
  MINDSET: "mindset",
  MOVEMENT: "movement",
  SPANISH: "spanish",
} as const;

export const PROVIDER_FOLDER = {
  ...COACHING_SESSION_TYPE,
  FRIDAYS_RESOURCES: "fridays-resources",
} as const;

export const READ_ONLY_DOCUMENT_FOLDER = {
  ...PROVIDER_FOLDER,
  DOCTOR_NOTES: "doctor-notes",
} as const;

export const DOCUMENT_FOLDER = {
  LABS: "labs",
  PRESCRIPTIONS: "prescriptions",
  OTHER: "other",
} as const;

export const DISCOUNT_DURATION = {
  ONCE: "once",
  REPEATING: "repeating",
  FOREVER: "forever",
} as const;

export const DISCOUNT_TYPE = {
  PERCENTAGE: "percentage",
  FIXED_AMOUNT: "fixed_amount",
} as const;

export const ATTACHMENT_TYPES = {
  IMAGE: "image",
  FILE: "application",
  PLACEHOLDER: "placeholder",
} as const;

import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import {
  getCurrentMedicationRequestQuery,
  getUpcomingConsultationsQuery,
} from "@/api";
import {
  EXTERNAL_LINK,
  MODAL_ROUTES,
  ROUTES,
  useNavigateModal,
} from "@/router";
import {
  Button,
  CardSkeleton,
  DeliveryIcon,
  IconWrapper,
  PillsIcon,
} from "@/ui";
import { formatBackendDate, tw } from "@/utils";
import { RequestCard } from ".";
import { MedicationStatusChip } from "../MedicationStatusChip";
import { MEDICATION_REQUEST_DESCRIPTION } from "./constants";

interface MedicationRequestCardProps {
  className?: string;
}

export const MedicationRequestCard = ({
  className,
}: MedicationRequestCardProps) => {
  const navigateModal = useNavigateModal();
  const navigate = useNavigate();

  const { data: medicationRequest, isLoading: isLoadingMedicationRequest } =
    useQuery(getCurrentMedicationRequestQuery());

  const {
    data: hasUpcomingConsultations,
    isLoading: isLoadingHasUpcomingConsultations,
  } = useQuery({
    ...getUpcomingConsultationsQuery(),
    select: (data) => !!data.length,
  });

  const handleNewMedicationRequest = () => {
    if (hasUpcomingConsultations) {
      navigateModal(MODAL_ROUTES.CONSULTATIONS.ALREADY_SCHEDULED);
    } else {
      navigate(ROUTES.CONSULTATIONS.PURPOSE);
    }
  };

  const hasDrugInfo = Boolean(
    medicationRequest?.drugName ?? medicationRequest?.dose,
  );

  if (isLoadingMedicationRequest) {
    return <CardSkeleton className="bg-salmon-01 px-2" />;
  }

  return (
    <RequestCard.Root
      className={tw("md:pt-5", !medicationRequest && "pt-4 md:pt-4", className)}
    >
      <RequestCard.Title>
        {medicationRequest
          ? t`Request made on ${formatBackendDate(medicationRequest?.dateOfRequest)}`
          : t`Do you need medication?`}
      </RequestCard.Title>

      {hasDrugInfo && (
        <RequestCard.Details>
          <div className="flex items-center gap-2">
            <RequestCard.Label>{t`Drug name:`}</RequestCard.Label>
            <RequestCard.Description className="text-sm">
              {medicationRequest?.drugName ?? "-"}
            </RequestCard.Description>
          </div>

          <div className="mx-1 my-1 hidden border-x border-salmon-07 md:block" />

          <div className="flex items-center gap-2">
            <RequestCard.Label>{t`Dose:`}</RequestCard.Label>
            <RequestCard.Description className="text-sm">
              {medicationRequest?.dose ?? "-"}
            </RequestCard.Description>
          </div>
        </RequestCard.Details>
      )}

      <RequestCard.Description>
        {medicationRequest
          ? MEDICATION_REQUEST_DESCRIPTION[medicationRequest.status]
          : t`You don't have an open request`}
      </RequestCard.Description>

      <RequestCard.Footer
        className={tw(
          !medicationRequest &&
            "flex-col gap-2 pt-2 md:flex-row-reverse md:justify-end md:gap-4",
        )}
      >
        {medicationRequest?.trackingNumber && (
          <RequestCard.Link
            href={`${EXTERNAL_LINK.TRACKING}${medicationRequest.trackingNumber}`}
          >
            <IconWrapper size="sm">
              <DeliveryIcon />
            </IconWrapper>
            {t`Tracking #${medicationRequest.trackingNumber}`}
          </RequestCard.Link>
        )}

        {!medicationRequest && (
          <Button
            disabled={isLoadingHasUpcomingConsultations}
            onClick={handleNewMedicationRequest}
            size="lg"
            className="w-full md:w-fit"
          >
            <IconWrapper size="sm">
              <PillsIcon />
            </IconWrapper>
            {t`New medication request`}
          </Button>
        )}
      </RequestCard.Footer>

      {medicationRequest && (
        <MedicationStatusChip
          status={medicationRequest.status}
          className="absolute -right-1 -top-1.5"
        />
      )}
    </RequestCard.Root>
  );
};

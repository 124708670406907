import { t } from "ttag";

import { illustratedIcons, Modal } from "@/ui";

export const PricingInfoModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={t`Pricing info`}
      icon={<illustratedIcons.LightBulb />}
    >
      <div className="flex flex-col gap-4 text-base font-medium md:text-lg">
        <div className="flex flex-col">
          <p className="text-salmon-08">{t`Total monthly`}</p>
          <p className="font-serif text-salmon-08">
            <span className="text-5xl leading-snug text-salmon-10">$99</span>
            <span className="text-5xl">/</span>
            <span className="text-3xl">{t`month`}</span>
          </p>
        </div>
        <div className="flex flex-col gap-1 font-medium text-brown-09">
          <p className="font-bold">{t`Medication`}</p>
          <div className="grid grid-cols-3 gap-2 rounded-t-lg bg-salmon-08 p-2 text-base text-salmon-01">
            <div className="col-start-2">{t`Medication Cost`}</div>
            <div className="flex flex-col">
              <p>{t`Total Monthly Cost`}</p>
              <p className="text-xs text-salmon-03">{t`(Including $99/mo Membership)`}</p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 bg-salmon-03 p-2">
            <div className="col-span-3 font-bold">{t`Semaglutide`}</div>
            <div>{t`0.25mg - 2.5mg`}</div>
            <div>{t`$129/mo`}</div>
            <div>{t`$228/mo`}</div>
          </div>
          <div className="grid grid-cols-3 gap-2 bg-salmon-03 p-2">
            <div className="col-span-3 font-bold">{t`Tirzepatide`}</div>
            <div>{t`2.5mg - 5mg`}</div>
            <div>{t`$179/mo`}</div>
            <div>{t`$278/mo`}</div>
            <div>{t`7.5mg - 10mg`}</div>
            <div>{t`$289/mo`}</div>
            <div>{t`$388/mo`}</div>
            <div>{t`12.5mg - 15mg`}</div>
            <div>{t`$389/mo`}</div>
            <div>{t`$488/mo`}</div>
          </div>
          <div className="grid grid-cols-3 gap-2 rounded-b-lg bg-nature-03 p-2">
            <div className="font-bold">{t`Labs *`}</div>
            <div>{t`$33`}</div>
          </div>
          <p className="text-base text-brown-08">{t`*can provide own labs within 12 months (CMP, TSH, A1C, Lipid Panel)`}</p>
        </div>
        <div className="text-center text-base font-medium text-brown-09">
          <p className="text-lg font-bold">{t`Insurance coverage`}</p>
          <p>{t`Based on your health status and plan, some insurances may cover all, or part of the cost of medications like Ozempic, Wegovy, Mounjaro, and Zepbound.`}</p>
          <p>{t`Our insurance concierge team is very experienced at navigating the prior authorization as well as the appeal process for GLP-1 medications.`}</p>
          <p>{t`We work with you and your insurance to give you the best chance at gaining insurance coverage based on your health and the requirements of your insurance formulary.`}</p>
        </div>
      </div>
    </Modal>
  );
};

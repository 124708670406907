import { t } from "ttag";

import { VARIANT } from "@/shared.constants";
import { Button, icons, IconWrapper } from "@/ui";
import { tw } from "@/utils";
import type { ActionProps } from "../schedule.types";
import { useScheduleContext } from "../useSchedule";

export const ConfirmSection = ({ onConfirm, onGoBack }: ActionProps) => {
  const { canUseCalendar, selectedAppointment } = useScheduleContext();

  return (
    <div className="flex w-full flex-col-reverse items-center gap-2.5 md:flex-row md:justify-between">
      {Boolean(onGoBack) && (
        <Button
          onClick={onGoBack}
          variant={VARIANT.SECONDARY}
          size="lg"
          className="w-full md:w-fit"
        >
          <IconWrapper size="sm" className="hidden md:block">
            <icons.ChevronLeft />
          </IconWrapper>
          {t`Back`}
        </Button>
      )}

      <Button
        disabled={!canUseCalendar || !selectedAppointment}
        onClick={() => selectedAppointment && onConfirm(selectedAppointment)}
        size="lg"
        className={tw("w-full md:w-fit", !onGoBack && "md:w-full")}
      >
        {t`Confirm Consultation`}
        <IconWrapper
          size="sm"
          className={tw("hidden md:block", !onGoBack && "md:hidden")}
        >
          <icons.ChevronRight />
        </IconWrapper>
      </Button>
    </div>
  );
};

import { t } from "ttag";

import { PAYMENT_STATUS, SIZE, VARIANT } from "@/shared.constants";
import type { Payment } from "@/shared.types";
import {
  Button,
  icons,
  IconWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui";
import { formatBackendDate, tw } from "@/utils";

interface PaymentHistoryTableProps {
  payments?: Payment[];
}

export const PaymentHistoryTable = ({ payments }: PaymentHistoryTableProps) => {
  return (
    <Table className="hidden w-full md:table">
      <TableHeader>
        <TableRow>
          <TableHead>{t`Date`}</TableHead>
          <TableHead>{t`Detail`}</TableHead>
          <TableHead>{t`Amount`}</TableHead>
          <TableHead>{t`Payment method`}</TableHead>
          <TableHead>{t`Status`}</TableHead>
          <TableHead>{t`Invoice`}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {payments?.map((payment) => (
          <TableRow key={`payment-${payment.id}`}>
            <TableCell>{formatBackendDate(payment.date)}</TableCell>
            <TableCell>{payment.detail}</TableCell>
            <TableCell>${payment.amount}</TableCell>
            {payment.lastFourDigits ? (
              <TableCell>**** {payment.lastFourDigits}</TableCell>
            ) : (
              <TableCell>{t`Payment method failed`}</TableCell>
            )}
            <TableCell
              className={tw(
                "flex items-center gap-1 font-bold capitalize",
                payment.status === PAYMENT_STATUS.SUCCESS && "text-green-07",
                payment.status === PAYMENT_STATUS.PENDING && "text-yellow-07",
                payment.status === PAYMENT_STATUS.FAILED && "text-red-07",
              )}
            >
              {payment.status === PAYMENT_STATUS.FAILED && (
                <IconWrapper className="fill-red-07">
                  <icons.ExclamationCircle />
                </IconWrapper>
              )}
              {payment.status}
            </TableCell>

            <TableCell>
              {payment.hostedInvoiceUrl && (
                <Button
                  variant={VARIANT.SECONDARY}
                  onClick={() => window.open(payment.hostedInvoiceUrl)}
                >
                  <IconWrapper size={SIZE.SMALL} className="stroke-nature-08">
                    <icons.Download />
                  </IconWrapper>
                  {t`Download invoice`}
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

import { Controller, useWatch } from "react-hook-form";
import type {
  Control,
  ControllerRenderProps,
  FieldError,
  FieldValues,
} from "react-hook-form";

import {
  ACCEPT_IMAGE_FILE,
  FIELD_TYPE,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
  ORIENTATION,
  SIZE,
} from "@/shared.constants";
import type { Field, FieldType } from "@/shared.types";
import {
  CheckboxGroup,
  Dropzone,
  HookedSelect,
  icons,
  IconWrapper,
  Input,
  Label,
  RadioGroup,
  TextArea,
} from "@/ui";
import { evaluateFilterCondition } from "@/utils";

interface FormField {
  formField: Field;
  control: Control<FieldValues>;
}

interface RenderFieldProps {
  field: ControllerRenderProps<FieldValues, string>;
  error?: FieldError;
}

const READ_ONLY_TYPES = [
  FIELD_TYPE.READ_ONLY,
  FIELD_TYPE.READ_ONLY_LINK,
] as const;
type ReadOnlyType = (typeof READ_ONLY_TYPES)[number];
const isReadOnlyType = (x: FieldType): x is ReadOnlyType => {
  return READ_ONLY_TYPES.includes(x as ReadOnlyType);
};

const HIDDEN_FIELD_LABELS = ["async_visit", "sync_visit"] as const;
type HiddenFieldLabel = (typeof HIDDEN_FIELD_LABELS)[number];
const isHiddenField = (x: string): x is HiddenFieldLabel => {
  return HIDDEN_FIELD_LABELS.includes(x as HiddenFieldLabel);
};

export const FormField = ({ formField, control }: FormField) => {
  const fieldCondition = formField.customModuleCondition;
  const fieldConditionName = fieldCondition?.conditionalCustomModuleId ?? "";
  const conditionFieldValue = useWatch({
    control,
    defaultValue: "",
    name: fieldConditionName,
  }) as string;

  const showConditionedField =
    !!fieldCondition &&
    evaluateFilterCondition(
      fieldCondition.filterType,
      conditionFieldValue,
      fieldCondition.valueToFilter,
    );

  const showField =
    !fieldCondition || (!!fieldCondition && showConditionedField);

  if (!showField) {
    return null;
  }

  const options = formField.options.map((option) => ({
    value: option,
    label: option,
  }));

  const label = formField.label
    ? `${formField.label}${formField.required ? "*" : ""}`
    : "";

  const renderField = ({ field, error }: RenderFieldProps) => {
    switch (formField.type) {
      case FIELD_TYPE.FILE:
        return (
          <Dropzone
            {...field}
            onDrop={(acceptedFiles) => field.onChange(acceptedFiles[0])}
            error={error?.message}
            accept={ACCEPT_IMAGE_FILE}
            placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
          />
        );

      case FIELD_TYPE.HORIZONTAL_RADIO:
        return (
          <RadioGroup
            label={label}
            id={formField.id}
            orientation={ORIENTATION.HORIZONTAL}
            options={options}
            error={error?.message}
            {...field}
          />
        );

      case FIELD_TYPE.RADIO:
        return (
          <RadioGroup
            label={label}
            id={formField.id}
            options={options}
            error={error?.message}
            {...field}
          />
        );

      case FIELD_TYPE.CHECKBOX:
        return (
          <CheckboxGroup
            label={label}
            id={formField.id}
            options={options}
            error={error?.message}
            {...field}
          />
        );

      case FIELD_TYPE.DROPDOWN:
        return (
          <HookedSelect
            label={label}
            control={control}
            name={formField.id}
            id={formField.id}
            options={options}
            error={error?.message}
          />
        );

      case FIELD_TYPE.TEXT:
        return (
          <Input
            label={label}
            id={formField.id}
            error={error?.message}
            {...field}
          />
        );

      case FIELD_TYPE.TEXTAREA:
        return (
          <TextArea
            label={label}
            id={formField.id}
            error={error?.message}
            {...field}
          />
        );

      default:
        return <></>;
    }
  };

  const renderReadOnlyField = (fieldType: ReadOnlyType) => {
    switch (fieldType) {
      case FIELD_TYPE.READ_ONLY:
        return (
          <div className="flex gap-2 p-0.5">
            <IconWrapper size={SIZE.SMALL} className="mt-0.5">
              <icons.InformationCircle />
            </IconWrapper>

            <div className="text-nature-10">
              <Label htmlFor={formField.id} className="font-bold">
                {formField.label}
              </Label>
              <div id={formField.id}>
                {formField.options.map((text) => text)}
              </div>
            </div>
          </div>
        );

      case FIELD_TYPE.READ_ONLY_LINK:
        // TODO: Add field when added in the BE
        return <></>;

      default:
        return <></>;
    }
  };

  if (isReadOnlyType(formField.type) && isHiddenField(formField.label)) {
    return null;
  }

  return (
    <fieldset className="flex flex-col gap-3.5 rounded-2xl bg-brown-02 p-3.5 text-salmon-10">
      {isReadOnlyType(formField.type) ? (
        renderReadOnlyField(formField.type)
      ) : (
        <Controller
          defaultValue={formField.type === FIELD_TYPE.FILE ? undefined : ""}
          name={formField.id}
          control={control}
          rules={{ required: formField.required && "This field is required" }}
          render={({ field, fieldState: { error } }) =>
            renderField({ field, error })
          }
        />
      )}
    </fieldset>
  );
};

import { useQuery } from "@tanstack/react-query";
import { isAfter } from "date-fns";
import { useNavigate } from "react-router";
import { t } from "ttag";

import { getPatientQuery } from "@/api";
import { CallToAction, MedicationRequestCard } from "@/components";
import { ROUTES } from "@/router";
import {
  Alert,
  CardPrimitive as Card,
  CardSkeleton,
  icons,
  IconWrapper,
  PillIcon,
  ScreenHeader,
} from "@/ui";
import { formatBackendDateToVerbose } from "@/utils";
import { PastMedicationRequests } from "./PastMedicationRequests";

export const MyMeds = () => {
  const navigate = useNavigate();
  const { data: patient, isLoading: isLoadingPatient } =
    useQuery(getPatientQuery());

  const hasUpcomingThirdInjection =
    patient?.thirdInjectionDate &&
    isAfter(new Date(patient.thirdInjectionDate), new Date());

  return (
    <div className="flex grow flex-col gap-7 overflow-auto px-4 pb-10 pt-4 md:px-8 md:pt-12 lg:px-16">
      <ScreenHeader
        title={t`My Meds`}
        paths={[
          {
            href: ROUTES.MY_MEDS,
            icon: icons.User,
            label: t`My Meds`,
          },
        ]}
      />

      {hasUpcomingThirdInjection && (
        <Alert
          content={t`You will be able to order medication after your third injection date
      on ${formatBackendDateToVerbose(patient.thirdInjectionDate)}`}
        />
      )}

      {!hasUpcomingThirdInjection && (
        <Card.Root>
          <Card.Header>
            <Card.Title>
              <IconWrapper size="sm">
                <PillIcon />
              </IconWrapper>
              {t`Current medication requests`}
            </Card.Title>
            <Card.Description>{t`You wont be able to request medication until your current request has been delivered.`}</Card.Description>
          </Card.Header>

          <Card.Content>
            {isLoadingPatient ? (
              <CardSkeleton className="bg-salmon-01 px-2" />
            ) : (
              <MedicationRequestCard className="border-transparent" />
            )}
          </Card.Content>
        </Card.Root>
      )}

      <PastMedicationRequests />

      <CallToAction
        title={t`Having any trouble with your medication delivery?`}
        description={t`Get in touch with our support team!`}
        buttonText={t`Get in touch`}
        onClick={() => navigate(ROUTES.MESSAGES)}
      />
    </div>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { t } from "ttag";

import { editWeightEntry } from "@/api";
import { ROUTES } from "@/router";
import { weightEntrySchema } from "@/shared.schemas";
import type { ModalProps, WeightEntry } from "@/shared.types";
import {
  Button,
  Dialog,
  errorToast,
  GraphIconMono,
  Input,
  OverlayLoader,
  ScrollArea,
  TextArea,
  useToastStore,
} from "@/ui";
import { formatBackendDate, handleAxiosFieldErrors } from "@/utils";
import type { LocationState } from "./progress-tracker.types";

export const EditWeightEntry = ({ show, onClose }: ModalProps) => {
  const { pushToast } = useToastStore();

  const { entryId } = useParams();
  const { weightEntry } = useLocation().state as LocationState;

  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    setError,
    getValues,
  } = useForm<WeightEntry>({
    resolver: zodResolver(weightEntrySchema),
    defaultValues: weightEntry,
  });

  const queryClient = useQueryClient();
  const { mutate: editWeightEntryMutation, isPending } = useMutation({
    mutationFn: editWeightEntry.mutation,
    onSuccess: () => {
      onClose();
      editWeightEntry.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Weight entry successfully updated!`,
      });
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  if (entryId !== weightEntry?.id) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content className="w-modal gap-6">
        <Dialog.CloseButton />

        <ScrollArea className="h-[calc(100dvh-2rem)] px-8 md:h-fit">
          <div className="flex size-full flex-col gap-6">
            <Dialog.Header className="pt-8 md:pt-10">
              <Dialog.Icon>
                <GraphIconMono />
              </Dialog.Icon>
              <Dialog.Title>{t`Edit entry`}</Dialog.Title>

              <VisuallyHidden asChild>
                <Dialog.Description>
                  {t`This modal allows you to update an existing weight entry. Modify the weight value, date, or note as needed.`}
                </Dialog.Description>
              </VisuallyHidden>
            </Dialog.Header>

            <form
              onSubmit={handleSubmit((values) =>
                editWeightEntryMutation(values),
              )}
              id="edit-entry-form"
              className="grow"
            >
              <Input
                disabled
                readOnly
                label={t`Date *`}
                id="date"
                value={formatBackendDate(getValues("date"))}
                compact={false}
              />
              <Input
                label={t`Weight (lbs) *`}
                placeholder={t`Your weight`}
                id="weight"
                type="number"
                {...register("weight", { setValueAs: Number })}
                error={errors.weight?.message}
                compact={false}
              />
              <TextArea
                label={t`Note (optional)`}
                placeholder={t`Optional note`}
                id="note"
                {...register("note")}
                error={errors.note?.message}
                compact={false}
              />
            </form>

            <Dialog.Footer className="pb-8">
              <Button
                onClick={onClose}
                variant="secondary"
                size="lg"
              >{t`Cancel`}</Button>
              <Button
                disabled={!isDirty || isPending}
                type="submit"
                form="edit-entry-form"
                size="lg"
              >{t`Save changes`}</Button>
            </Dialog.Footer>
          </div>
        </ScrollArea>

        {isPending && <OverlayLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};

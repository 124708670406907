import { t } from "ttag";

import { ROUTES } from "@/router";
import { SUBSCRIPTION_STATUS } from "@/shared.constants";
import type { Route, SubscriptionStatus } from "@/shared.types";
import { getEnumValues } from "@/utils";

interface NavigationItem {
  path: Route;
  label: string;
  subscriptionStatus: SubscriptionStatus[];
  needsIntakeForm?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
}

export const NAVIGATION: NavigationItem[] = [
  {
    path: ROUTES.HOME,
    label: t`Home`,
    subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
  },
  {
    path: ROUTES.MY_MEDS,
    label: t`My Meds`,
    subscriptionStatus: [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
    ],
    needsIntakeForm: true,
  },
  {
    path: ROUTES.CONSULTATIONS.OVERVIEW,
    label: t`Consultations`,
    subscriptionStatus: [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
    ],
    needsIntakeForm: true,
  },
  {
    path: ROUTES.DOCUMENT_CENTER.OVERVIEW,
    label: t`Document center`,
    subscriptionStatus: [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
    ],
    needsIntakeForm: true,
  },
  {
    path: ROUTES.BILLING,
    label: t`Billing`,
    subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
  },
  {
    path: ROUTES.MESSAGES,
    label: t`Messages`,
    subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
  },
  {
    path: ROUTES.COACHING_HUB.OVERVIEW,
    label: t`Fridays Coaching Hub`,
    subscriptionStatus: getEnumValues(SUBSCRIPTION_STATUS),
  },
] as const;

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { t } from "ttag";

import { COACHING_SESSION_ICON, COACHING_SESSION_LABEL } from "@/components";
import { ROUTES } from "@/router";
import { PROVIDER_FOLDER } from "@/shared.constants";
import type { CoachingSessionType } from "@/shared.types";
import { Header, LightbulbMinimalisticIcon } from "@/ui";
import { isEnumValue } from "@/utils";

export const PastSessionsHeader = () => {
  const navigate = useNavigate();
  const { sessionType } = useParams<{
    sessionType: CoachingSessionType;
  }>();

  if (!(sessionType && isEnumValue(sessionType, PROVIDER_FOLDER))) {
    return <Navigate to={ROUTES.COACHING_HUB.OVERVIEW} replace />;
  }

  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.COACHING_HUB.OVERVIEW,
              icon: LightbulbMinimalisticIcon,
              label: t`Coaching Hub`,
            },
            {
              href: ROUTES.COACHING_HUB.PAST_SESSIONS,
              icon: COACHING_SESSION_ICON[sessionType],
              label: t`Past ${COACHING_SESSION_LABEL[sessionType]} sessions`,
            },
          ]}
        />

        <div className="flex gap-2">
          <Header.GoBackButton
            onClick={() => navigate(ROUTES.COACHING_HUB.OVERVIEW)}
            className="mt-3.5 md:hidden"
          />
          <Header.Title className="capitalize">{t`Past ${COACHING_SESSION_LABEL[sessionType]} sessions`}</Header.Title>
        </div>
      </Header.Content>
    </Header.Root>
  );
};

import { useLocation } from "react-router-dom";

import { ROUTES } from "@/router";
import type { Variant } from "@/shared.types";
import { StepProgressBar } from "@/ui";

const ONBOARDING_STEPS = [
  { value: ROUTES.ONBOARDING.MAIN_INFO, label: "Your main info" },
  { value: ROUTES.ONBOARDING.JOURNEY_BEGIN, label: "Choose your journey" },
  { value: ROUTES.ONBOARDING.PAYMENT_AND_ACCOUNT, label: "Payment & Account" },
] as const;

export const OnboardingProgressBar = ({ variant }: { variant?: Variant }) => {
  const { pathname: currentPath } = useLocation();
  return (
    <div className="flex w-full justify-center">
      <StepProgressBar
        steps={ONBOARDING_STEPS}
        currentStep={currentPath}
        variant={variant}
      />
    </div>
  );
};

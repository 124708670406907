import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "@/router";
import type { BirthData, ObjectValue } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { FilteringQuestions } from "../StartingPoint/FilteringQuestions";
import { StartingPointForm } from "../StartingPoint/Form";
import type { BMIData } from "../StartingPoint/startingPoint.types";
import { BirthInfo } from "./BirthInfo";
import { MainInfoDisqualifiedScreen } from "./DisqualifiedScreen";
import { MainInfoForm } from "./Form";
import type { ValidationError } from "./mainInfo.types";

const MAIN_INFO_STEP = {
  HEIGHT_AND_WEIGHT: "height-and-weight",
  BIRTH: "birth",
  INITIAL_QUESTIONS: "initial-questions",
  USER_INFO: "user-info",
} as const;

type MainInfoStep = ObjectValue<typeof MAIN_INFO_STEP>;

export const MainInfo = () => {
  const navigate = useNavigate();
  const { setUserBirthInfo, setBmi, bmi, birthInfo } = useOnboardingStore();
  const [step, setStep] = useState<MainInfoStep>(
    MAIN_INFO_STEP.HEIGHT_AND_WEIGHT,
  );
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  const setBirthData = (data: BirthData) => {
    setUserBirthInfo(data);
    setStep(MAIN_INFO_STEP.INITIAL_QUESTIONS);
  };

  const setBmiData = (data: BMIData) => {
    setBmi(data);
    setStep(MAIN_INFO_STEP.BIRTH);
  };

  return (
    <div>
      {step === MAIN_INFO_STEP.HEIGHT_AND_WEIGHT && (
        <StartingPointForm
          savedData={bmi}
          onCancel={() => navigate(ROUTES.LOGIN)}
          onSubmit={setBmiData}
        />
      )}

      {step === MAIN_INFO_STEP.BIRTH && (
        <BirthInfo
          savedData={birthInfo}
          onValidation={setValidationError}
          onGoBack={() => setStep(MAIN_INFO_STEP.HEIGHT_AND_WEIGHT)}
          onSuccess={setBirthData}
        />
      )}

      {step === MAIN_INFO_STEP.INITIAL_QUESTIONS && bmi && birthInfo && (
        <FilteringQuestions
          birthData={birthInfo}
          bmi={bmi}
          onGoBack={() => setStep(MAIN_INFO_STEP.BIRTH)}
          onSuccess={() => setStep(MAIN_INFO_STEP.USER_INFO)}
        />
      )}

      {step === MAIN_INFO_STEP.USER_INFO && (
        <MainInfoForm
          onGoBack={() => setStep(MAIN_INFO_STEP.INITIAL_QUESTIONS)}
          onValidation={setValidationError}
        />
      )}

      {validationError && (
        <MainInfoDisqualifiedScreen
          validationError={validationError}
          onGoBack={() => setValidationError(null)}
        />
      )}
    </div>
  );
};

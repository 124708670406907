import { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";

import { Label, Message } from "@/ui";
import { tw } from "@/utils";

interface CreditCardInputProps {
  id: string;

  containerClassName?: string;
  compact?: boolean;
  label?: string;
  message?: string;
  error?: string;
  onChange?: (hasCreditCard: boolean) => void;
}

export const CreditCardInput = ({
  id,
  containerClassName,
  compact,
  label,
  message,
  error,
  onChange,
}: CreditCardInputProps) => {
  const [stripeErrorMessage, setStripeErrorMessage] = useState("");
  const [iframeFocus, setIframeFocus] = useState(false);

  const handleChange = (e: StripeCardElementChangeEvent) => {
    onChange?.(!e.empty);
    setStripeErrorMessage(e.error?.message ?? "");
  };

  const errorMessage = stripeErrorMessage || error;

  return (
    <div className={tw("flex flex-col gap-1.5", containerClassName)}>
      <Label htmlFor={id}>{label}</Label>

      <CardElement
        id={id}
        options={{
          style: {
            base: {
              fontFamily: '"DM Sans", sans-serif',
              color: "#3F352C",
              "::placeholder": {
                fontWeight: "300",
                color: "#A59383",
              },
            },
          },
        }}
        className={tw(
          "w-full rounded-lg border border-brown-05 p-3 focus-within:border-brown-09",
          !!errorMessage && "border-red-07",
          iframeFocus && "border-brown-09",
        )}
        onChange={handleChange}
        onFocus={() => setIframeFocus(true)}
        onBlur={() => setIframeFocus(false)}
      />

      {(!compact || !!message || !!errorMessage) && (
        <Message message={message} error={errorMessage} />
      )}
    </div>
  );
};

interface HookedCreditCardInputProps<TFieldValues extends FieldValues>
  extends CreditCardInputProps {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
}
export const HookedCreditCardInput = <TFieldValues extends FieldValues>({
  name,
  control,
  ...props
}: HookedCreditCardInputProps<TFieldValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange } = field;
        return <CreditCardInput {...props} onChange={onChange} />;
      }}
    />
  );
};

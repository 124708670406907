import { useState } from "react";

import { JourneyBeginCover } from "./Cover";
import { Delivery } from "./Delivery";
import { JourneyBeginSchedule } from "./Schedule";

const STEP = {
  COVER: "cover",
  APPOINTMENT: "appointment",
  DELIVERY: "delivery",
  ERROR: "error",
} as const;

type Step = (typeof STEP)[keyof typeof STEP];

export const JourneyBegin = () => {
  const [step, setStep] = useState<Step>(STEP.COVER);
  return (
    <>
      {step === STEP.COVER && <JourneyBeginCover onContinue={setStep} />}

      {step === STEP.APPOINTMENT && (
        <JourneyBeginSchedule onGoBack={() => setStep(STEP.COVER)} />
      )}

      {step === STEP.DELIVERY && (
        <Delivery
          onGoBack={() => setStep(STEP.COVER)}
          onDisqualified={() => setStep(STEP.APPOINTMENT)}
        />
      )}
    </>
  );
};

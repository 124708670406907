import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import {
  getInitialQuestionQuery,
  getQuestionQuery,
  qualifiesUser,
} from "@/api";
import {
  AnswerCheckboxGroup,
  AnswerRadioGroup,
  FullScreenAlert,
  FullScreenErrorAlert,
  FullScreenLoader,
  Loader,
  SuccessFullScreenAlert,
} from "@/components";
import { FIELD_TYPE } from "@/shared.constants";
import type { Answer, BirthData, Question } from "@/shared.types";
import { useOnboardingStore } from "@/stores";
import { Button, errorToast } from "@/ui";
import { extractAnswerDetails, tw } from "@/utils";
import { DisqualifiedActionButtons } from "../DisqualifiedActionButtons";
import { OnboardingActionButtons } from "../OnboardingActionButtons";
import { OnboardingHeader } from "../OnboardingHeader";
import { OnboardingLayout } from "../OnboardingLayout";
import type { BMIData } from "./startingPoint.types";

const MIN_BMI_VALUE = 22;

interface SelectedAnswers {
  questionId: Question["id"];
  selectedAnswerIds: Answer["id"][];
}

interface FilteringQuestionsProps {
  bmi: BMIData;
  birthData: BirthData;
  onGoBack: () => void;
  onSuccess: () => void;
}

export const FilteringQuestions = ({
  bmi,
  birthData,
  onGoBack,
  onSuccess,
}: FilteringQuestionsProps) => {
  const setUser = useOnboardingStore((state) => state.setUser);
  const [isDisqualified, setIsDisqualified] = useState(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const [questionId, setQuestionId] = useState<Question["id"] | null>(null);
  const [answersHistory, setAnswersHistory] = useState<SelectedAnswers[]>([]);
  const [currentAnswerIds, setCurrentAnswerIds] = useState<Answer["id"][]>([]);

  const {
    data: initialQuestion,
    isLoading: isLoadingInitialQuestion,
    isError: isInitialQuestionError,
  } = useQuery(getInitialQuestionQuery(bmi.value));

  const {
    data: question,
    isLoading: isLoadingQuestion,
    isError: isQuestionError,
  } = useQuery(getQuestionQuery(questionId));

  const currentQuestion = question ?? initialQuestion;
  const isLoading = isLoadingInitialQuestion || isLoadingQuestion;

  const {
    mutate: qualifiesUserMutation,
    isPending: isPendingQualifiesUserMutation,
  } = useMutation({
    mutationFn: qualifiesUser.mutation,
    onSuccess: (data) => {
      setUser(data);
      setShowSuccessScreen(true);
    },
    onError: (err) => {
      errorToast(err);
      setIsDisqualified(true);
    },
  });

  const questionAnswers = currentQuestion?.onboardingAnswers ?? [];
  const answerOptions = questionAnswers.map((answer) => ({
    ...extractAnswerDetails(answer),
    value: answer.id,
    // TODO: (BE) - add unique prop to 'None of the above.' options
    unique: answer.answer.includes("None of the above."),
  }));

  const handleNextQuestion = () => {
    const selectedAnswers = questionAnswers.filter((answer) =>
      currentAnswerIds.includes(answer.id),
    );

    if (selectedAnswers.some((answer) => answer.disqualifier)) {
      setIsDisqualified(true);
      return;
    }

    const newSelectedAnswer = selectedAnswers[0] && {
      questionId: selectedAnswers[0].answerGroupId,
      selectedAnswerIds: currentAnswerIds,
    };

    const updatedAnswersHistory = newSelectedAnswer && [
      ...answersHistory,
      newSelectedAnswer,
    ];

    if (updatedAnswersHistory) {
      setAnswersHistory(updatedAnswersHistory);
    }

    const nextQuestionId = selectedAnswers.find(
      (answer) => answer.answerGroupId,
    )?.nextAnswerGroupId;

    if (nextQuestionId) {
      setQuestionId(nextQuestionId);
      setCurrentAnswerIds([]);
    } else {
      const allSelectedAnswers = updatedAnswersHistory?.flatMap(
        (selectedAnswer) => selectedAnswer.selectedAnswerIds,
      );

      allSelectedAnswers &&
        qualifiesUserMutation({
          ...bmi,
          ...birthData,
          onboardingAnswers: allSelectedAnswers,
        });
    }
  };

  const handleGoBack = () => {
    if (!answersHistory.length) {
      onGoBack();
    } else {
      const updatedAnswersHistory = answersHistory.slice(0, -1);
      const lastAnswerHistory = answersHistory.at(-1);

      setAnswersHistory(updatedAnswersHistory);

      if (lastAnswerHistory) {
        const { questionId, selectedAnswerIds } = lastAnswerHistory;
        setQuestionId(questionId);
        setCurrentAnswerIds(selectedAnswerIds);
      }
    }
  };

  const showDisqualifiedScreen =
    isDisqualified || Number(bmi.value) < MIN_BMI_VALUE;

  const isError = isInitialQuestionError || isQuestionError;

  return (
    <OnboardingLayout>
      {isLoading && <Loader />}

      {!isLoading && currentQuestion && (
        <div className="flex flex-col items-center gap-6 pb-10 text-center sm:pb-20">
          <OnboardingHeader title={currentQuestion.question} />

          <div className="flex w-full max-w-desktop flex-col gap-10 sm:gap-12 sm:px-16">
            {currentQuestion.type === FIELD_TYPE.CHECKBOX && (
              <AnswerCheckboxGroup
                id={`${currentQuestion.id}-${currentQuestion.type}`}
                options={answerOptions}
                value={currentAnswerIds}
                onChange={setCurrentAnswerIds}
                className={tw(
                  "gap-4 sm:gap-2",
                  answerOptions.length < 4 && "xl:grid-cols-3",
                )}
              />
            )}

            {currentQuestion.type === FIELD_TYPE.RADIO && (
              <AnswerRadioGroup
                id={`${currentQuestion.id}-${currentQuestion.type}`}
                options={answerOptions}
                value={currentAnswerIds[0]}
                onValueChange={(value) => setCurrentAnswerIds([value])}
                className={tw(
                  "gap-4 sm:gap-2",
                  answerOptions.length < 4 && "gap-4 sm:gap-2 xl:grid-cols-3",
                  answerOptions.length == 2 && "gap-4 sm:gap-2 xl:grid-cols-2",
                )}
              />
            )}

            <OnboardingActionButtons
              onCancel={handleGoBack}
              onContinue={handleNextQuestion}
              continueLabel={t`Next`}
            />
          </div>
        </div>
      )}

      {isPendingQualifiesUserMutation && <FullScreenLoader />}

      {showDisqualifiedScreen && (
        <FullScreenAlert
          title={t`It appears that Fridays treatments may not be the best fit for you at the moment`}
          description={t`Your journey to wellness is personal and unique. While Fridays may not align with your current needs, it's just the beginning of discovering what works best for you. Keep exploring, and you'll find the perfect fit.`}
          customAction={
            <DisqualifiedActionButtons
              onGoBack={() => {
                if (!isDisqualified) {
                  onGoBack();
                }

                setIsDisqualified(false);
              }}
            />
          }
        />
      )}
      {showSuccessScreen && (
        <SuccessFullScreenAlert
          description={t`We're excited to join you on this wellness journey. Let's make every day feel like a Friday! 🎉`}
          title={t`Congrats! It looks like you'd be a great candidate for Fridays!`}
          customAction={
            <Button
              onClick={onSuccess}
              className="w-full sm:w-fit"
            >{t`Start your journey!`}</Button>
          }
        />
      )}
      {isError && <FullScreenErrorAlert onTryAgain={onGoBack} />}
    </OnboardingLayout>
  );
};

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { EXTERNAL_LINK, ROUTES } from "@/router";
import { SIZE, VARIANT } from "@/shared.constants";
import { Button, DollarMinimalisticIcon, FadeOut, IconWrapper } from "@/ui";
import { Background } from "./Background";
import { PricingInfoModal } from "./PricingInfoModal";

export const Welcome = () => {
  const [showPricing, setShowPricing] = useState(false);
  const navigate = useNavigate();

  return (
    <FadeOut className="flex h-dvh w-screen bg-nature-10 sm:items-center sm:justify-center">
      <div className="relative flex h-full w-desktop overflow-hidden sm:h-fit">
        <div className="z-10 flex w-full shrink-0 flex-col gap-10 overflow-y-auto px-6 pb-10 pt-42 text-nature-02 sm:w-152 sm:gap-5 sm:px-14 sm:pt-64">
          <div className="flex flex-col gap-2 text-center sm:text-left">
            <h1 className="font-serif text-6xl text-nature-02">{t`Welcome`}</h1>
            <p className="font-medium">{t`Let's create your Fridays account!`}</p>
          </div>
          <div className="h-full max-h-80 sm:h-fit" />
          <div className="flex flex-col-reverse gap-4 sm:w-fit">
            <Button
              onClick={() => setShowPricing(true)}
              size={SIZE.LARGE}
              variant="secondary"
              className="mt-auto w-full border-transparent md:w-fit md:border-nature-01"
            >
              <div className="flex items-center gap-2 text-nature-01">
                <IconWrapper className="fill-nature-01" size={SIZE.SMALL}>
                  <DollarMinimalisticIcon />
                </IconWrapper>
                {t`Pricing info`}
              </div>
            </Button>
            <Button
              asChild
              variant={VARIANT.SECONDARY}
              size={SIZE.LARGE}
              className="w-full text-nature-01 underline-offset-2 hover:border-transparent hover:text-nature-03 hover:underline md:border-transparent"
            >
              <Link to={EXTERNAL_LINK.HOME}>{t`Learn more first!`}</Link>
            </Button>
            <Button
              onClick={() => navigate(ROUTES.ONBOARDING.MAIN_INFO)}
              size={SIZE.LARGE}
              className="w-full"
            >{t`Take the quiz!`}</Button>
          </div>
        </div>
        <div className="absolute left-40 flex h-full grow opacity-30 sm:static sm:opacity-100">
          <Background />
        </div>
      </div>
      <PricingInfoModal
        show={showPricing}
        onClose={() => setShowPricing(false)}
      />
    </FadeOut>
  );
};

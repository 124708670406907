import { t } from "ttag";

import { ROUTES } from "@/router";
import { SUPPORT_EMAIL } from "@/shared.constants";
import { Alert, Label } from "@/ui";
import { StepCard } from ".";

export const Step1 = () => (
  <div className="flex flex-col gap-2">
    <Label
      htmlFor="step-1-checklist"
      size="md"
    >{t`Welcome to Fridays! Here's Your Checklist to Get Started:`}</Label>

    <div id="step-1-checklist" className="flex flex-col gap-8">
      <StepCard.Root>
        <StepCard.Title>{t`🩺 To proceed, we need the following lab results:`}</StepCard.Title>

        <StepCard.Content>
          <StepCard.Item>
            <StepCard.ItemTitle>{t`Comprehensive Metabolic Panel (CMP)`}</StepCard.ItemTitle>
          </StepCard.Item>
          <StepCard.Item>
            <StepCard.ItemTitle>{t`Thyroid Stimulating Hormone (TSH)`}</StepCard.ItemTitle>
          </StepCard.Item>
          <StepCard.Item>
            <StepCard.ItemTitle>{t`Lipid Panel`}</StepCard.ItemTitle>
          </StepCard.Item>
          <StepCard.Item>
            <StepCard.ItemTitle>{t`Hemoglobin A1c (HgbA1c)`}</StepCard.ItemTitle>
          </StepCard.Item>
        </StepCard.Content>

        <Alert
          content={t`Important: Your first prescription can be filled without uploading these lab results. You are also eligible for one refill (at the same dosage) before we require your lab results to be uploaded.`}
          className="bg-salmon-02"
        />
      </StepCard.Root>

      <StepCard.Root>
        <StepCard.Title>{t`✅️ Have you uploaded your lab results yet?`}</StepCard.Title>

        <StepCard.Content>
          <StepCard.Item>
            <StepCard.ItemTitle>{t`Already have these lab results from the past 12 months?`}</StepCard.ItemTitle>

            <StepCard.ItemContent>
              {t`Please upload them directly in `}
              <StepCard.Link
                to={ROUTES.PROFILE.OVERVIEW}
              >{t`your profile`}</StepCard.Link>
              .
            </StepCard.ItemContent>
          </StepCard.Item>

          <StepCard.Item>
            <StepCard.ItemTitle>{t`Don’t have recent labs?`}</StepCard.ItemTitle>
            <StepCard.ItemContent>
              {t`Don’t worry! Send us a message at `}
              <span className="font-semibold text-nature-08 md:font-bold">
                {SUPPORT_EMAIL}
              </span>
              {t` to request a lab order.`}
            </StepCard.ItemContent>
          </StepCard.Item>

          <StepCard.Item>
            <StepCard.ItemTitle>{t`Near a Quest Diagnostics?`}</StepCard.ItemTitle>
            <StepCard.ItemContent>
              {t`We can send you a lab order for Quest, and the total cost will be $33.`}
            </StepCard.ItemContent>
          </StepCard.Item>

          <StepCard.Item>
            <StepCard.ItemTitle>{t`Not near a Quest?`}</StepCard.ItemTitle>
            <StepCard.ItemContent>
              {t`We can provide a general lab order. The cost will vary depending on your local lab and your insurance coverage.`}
            </StepCard.ItemContent>
          </StepCard.Item>
        </StepCard.Content>
      </StepCard.Root>
    </div>
  </div>
);

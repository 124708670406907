import { useMutation } from "@tanstack/react-query";
import { addDays, addYears, endOfMonth } from "date-fns";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { t } from "ttag";

import { updateThirdInjectionDate } from "@/api";
import { ROUTES } from "@/router";
import { NOW } from "@/shared.constants";
import {
  Button,
  errorToast,
  HookedDatePicker,
  icons,
  illustratedIcons,
  Modal,
} from "@/ui";

export const RefillModal = ({
  setShowConfirmation,
  show,
}: {
  setShowConfirmation: (b: boolean) => void;
  show: boolean;
}) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<{ thirdInjectionDate: string }>({
    values: { thirdInjectionDate: "" },
  });
  const navigate = useNavigate();

  const { mutate: updateThirdInjectionDateMutation } = useMutation({
    mutationFn: updateThirdInjectionDate.mutation,
    onError: (error) => {
      errorToast(error);
    },
    onSuccess: () => {
      navigate(ROUTES.HOME);
    },
  });

  const saveThirdInjectionDate = (data: { thirdInjectionDate: string }) => {
    updateThirdInjectionDateMutation({
      thirdInjectionDate: data.thirdInjectionDate,
    });
  };
  return (
    <Modal
      show={show}
      icon={<illustratedIcons.Warning />}
      title={t`Oops, not so fast!`}
      description={
        <p className="flex flex-col gap-6">
          <span>{t`Please remember, we can't process refills until you've used 3 doses. Come back after your third injection for an accurate progress assessment by your provider!`}</span>
        </p>
      }
      onClose={() => setShowConfirmation(false)}
    >
      <div className="flex flex-col gap-3 rounded-lg bg-salmon-02 p-3 text-brown-09">
        <span>{t`Let us know the date of your third injection so we can help you remember`}</span>
        <form
          onSubmit={handleSubmit(saveThirdInjectionDate)}
          className="flex flex-col gap-3"
        >
          <HookedDatePicker
            label={t`3rd injection date`}
            id="thirdInjectionDate"
            name="thirdInjectionDate"
            left={<icons.Calendar />}
            control={control}
            error={errors.thirdInjectionDate?.message}
            config={{
              years: {
                mode: "exact",
                numberOfYears: 1,
                step: 0,
              },
              dates: {
                maxDate: endOfMonth(addYears(NOW, 100)),
                minDate: addDays(NOW, 1),
              },
            }}
          />
          <Button
            className="w-fit self-center"
            type="submit"
          >{t`Submit`}</Button>
        </form>
      </div>
    </Modal>
  );
};

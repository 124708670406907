import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";
import { LightbulbFixedIconMono } from "../illustrated-icons";

const Root = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <section
      ref={ref}
      className={tw(
        "flex grow flex-col items-center justify-center gap-2 rounded-2xl border border-nature-06 p-4 md:p-6",

        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "EmptyState.Root";

const Icon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={tw("size-20", className)} {...props}>
      {children ? children : <LightbulbFixedIconMono variant="secondary" />}
    </div>
  ),
);
Icon.displayName = "EmptyState.Icon";

const Description = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={tw("text-center text-lg font-medium text-brown-05", className)}
    {...props}
  />
));
Description.displayName = "EmptyState.Description";

export { Root, Description, Icon };

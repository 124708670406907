import { RadioGroupIndicator } from "@radix-ui/react-radio-group";

import { SIZE } from "@/shared.constants";
import { tw } from "@/utils";
import type { IndicatorProps } from "./form.types";

export const RadioIndicator = ({
  className,
  size = SIZE.SMALL,
}: IndicatorProps) => {
  return (
    <div
      className={tw(
        "flex shrink-0 items-center justify-center rounded-full border border-brown-04 bg-salmon-01 hover:border-nature-09 group-focus:ring-2 group-focus:ring-nature-09",
        size === SIZE.SMALL && "h-4 w-4",
        size === SIZE.MEDIUM && "h-5 w-5",
        className,
      )}
    >
      <RadioGroupIndicator className="flex h-full w-full items-center justify-center rounded-full bg-nature-09">
        <div
          className={tw(
            "rounded-full bg-nature-04",
            size === SIZE.SMALL && "h-2 w-2",
            size === SIZE.MEDIUM && "h-2.5 w-2.5",
          )}
        />
      </RadioGroupIndicator>
    </div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { t } from "ttag";

import { getPatientQuery } from "@/api";
import { ROUTES } from "@/router";
import { CardSkeleton, icons, ScreenHeader } from "@/ui";
import { tw } from "@/utils";
import { CurrentMembershipSection } from "./CurrentMembershipSection";
import { PaymentHistory } from "./PaymentHistory";
import { PaymentMethodSection } from "./PaymentMethodSection";

export const Billing = () => {
  const {
    data: patientSubscriptionStatus,
    isLoading: isLoadingPatientSubscriptionStatus,
  } = useQuery({
    ...getPatientQuery(),
    select: (data) => data.subscriptionStatus,
  });

  return (
    <div className="flex max-h-full w-full flex-col gap-7 px-5 pt-5 md:px-8 md:pb-4 md:pt-12 lg:px-16">
      <ScreenHeader
        title={t`Billing`}
        paths={[
          {
            href: ROUTES.BILLING,
            icon: icons.Wallet,
            label: t`Billing`,
          },
        ]}
      />

      <div>
        {isLoadingPatientSubscriptionStatus && <CardSkeleton />}

        {!isLoadingPatientSubscriptionStatus && patientSubscriptionStatus && (
          <div className={tw("flex grid-cols-2 flex-col gap-3 md:grid")}>
            <CurrentMembershipSection status={patientSubscriptionStatus} />

            <PaymentMethodSection />
          </div>
        )}
      </div>

      <PaymentHistory />
    </div>
  );
};

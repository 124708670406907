import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import { DocumentsIcon, Header, IconWrapper } from "@/ui";
import { StethoscopeIcon } from "@/ui/icons/medicine/StethoscopeIcon";

export const PrescriptionsDetailsHeader = () => {
  const navigateModal = useNavigateModal();
  const navigate = useNavigate();
  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: DocumentsIcon,
              label: t`Document center`,
            },
            {
              href: ROUTES.DOCUMENT_CENTER.DOCTOR_NOTES,
              icon: StethoscopeIcon,
              label: t`Previous prescriptions`,
            },
          ]}
        />

        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            <Header.GoBackButton
              onClick={() => navigate(ROUTES.DOCUMENT_CENTER.OVERVIEW)}
              className="md:hidden"
            />
            <Header.Title className="text-2xl md:text-3xl">{t`Previous Prescriptions`}</Header.Title>
          </div>

          <Header.Actions>
            <Header.Button
              onClick={() =>
                navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.PRESCRIPTIONS)
              }
              className="hidden md:flex"
            >
              <IconWrapper size="sm">
                <StethoscopeIcon />
              </IconWrapper>
              {t`Add previous prescriptions`}
            </Header.Button>
          </Header.Actions>
        </div>

        <Header.Description>{t`Browse through your Previous Prescriptions to find specific documents. Tap on a document to view it in detail.`}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};

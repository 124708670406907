import { Link } from "react-router-dom";
import { t } from "ttag";

import { CoachingScheduleAugust24 } from "@/assets";
import {
  Badge,
  CardPrimitive as Card,
  ConfettiMinimalisticIcon,
  HeartIcon,
  IconWrapper,
  Shape10,
} from "@/ui";

export const ComingSoonCard = () => {
  return (
    <Card.Root className="grow gap-4 md:gap-3">
      <Card.Background className="bg-salmon-04">
        <Shape10 className="translate-x-11 translate-y-12 text-salmon-05 opacity-50" />
      </Card.Background>

      <Card.Header className="gap-2">
        <Card.Description className="flex items-center gap-1 font-bold">
          <IconWrapper as="span" className="size-5">
            <ConfettiMinimalisticIcon />
          </IconWrapper>
          {t`Exciting News`}
        </Card.Description>
        <Card.Title className="flex-col items-start gap-1 text-xl leading-6 md:flex-row md:gap-2">
          {t`Coaching hub`}
          <Badge variant="secondary">{t`Open while under construction`}</Badge>
        </Card.Title>
        <Card.Description className="max-w-2xl pt-2 text-xl md:pt-0">
          {t`The interactive Coaching Hub will be ready soon, in the meantime, please see the resources below!`}
        </Card.Description>
      </Card.Header>

      <Card.Content className="flex gap-2 rounded-lg bg-salmon-03 p-3">
        <IconWrapper size="sm" className="mt-0.5">
          <HeartIcon className="stroke-[3]" />
        </IconWrapper>
        <Card.Description>
          {t`Check out `}
          <Link
            target="_blank"
            to={CoachingScheduleAugust24}
            className="font-bold text-salmon-10 underline"
          >
            {t`our current coaching schedule`}
          </Link>
          {t` and join any of our ongoing sessions. We appreciate your patience
            and look forward to supporting you on your journey to better health!`}
        </Card.Description>
      </Card.Content>
    </Card.Root>
  );
};

import { useEffect, useState } from "react";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { scheduleSubscription } from "@/api";
import type { Patient } from "@/shared.types";
import {
  AlertDialog,
  Button,
  Checkbox,
  errorToast,
  illustratedIcons,
} from "@/ui";

export const SetUpPaymentMethodModal = ({
  patient,
  isLoading,
}: {
  patient?: Patient;
  isLoading: boolean;
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(patient?.subscriptionStatus === "paid_in_healthie");
  }, [patient]);

  const [hasConsent, setHasConsent] = useState<CheckedState>(false);
  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const queryClient = useQueryClient();

  const { mutate: scheduleSubscriptionMutation } = useMutation({
    mutationFn: scheduleSubscription.mutation,
    onSuccess: () => {
      scheduleSubscription.invalidates(queryClient);
      setUpdatingPaymentMethod(false);
      scheduleSubscription.invalidates(queryClient);
    },
    onError: (err) => {
      errorToast(err);
      setUpdatingPaymentMethod(false);
    },
  });

  const handleChangePaymentMethod = async () => {
    const cardElement = stripe && elements && elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    setUpdatingPaymentMethod(true);

    await stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        scheduleSubscriptionMutation({
          paymentMethodId: result.paymentMethod?.id,
        });
      })
      .catch((err) => {
        errorToast(err);
        setUpdatingPaymentMethod(false);
      });
  };
  return (
    <AlertDialog
      show={show}
      onClose={() => setShow(false)}
      icon={<illustratedIcons.Success />}
      title={t`Welcome to Fridays! Just one small thing left to do`}
      description={
        <p className="flex flex-col gap-6">
          <span className="font-normal">{t`Please enter your card details below to continue. We need this for future medications, labs or membership payments.`}</span>
          <span className="font-medium">
            {t`Your card will not be charged now, your current subscription will remain active.`}
          </span>
        </p>
      }
      renderActions={() => (
        <div className="flex w-full flex-col gap-2 md:flex-row-reverse md:justify-center">
          <Button
            onClick={handleChangePaymentMethod}
            disabled={!hasConsent || updatingPaymentMethod || isLoading}
          >{t`Save changes`}</Button>
        </div>
      )}
    >
      <div className="flex w-full flex-col gap-1">
        <label className="text-sm font-medium text-salmon-10">
          {t`Card number *`}
        </label>
        <CardElement className="w-full rounded-lg border border-brown-09 p-3" />
      </div>
      <Checkbox
        checked={hasConsent}
        onCheckedChange={setHasConsent}
        id=""
        label={
          <span className="text-sm text-brown-10">
            {t`I authorize the use of this credit card for memberships, medications and lab charges`}
          </span>
        }
      />
    </AlertDialog>
  );
};

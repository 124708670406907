import type { Tag } from "@/shared.types";

export const ConversationTag = ({
  tag,
  onClick,
}: {
  tag: Tag;
  onClick: () => void;
}) => {
  return (
    <button
      className="flex items-center gap-2 rounded-2xl border-2 border-transparent bg-salmon-01 px-4 py-3"
      onClick={onClick}
    >
      <div className="font-medium text-brown-10">{tag.name}</div>
    </button>
  );
};

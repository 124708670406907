import { Skeleton } from "@/ui";

export const TimeSlotsSkeleton = () => {
  return (
    <div className="flex min-h-44 grow flex-col">
      <div className="flex flex-wrap gap-2.5">
        {Array.from({ length: 5 }, (_, index) => `${index}`).map((i) => (
          <Skeleton
            key={`time-slot-skeleton-${i}`}
            className="h-9 w-24 rounded-l-full rounded-r-full"
          />
        ))}
      </div>
    </div>
  );
};

import { create } from "zustand";

import type { BMIData } from "@/screens/Onboarding/StartingPoint/startingPoint.types";
import type { BirthData, OnboardingUser, TimeSlot } from "@/shared.types";

export interface OnboardingStoreState {
  birthInfo: BirthData | null;
  bmi: BMIData | null;
  user: OnboardingUser | null;
  appointment: TimeSlot | null;
  healthieUserToken: string | null;
  patientId: string | null;
  setUser: (user: OnboardingUser) => void;
  setUserBirthInfo: (birthInfo: BirthData) => void;
  setBmi: (bmi: BMIData) => void;
  setAppointment: (appointment: TimeSlot) => void;
  setHealthieUserToken: (token: string) => void;
  setPatientId: (id: string) => void;
  clearAll: () => void;
}

export const useOnboardingStore = create<OnboardingStoreState>()((set) => ({
  birthInfo: null,
  user: null,
  appointment: null,
  healthieUserToken: null,
  patientId: null,
  bmi: null,
  setUser: (user: OnboardingUser) => {
    set(() => ({ user }));
  },
  setUserBirthInfo: (birthInfo: BirthData) => {
    set(() => ({ birthInfo }));
  },
  setBmi: (bmi: BMIData) => {
    set(() => ({ bmi }));
  },
  setAppointment: (appointment: TimeSlot) => {
    set(() => ({ appointment }));
  },
  setHealthieUserToken: (healthieUserToken: string) => {
    set(() => ({ healthieUserToken }));
  },
  setPatientId: (patientId: string) => {
    set(() => ({ patientId }));
  },
  clearAll: () => {
    set({
      user: null,
      appointment: null,
      healthieUserToken: null,
      patientId: null,
    });
  },
}));

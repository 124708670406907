import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import {
  IMAGE_FILE_TYPES,
  MAX_UPLOAD_SIZE_PLACEHOLDER,
} from "@/shared.constants";
import type { OnboardingImageUploadsValues } from "@/shared.types";
import { Card, ControlledDropzone } from "@/ui";
import { SectionHeader } from "./SectionHeader";

export const ACCEPT_IMAGE_FILE = {
  [IMAGE_FILE_TYPES.PNG]: [],
  [IMAGE_FILE_TYPES.JPEG]: [],
};

export const InsuranceCard = () => {
  const { control } = useFormContext<OnboardingImageUploadsValues>();

  return (
    <section className="flex flex-col gap-6">
      <SectionHeader
        title={t`Would you like to upload your insurance information?`}
        description={t`At your initial consultation, your provider may need details about your insurance coverage. Providing this information can help streamline your appointments and ensure smooth billing processes.`}
      />

      <Card title={t`Insurance card`}>
        <div className="grid sm:grid-cols-2 sm:gap-4">
          <ControlledDropzone
            accept={ACCEPT_IMAGE_FILE}
            control={control}
            name="insuranceCardFront"
            label={t`Drop your front card photo here`}
            placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
          />
          <ControlledDropzone
            accept={ACCEPT_IMAGE_FILE}
            control={control}
            name="insuranceCardBack"
            label={t`Drop your back card photo here`}
            placeholder={MAX_UPLOAD_SIZE_PLACEHOLDER}
          />
        </div>
      </Card>
    </section>
  );
};

import type { ReactNode } from "react";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { tw } from "@/utils";

interface CardProps {
  className?: string;
  titleClassName?: string;
  colorVariant?: Variant;
  children?: ReactNode;
  actions?: ReactNode;
  title: string;
  icon?: ReactNode;
}

export const Card = ({
  className,
  titleClassName,
  colorVariant = VARIANT.PRIMARY,
  children,
  actions,
  title,
  icon,
}: CardProps) => {
  return (
    <section
      className={tw(
        "flex h-auto flex-col gap-4 rounded-2xl p-4",
        colorVariant === VARIANT.PRIMARY && "bg-brown-03",
        colorVariant === VARIANT.SECONDARY && "bg-salmon-04",
        colorVariant === VARIANT.TERTIARY && "bg-brown-02",
        colorVariant === VARIANT.QUATERNARY && "bg-nature-08 text-brown-01",
        colorVariant === VARIANT.QUINARY && "bg-nature-03 text-nature-10",
        className,
      )}
    >
      <header className="flex items-center justify-between">
        <h3
          className={tw(
            "flex items-center gap-2 text-lg font-bold text-brown-10",
            colorVariant === VARIANT.QUATERNARY && "text-white",
            titleClassName,
          )}
        >
          {icon}
          {title}
        </h3>
        {actions}
      </header>

      {children}
    </section>
  );
};

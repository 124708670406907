import type { PropsWithChildren } from "react";

import { ScrollArea } from "@/ui";
import { tw } from "@/utils";

interface ScreenLayoutProps extends PropsWithChildren {
  className?: string;
}

export const ScreenLayout = ({ children, className }: ScreenLayoutProps) => {
  return (
    <ScrollArea>
      <div
        className={tw(
          "flex h-full flex-col gap-7 p-6 md:px-16 md:py-12",
          className,
        )}
      >
        {children}
      </div>
    </ScrollArea>
  );
};

import type { ReactNode } from "react";

import { VARIANT } from "@/shared.constants";
import type { BaseVariant } from "@/shared.types";
import { tw } from "@/utils";

export const OnboardingHeader = ({
  title,
  description,
  variant = VARIANT.PRIMARY,
}: {
  title: ReactNode;
  description?: ReactNode;
  variant?: BaseVariant;
}) => {
  return (
    <header
      className={tw(
        "flex w-full flex-col items-center gap-2 text-center",
        variant === VARIANT.SECONDARY && "flex-col-reverse gap-0",
      )}
    >
      <h1
        className={tw(
          "font-serif text-brown-10",
          variant === VARIANT.PRIMARY &&
            "max-w-3xl text-2xl sm:text-3xl sm:leading-snug",
          variant === VARIANT.SECONDARY &&
            "text-4xl text-nature-01 sm:text-6xl sm:leading-snug",
        )}
      >
        {title}
      </h1>

      {description && typeof description === "string" ? (
        <p
          className={tw(
            "font-medium text-brown-08",
            variant === VARIANT.PRIMARY && "text-sm sm:text-lg sm:leading-6",
            variant === VARIANT.SECONDARY &&
              "text-lg text-nature-04 sm:text-2xl",
          )}
        >
          {description}
        </p>
      ) : (
        description
      )}
    </header>
  );
};

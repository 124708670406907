import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { t } from "ttag";

import { createWeightEntry } from "@/api";
import { NOW } from "@/shared.constants";
import { weightEntrySchema } from "@/shared.schemas";
import type { ModalProps, WeightEntry } from "@/shared.types";
import {
  Button,
  CalendarMinimalisticIcon,
  Dialog,
  errorToast,
  GraphIconMono,
  HookedDatePicker,
  Input,
  OverlayLoader,
  ScrollArea,
  TextArea,
  useToastStore,
} from "@/ui";
import { formatToBackendDate, handleAxiosFieldErrors } from "@/utils";

const defaultValues = {
  date: formatToBackendDate(NOW),
};

export const CreateWeightEntry = ({ show, onClose }: ModalProps) => {
  const { pushToast } = useToastStore();

  const {
    formState: { errors, isDirty },
    register,
    control,
    handleSubmit,
    setError,
  } = useForm<Omit<WeightEntry, "id">>({
    resolver: zodResolver(weightEntrySchema.omit({ id: true })),
    defaultValues,
  });

  const queryClient = useQueryClient();
  const { mutate: createWeightEntryMutation, isPending } = useMutation({
    mutationFn: createWeightEntry.mutation,
    onSuccess: () => {
      onClose();
      createWeightEntry.invalidates(queryClient);
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Weight entry successfully created!`,
      });
    },
    onError: (err) => {
      errorToast(err);
      handleAxiosFieldErrors(err, setError);
    },
  });

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content className="w-modal gap-6">
        <Dialog.CloseButton />

        <ScrollArea className="h-[calc(100dvh-2rem)] px-8 md:h-fit">
          <Dialog.Header className="pb-6 pt-8 md:pt-10">
            <Dialog.Icon>
              <GraphIconMono />
            </Dialog.Icon>
            <Dialog.Title>{t`Log entry`}</Dialog.Title>
            <Dialog.Description>{t`Ready to track your progress? Share your current weight with us below! Let's work towards your goals together!`}</Dialog.Description>
          </Dialog.Header>

          <form
            onSubmit={handleSubmit((value) => createWeightEntryMutation(value))}
            id="create-entry-form"
            className="grow"
          >
            <HookedDatePicker
              label={t`Date *`}
              id="date"
              name="date"
              left={<CalendarMinimalisticIcon />}
              control={control}
              error={errors.date?.message}
              compact={false}
              config={{ dates: { maxDate: NOW } }}
            />
            <Input
              label={t`Weight (lbs) *`}
              placeholder={t`Your weight`}
              id="weight"
              type="number"
              {...register("weight", { setValueAs: Number })}
              error={errors.weight?.message}
              compact={false}
            />
            <TextArea
              label={t`Note (optional)`}
              placeholder={t`Optional note`}
              id="note"
              {...register("note")}
              error={errors.note?.message}
              compact={false}
            />
          </form>

          <Dialog.Footer className="pb-8">
            <Button
              disabled={isPending}
              onClick={onClose}
              variant="secondary"
              size="lg"
            >{t`Cancel`}</Button>
            <Button
              disabled={!isDirty || isPending}
              type="submit"
              form="create-entry-form"
              size="lg"
            >{t`Upload`}</Button>
          </Dialog.Footer>
        </ScrollArea>

        {isPending && <OverlayLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};

import { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";

import { IsoLogo } from "@/components";
import { icons, IconWrapper } from "@/ui";
import { Sidebar } from "./Sidebar";

export const ResponsiveSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <Dialog.Root open={sidebarOpen} onOpenChange={setSidebarOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-50 bg-gray-800/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
          <Dialog.Content className="fixed inset-y-0 left-0 z-50 h-full w-fit gap-4 border-r border-gray-700 bg-black shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm">
            <Sidebar onCloseSidebar={() => setSidebarOpen(false)} />
            <Dialog.Close className="absolute right-6 top-6 rounded-sm transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
              <IconWrapper size="md">
                <icons.XMark className="stroke-salmon-01" />
              </IconWrapper>

              <span className="sr-only">Close</span>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      <div className="z-40 flex h-12 shrink-0 items-center justify-between gap-x-6 bg-nature-08 px-4 shadow-sm sm:px-6 md:hidden lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-white xl:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>

          <icons.Bars3 className="h-5 w-5" aria-hidden="true" />
        </button>
        <IsoLogo className="h-7 w-7" />
      </div>
    </>
  );
};

import { twMerge } from "tailwind-merge";
import { t } from "ttag";

import type { Consultation } from "@/shared.types";
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  icons,
} from "@/ui";
import { ConsultationCard } from "./ConsultationCard";

export const UpcomingConsultations = ({
  upcomingConsultations,
}: {
  upcomingConsultations?: Consultation[];
}) => {
  return (
    <Card
      title={t`Upcoming Video Consultations`}
      className="max-w-[calc(100vw-2rem)] bg-brown-02 pr-0"
    >
      {!upcomingConsultations?.length && (
        <div className="flex flex-col items-center justify-center p-4">
          <icons.EmptyUpcomingConsultations />
          <p className="text-center text-xl font-medium text-brown-05">
            {t`There are no upcoming consultations yet`}
          </p>
        </div>
      )}
      {!!upcomingConsultations?.length && (
        <Carousel
          opts={{
            align: "end",
          }}
        >
          <CarouselContent className="px-1.5 py-1.5">
            {upcomingConsultations.map((consultation, idx, { length }) => (
              <CarouselItem
                key={`upcoming-consultation-${consultation.id}`}
                className={twMerge(
                  "max-w-full lg:basis-2/5",
                  idx + 1 === length && "pr-4",
                )}
              >
                <ConsultationCard
                  consultation={consultation}
                  isFirstConsultation={!idx}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="hidden md:flex">
            <CarouselPrevious />
            <CarouselNext />
          </div>
        </Carousel>
      )}
    </Card>
  );
};

import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { cancelAppointment } from "@/api";
import type { Consultation } from "@/shared.types";
import { Alert, Button, errorToast, icons, IconWrapper, Modal } from "@/ui";
import { formatBackendDate, formatConsultationTime, tw } from "@/utils";
import { useCanJoin } from "./useCanJoin";

interface ConsultationCardProps {
  consultation: Consultation;
  isFirstConsultation: boolean;
}

export const ConsultationCard = ({
  consultation,
  isFirstConsultation,
}: ConsultationCardProps) => {
  const [show, setShow] = useState(false);
  const [appointmentIdToDelete, setAppointmentIdToDelete] = useState<
    string | null
  >(null);
  const onClose = () => {
    setShow(false);
    setAppointmentIdToDelete(null);
  };
  const deleteAppointment = (id: string) => {
    setAppointmentIdToDelete(id);
    setShow(true);
  };

  const queryClient = useQueryClient();

  const { mutate: cancelAppointmentMutation, isPending } = useMutation({
    mutationFn: cancelAppointment.mutation,
    onSuccess: () => {
      cancelAppointment.invalidates(queryClient);
      onClose();
    },
    onError: (err) => {
      errorToast(err);
    },
  });

  const { canJoin } = useCanJoin(
    consultation.startTime,
    consultation.endTime,
    isFirstConsultation,
  );

  return (
    <div
      className={tw(
        "relative flex h-full max-w-full flex-col gap-4 rounded-xl border border-brown-05 px-9 py-8",
        canJoin && "border-salmon-06 bg-salmon-01",
      )}
    >
      <div
        className={tw(
          "absolute left-0 top-0 h-10 w-10 -translate-x-1.5 -translate-y-1.5 rounded-full bg-salmon-06 fill-salmon-01 p-3",
          canJoin && "bg-salmon-07 fill-brown-01",
        )}
      >
        <icons.VideoCamera />
      </div>

      <div className="flex flex-1 flex-col gap-1.5 text-brown-10">
        <h4 className="text-lg font-semibold">{t`Session with ${consultation.provider.fullName}`}</h4>
        <p className="font-medium">
          {formatBackendDate(consultation.startTime)}
        </p>
        <p>
          {`${formatConsultationTime(
            consultation.startTime,
          )} - ${formatConsultationTime(consultation.endTime)} ${
            consultation.timezone
          }`}
        </p>
      </div>

      {canJoin && (
        <div className="">
          <Alert
            content={t` For the video visit, please enter your name exactly as it appears in
          your Fridays profile.`}
            className="p-3 sm:p-3"
          />
        </div>
      )}

      <div className="flex gap-3 justify-self-end">
        {canJoin && (
          <a
            href={consultation.videoUrl}
            target="_blank"
            rel="noreferrer"
            role="button"
            className="flex shrink-0 items-center justify-center gap-2 truncate rounded-lg border border-transparent bg-nature-08 px-4 py-2 text-xs font-medium text-salmon-01 hover:bg-nature-07 focus:outline-none focus:ring-1 focus:ring-nature-03 disabled:bg-brown-04"
          >
            <IconWrapper className="h-5 w-5 fill-white">
              <icons.VideoCamera />
            </IconWrapper>
            {t`Join now`}
          </a>
        )}

        <Button
          variant="secondary"
          className="h-11 w-12 md:px-3.5"
          onClick={() => deleteAppointment(consultation.id)}
        >
          <IconWrapper size="sm" className="fill-nature-08">
            <icons.TrashBin2 />
          </IconWrapper>
        </Button>
      </div>

      <Modal
        show={show}
        onClose={onClose}
        icon={<icons.DeleteAppointment />}
        title={t`Are you sure you want to cancel your consultation?`}
        description={t`Cancelling your consultation with Dr. ${consultation.provider.fullName} means you'll lose your spot on the schedule. Are you sure you want to do that?`}
        className="w-96 rounded-2xl md:w-auto"
      >
        <div className="flex w-full flex-col-reverse items-stretch justify-center gap-3.5 md:flex-row md:items-center">
          <Button
            disabled={isPending}
            variant="secondary"
            onClick={onClose}
            size="lg"
          >
            {t`Keep Consultation`}
          </Button>
          <Button
            disabled={isPending}
            size="lg"
            onClick={() => cancelAppointmentMutation(appointmentIdToDelete!)}
          >
            {t`Cancel Consultation`}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

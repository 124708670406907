import { t } from "ttag";

import { DISCOUNT_DURATION, DISCOUNT_TYPE } from "@/shared.constants";
import type { Coupon } from "@/shared.types";

export const getCouponMessageFromResponse = (coupon: Coupon) => {
  const baseText = t`As a special treat `;

  const durationText = (() => {
    switch (coupon.duration) {
      case DISCOUNT_DURATION.ONCE:
        return t`for your first month `;
      case DISCOUNT_DURATION.REPEATING:
        return t`for your first ${coupon.durationInMonths} months `;
      case DISCOUNT_DURATION.FOREVER:
        return t`for the duration of your membership `;
    }
  })();

  const discountText = (() => {
    switch (coupon.type) {
      case DISCOUNT_TYPE.PERCENTAGE:
        return t`you get ${coupon.percentOff}% off. You'll pay $${(
          99 -
          (99 * coupon.percentOff) / 100
        ).toFixed(
          2,
        )} ${coupon.duration === DISCOUNT_DURATION.ONCE ? "for your first month" : "per month"}`;
      case DISCOUNT_TYPE.FIXED_AMOUNT:
        return t`you get $${coupon.amountOff} off. You'll pay $${
          99 - coupon.amountOff
        } ${coupon.duration === DISCOUNT_DURATION.ONCE ? "for your first month" : "per month"}`;
    }
  })();

  const priceText = (() => {
    if (coupon.duration !== DISCOUNT_DURATION.FOREVER) {
      return t`, then $99 per month after that.`;
    }
    return ".";
  })();

  return `${baseText}${durationText}${discountText}${priceText}`;
};

import { useState } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { CONSULTATION_PURPOSE, SIZE, VARIANT } from "@/shared.constants";
import { useConsultationStore } from "@/stores";
import {
  Button,
  icons,
  IconWrapper,
  illustratedIcons,
  Label,
  Modal,
  RadioIndicator,
} from "@/ui";
import { tw } from "@/utils";
import { RefillModal } from "./RefillModal";

const DOSIS_OPTIONS = Array.from({ length: 5 }, (_, index) => `${index}`);

export const Injections = ({ onSubmit }: { onSubmit: () => void }) => {
  const [selectedDosis, setSelectedDosis] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRefillModal, setShowRefillModal] = useState(false);
  const navigate = useNavigate();
  const refill = useConsultationStore(
    (state) => state.purpose === CONSULTATION_PURPOSE.REFILL,
  );

  return (
    <>
      <form
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault();
          const showReminderModal = DOSIS_OPTIONS.slice(0, 3).includes(
            selectedDosis,
          );

          if (showReminderModal) {
            setShowRefillModal(refill);
            setShowConfirmation(true);
          } else {
            onSubmit();
          }
        }}
      >
        <div className="flex flex-col gap-6 rounded-2xl bg-brown-02 p-4 pb-6">
          <Label htmlFor="injections-amount" className="text-brown-09">
            {t`How many injections of the current level of medication have you completed?`}
          </Label>

          <RadioGroup.Root
            id="injections-amount"
            className="flex flex-col items-center gap-6 md:flex-row"
            onValueChange={setSelectedDosis}
          >
            {DOSIS_OPTIONS.map((option) => (
              <RadioGroup.Item
                key={option}
                value={option}
                id={`dosis-option-${option}`}
                className={tw(
                  "group flex w-full grow items-center gap-2 rounded-2xl border border-brown-05 bg-brown-01 p-4",
                  selectedDosis === option && "border-brown-04 bg-brown-04",
                )}
              >
                <RadioIndicator className="group-hover:border-nature-09" />
                <Label
                  htmlFor={`dosis-option-${option}`}
                  className="flex-1 cursor-pointer"
                >
                  {option}
                </Label>
              </RadioGroup.Item>
            ))}
          </RadioGroup.Root>
        </div>

        <div className="flex flex-col-reverse items-stretch justify-between gap-4 md:flex-row md:items-center">
          <Button variant="secondary" size="lg" onClick={() => navigate(-1)}>
            <IconWrapper size="sm" className="hidden md:block">
              <icons.ChevronLeft />
            </IconWrapper>
            {t`Back`}
          </Button>
          <Button type="submit" size={SIZE.LARGE} disabled={!selectedDosis}>
            {t`Next`}
            <IconWrapper size="sm" className="hidden md:block">
              <icons.ChevronRight />
            </IconWrapper>
          </Button>
        </div>
      </form>

      <Modal
        show={showConfirmation && !showRefillModal}
        icon={<illustratedIcons.Warning />}
        title={t`Just a friendly reminder: Refills are available only after 3 injections.`}
        description={
          <p className="flex flex-col gap-6">
            <span>{t`It appears you've taken less than 3 doses of your medication. We will only process a refill if you've used 3 doses at the time of your session.`}</span>
            <span>{t`Would you like to proceed with your consultation anyway, or cancel and return later?`}</span>
            <span>{t`Remember, tracking your weight regularly can help you stay motivated and achieve your goals. Keep up the good work!`}</span>
          </p>
        }
        onClose={() => setShowConfirmation(false)}
      >
        <div className="flex w-full flex-col-reverse items-stretch justify-center gap-3.5 md:flex-row md:items-center">
          <Button
            size={SIZE.LARGE}
            variant={VARIANT.TERTIARY}
            onClick={() => {
              onSubmit();
            }}
          >
            {t`Proceed anyways`}
          </Button>
          <Button
            size={SIZE.LARGE}
            variant={VARIANT.SECONDARY}
            onClick={() => navigate(ROUTES.MESSAGES)}
          >
            {t`Message care coordinator`}
          </Button>
          <Button
            size={SIZE.LARGE}
            variant={VARIANT.PRIMARY}
            onClick={() => {
              navigate(ROUTES.CONSULTATIONS.OVERVIEW);
            }}
          >
            {t`Cancel request`}
          </Button>
        </div>
      </Modal>
      <RefillModal
        show={showConfirmation && showRefillModal}
        setShowConfirmation={setShowConfirmation}
      />
    </>
  );
};

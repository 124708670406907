import { Link } from "react-router-dom";
import { t } from "ttag";

import { MODAL_ROUTES, ROUTES, useNavigateModal } from "@/router";
import { Button, Shape7, Shape10, TestTubeFixedIcon } from "@/ui";
import { DocumentCard } from ".";

export const DocumentCenterLabs = () => {
  const navigateModal = useNavigateModal();
  return (
    <DocumentCard.Root>
      <DocumentCard.Banner>
        <DocumentCard.IconWrapper>
          <TestTubeFixedIcon />
        </DocumentCard.IconWrapper>

        <div className="absolute -z-10 flex h-full w-full items-start justify-center gap-7 text-nature-04">
          <Shape7 className="w-64 translate-y-11 -scale-x-100 opacity-30" />
          <Shape10 className="w-64 -translate-y-4 self-end opacity-30" />
        </div>
      </DocumentCard.Banner>

      <DocumentCard.Content>
        <DocumentCard.Title>{t`Labs`}</DocumentCard.Title>
        <DocumentCard.Description>{t`View and manage your lab results and lab orders here.`}</DocumentCard.Description>
      </DocumentCard.Content>

      <DocumentCard.Actions>
        <Button asChild variant="secondary" size="lg">
          <Link to={ROUTES.DOCUMENT_CENTER.LABS}>{t`View details`}</Link>
        </Button>
        <Button
          onClick={() => navigateModal(MODAL_ROUTES.DOCUMENT_CENTER.LABS)}
          size="lg"
        >
          {t`Upload lab test`}
        </Button>
      </DocumentCard.Actions>
    </DocumentCard.Root>
  );
};

import type { ComponentPropsWithoutRef, ForwardedRef } from "react";
import { Slot } from "@radix-ui/react-slot";

import { forwardRef, tw } from "@/utils";

const BUTTON_VARIANT = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  QUATERNARY: "quaternary",
} as const;
type ButtonVariant = (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT];

const SIZE = {
  SMALL: "sm",
  LARGE: "lg",
} as const;
type Size = (typeof SIZE)[keyof typeof SIZE];

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: ButtonVariant;
  size?: Size;
  asChild?: boolean;
}

export const Button = forwardRef(
  (
    {
      type = "button",
      className,
      variant = BUTTON_VARIANT.PRIMARY,
      size = SIZE.SMALL,
      disabled = false,
      children,
      asChild,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const Component = asChild ? Slot : "button";

    return (
      <Component
        ref={ref}
        type={type}
        className={tw(
          "flex shrink-0 items-center justify-center gap-2 truncate rounded-lg border border-transparent py-2.5 font-medium focus:outline-none focus:ring-1 focus:ring-nature-03 disabled:cursor-not-allowed",

          variant === BUTTON_VARIANT.PRIMARY &&
            "bg-nature-08 text-salmon-01 hover:bg-nature-07 disabled:bg-brown-04",
          variant === BUTTON_VARIANT.SECONDARY &&
            "border-nature-08 text-nature-08 hover:border-nature-07 hover:text-nature-07 disabled:border-brown-04 disabled:text-brown-04",
          variant === BUTTON_VARIANT.TERTIARY &&
            "bg-transparent text-nature-08 hover:text-nature-07 disabled:text-brown-04",
          variant === BUTTON_VARIANT.QUATERNARY && "bg-white text-black",

          size === SIZE.SMALL && "px-4 py-2 text-xs",
          size === SIZE.LARGE && "px-6 text-base",
          className,
        )}
        disabled={disabled}
        {...props}
      >
        {children}
      </Component>
    );
  },
);

import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { t } from "ttag";

import { getPatientQuery } from "@/api";
import { Loader } from "@/components";
import { ROUTES } from "@/router";
import { Avatar, Card, CardItem, icons, IconWrapper, ScreenHeader } from "@/ui";
import { formatBackendDate } from "@/utils";
import { InsuranceCard } from "./components";

export const MyProfile = () => {
  const { data: patient, isLoading } = useQuery(getPatientQuery());

  return (
    <div className="relative flex grow flex-col gap-6 bg-salmon-01 px-4 pt-12 md:px-8 lg:px-16">
      <ScreenHeader
        title={t`My Profile`}
        paths={[
          {
            href: ROUTES.PROFILE.OVERVIEW,
            icon: icons.User,
            label: t`My Profile`,
          },
        ]}
        actions={
          <div className="flex items-center gap-2">
            <Link
              to={ROUTES.PROFILE.EDIT}
              className="flex items-center gap-2 rounded-lg bg-salmon-01 p-2.5 text-xs font-medium text-brown-10 sm:px-4"
            >
              <IconWrapper size="xs" className="hidden sm:block">
                <icons.Pencil className="fill-brown-07" />
              </IconWrapper>
              {t`Edit profile`}
            </Link>
          </div>
        }
      />

      {isLoading && <Loader />}

      {patient && (
        <div className="grid gap-4 pb-8 lg:grid-cols-2">
          <Card
            colorVariant="secondary"
            title={t`General information`}
            actions={
              <Avatar imgURL={patient.picture} name={patient.firstName} />
            }
          >
            <div className="flex flex-col gap-4">
              <CardItem
                colorVariant="secondary"
                labelIcon={<icons.User />}
                label={t`First name`}
              >
                {patient.firstName}
              </CardItem>
              <CardItem
                colorVariant="secondary"
                labelIcon={<icons.User />}
                label={t`Last name`}
              >
                {patient.lastName}
              </CardItem>
              <CardItem
                colorVariant="secondary"
                labelIcon={<icons.Gender />}
                label={t`Sex assigned at birth`}
              >
                <span className="capitalize">{patient.gender}</span>
              </CardItem>
              <CardItem
                colorVariant="secondary"
                labelIcon={<icons.Calendar />}
                label={t`Date of birth`}
              >
                {formatBackendDate(patient.dateOfBirth)}
              </CardItem>
            </div>
          </Card>

          <Card title={t`Contact`} actions={<div className="h-10"></div>}>
            <div className="grid gap-4 lg:grid-cols-2">
              <CardItem label={t`Email address`} labelIcon={<icons.Envelope />}>
                {patient.email}
              </CardItem>
              <CardItem
                label={t`Phone number`}
                labelIcon={<icons.DevicePhone />}
              >
                {patient.phoneNumber}
              </CardItem>
              <CardItem
                label={t`House and street #`}
                labelIcon={<icons.Home />}
              >
                {patient.location?.line1}
              </CardItem>
              <CardItem label={t`Apartment number`} labelIcon={<icons.Home />}>
                {patient.location?.line2}
              </CardItem>
              <CardItem label={t`Zip code`} labelIcon={<icons.Home />}>
                {patient.location?.zip}
              </CardItem>
              <CardItem label={t`City`} labelIcon={<icons.Home />}>
                {patient.location?.city}
              </CardItem>
              <CardItem label={t`State`} labelIcon={<icons.Home />}>
                {patient.location?.state}
              </CardItem>
              <CardItem label={t`Country`} labelIcon={<icons.Home />}>
                {patient.location?.country}
              </CardItem>
            </div>
          </Card>

          <div className="lg:col-span-2">
            <InsuranceCard policy={patient.policy} />
          </div>
        </div>
      )}
    </div>
  );
};

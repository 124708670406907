import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { checkNewMessagesQuery, getConversationsQuery } from "@/api/messages";
import { ROUTES } from "@/router";
import { VARIANT } from "@/shared.constants";
import type { Conversation } from "@/shared.types";
import { Button, icons, IconWrapper, ScreenHeader } from "@/ui";
import { tw } from "@/utils";
import Chat from "./Chat";
import ChatsList from "./ChatsList";

export const Messages = () => {
  const queryClient = useQueryClient();
  const { data: conversationsWithNewMessages } = useQuery(
    checkNewMessagesQuery(),
  );
  const [selectedConversation, setSelectedConversation] =
    useState<Conversation | null>(null);
  const [createNewChat, setCreateNewChat] = useState<boolean>(false);
  const { data: conversations } = useQuery(getConversationsQuery());
  const [newConversationId, setNewConversationId] = useState<string | null>(
    null,
  );

  const handleSelectConversation = (conversation: Conversation) => {
    setNewConversationId(null);
    setSelectedConversation(conversation);
    setCreateNewChat(false);
  };

  const handleCreateNewChat = () => {
    setCreateNewChat(true);
    setSelectedConversation(null);
  };

  const handleChatCreated = (conversationId: string) => {
    setNewConversationId(conversationId);
    setCreateNewChat(false);
  };

  useEffect(() => {
    const conversation = conversations?.find(
      (conversation) => conversation.id === newConversationId,
    );
    if (conversation) {
      setCreateNewChat(false);
      setSelectedConversation(conversation);
      setNewConversationId(null);
    }
  }, [conversations, newConversationId]);

  useEffect(() => {
    if (
      conversationsWithNewMessages &&
      conversationsWithNewMessages.conversationsWithNewMessages.length > 0
    ) {
      void queryClient.invalidateQueries({ queryKey: ["messages"] });
    }
  }, [
    conversationsWithNewMessages,
    conversationsWithNewMessages?.conversationsWithNewMessages,
    queryClient,
  ]);

  return (
    <div className="flex max-h-full w-full flex-col px-0 md:px-8 md:pb-4 md:pt-12 lg:px-16">
      <div className="mb-4 hidden md:block">
        <ScreenHeader
          title={t`Messages`}
          paths={[
            {
              href: ROUTES.MESSAGES,
              icon: icons.Envelope,
              label: t`Messages`,
            },
          ]}
          actions={
            <button
              className="flex cursor-pointer items-center gap-2 rounded-lg bg-salmon-01 px-4 py-2.5 text-xs font-medium text-brown-10"
              onClick={handleCreateNewChat}
            >
              <IconWrapper size="xs" className="hidden sm:block">
                <icons.Envelope className="fill-brown-07" />
              </IconWrapper>
              <span className="text-sm sm:text-xs">{t`New message`}</span>
            </button>
          }
          hidden={
            createNewChat ||
            selectedConversation !== null ||
            newConversationId !== null
          }
        />
      </div>
      <div
        className={tw(
          "mx-4 mt-4 flex flex-col gap-2 rounded-2xl bg-nature-08 pb-4 pl-4 pr-8 pt-6 md:mx-0 md:hidden",
          (Boolean(selectedConversation) ||
            Boolean(createNewChat) ||
            Boolean(newConversationId)) &&
            "hidden",
        )}
      >
        <div className="flex items-center justify-start md:justify-between">
          <div className="flex w-full items-center justify-between gap-2 text-lg font-bold text-nature-01">
            <p className="font-serif text-2xl font-normal">{t`Messages`}</p>
            <Button
              variant={VARIANT.QUATERNARY}
              onClick={handleCreateNewChat}
            >{t`New message`}</Button>
          </div>
        </div>
      </div>
      <div className="h-full max-h-full grid-cols-5 gap-3 overflow-auto md:grid">
        <ChatsList
          conversations={conversations}
          setSelectedConversation={handleSelectConversation}
          selectedConversation={selectedConversation}
          createNewChat={createNewChat}
          newConversationId={newConversationId}
        />
        <Chat
          newConversationId={newConversationId}
          handleChatCreated={handleChatCreated}
          conversationId={newConversationId ?? selectedConversation?.id}
          createNewChat={createNewChat}
          goBack={() => {
            setSelectedConversation(null);
            setNewConversationId(null);
            setCreateNewChat(false);
          }}
        />
      </div>
    </div>
  );
};

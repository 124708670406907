import { ScreenLayout } from "@/layouts";
import {
  DocumentCenterDoctorNotes,
  DocumentCenterHeader,
  DocumentCenterLabs,
  DocumentCenterMedicalRecords,
  DocumentCenterPrescriptions,
  FridaysResources,
} from "./components";

export const DocumentCenterOverview = () => {
  return (
    <ScreenLayout>
      <DocumentCenterHeader />

      <FridaysResources />

      <section className="grid gap-4 lg:grid-cols-2">
        <DocumentCenterLabs />
        <DocumentCenterPrescriptions />
        <DocumentCenterDoctorNotes />
        <DocumentCenterMedicalRecords />
      </section>
    </ScreenLayout>
  );
};

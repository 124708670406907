import type { ReactNode } from "react";

import { VARIANT } from "@/shared.constants";
import type { Variant } from "@/shared.types";
import { IconWrapper, Label } from "@/ui";
import { tw } from "@/utils";

interface CardItemProps {
  children?: ReactNode;
  colorVariant?: Variant;
  label: string;
  labelIcon?: ReactNode;
}

export const CardItem = ({
  children,
  colorVariant = VARIANT.PRIMARY,
  label,
  labelIcon,
}: CardItemProps) => {
  return (
    <div
      className={tw(
        "flex flex-col gap-1 rounded-lg p-4 text-salmon-10",
        colorVariant === VARIANT.PRIMARY && "bg-brown-02",
        colorVariant === VARIANT.SECONDARY && "bg-salmon-02",
      )}
    >
      <Label size="md">
        <IconWrapper size="sm" className="fill-salmon-09">
          {labelIcon}
        </IconWrapper>
        <span className="truncate">{label}</span>
      </Label>
      <div className="truncate pl-5 text-sm">{children ? children : "-"}</div>
    </div>
  );
};

import sanitizeHtml from "sanitize-html";

import { ATTACHMENT_TYPES, SIZE, VARIANT } from "@/shared.constants";
import type { ChatMessage } from "@/shared.types";
import { useUserStore } from "@/stores";
import { Avatar, IconWrapper, PaperclipIcon, Skeleton } from "@/ui";
import { tw } from "@/utils";

export const Message = ({ message }: { message: ChatMessage }) => {
  const { user } = useUserStore();

  const parseUrls = (text: string) => {
    const urlRegex = new RegExp(/\b((http|https):\/\/[^\s/$.?#].[^\s]*)/gi);
    const dirtyHtml = text.replace(
      urlRegex,
      '<a href="$1" target="_blank">$1</a>',
    );

    const sanitizedHtml: string = sanitizeHtml(dirtyHtml, {
      allowedTags: ["a"],
      allowedAttributes: {
        a: ["href", "target"],
      },
    });

    return sanitizedHtml;
  };

  return (
    <div className={tw("flex w-full gap-2", message.isFromUser && "self-end")}>
      {!message.isFromUser && (
        <Avatar
          name={message.author}
          variant={VARIANT.TERTIARY}
          size={SIZE.SMALL}
          onlyInitial={false}
        />
      )}
      <div
        className={tw(
          "flex w-full flex-col gap-1 rounded-lg border p-2",
          !message.isFromUser
            ? "rounded-tl-none border-salmon-07 bg-salmon-01"
            : "rounded-tr-none border-nature-07 bg-nature-01",
        )}
      >
        <div className="flex flex-col gap-2">
          <div
            className="text-sm text-brown-09"
            dangerouslySetInnerHTML={{ __html: parseUrls(message.body) }}
          ></div>
          {message.attachmentUrl &&
            message.attachmentType === ATTACHMENT_TYPES.IMAGE && (
              <img
                className="w-fit"
                src={message.attachmentUrl}
                alt="attachment"
              />
            )}
          {message.attachmentUrl &&
            message.attachmentType === ATTACHMENT_TYPES.FILE && (
              <button
                className="flex w-fit items-center gap-2 rounded-xl border border-brown-03 px-4 py-2 text-sm"
                onClick={() => window.open(message.attachmentUrl, "_blank")}
              >
                <IconWrapper className="size-4 text-brown-08">
                  <PaperclipIcon />
                </IconWrapper>
                {message.attachmentName}
              </button>
            )}
          {message.attachmentType === ATTACHMENT_TYPES.PLACEHOLDER && (
            <Skeleton className="h-8 w-1/4" />
          )}
          <div className="self-end text-xs text-brown-07">
            {message.timestamp}
          </div>
        </div>
      </div>
      {message.isFromUser && (
        <Avatar name={user?.name} variant="primary" size="sm" />
      )}
    </div>
  );
};

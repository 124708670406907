import type { User } from "@/shared.types";
import { useUserStore } from "@/stores";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";

export interface AuthData {
  accessToken: string;
  user: User;
  needsChangePassword: boolean;
}

interface LoginRequest {
  email: string;
  password: string;
}

export const login = {
  mutation: async (params: LoginRequest) => {
    const { data } = await publicAPI.post<ServiceResponse<AuthData>>("/login", {
      ...params,
    });

    return data.data;
  },
};

export const logout = () => {
  const { clearUser } = useUserStore.getState();

  clearUser();
};

export const setPassword = {
  mutation: async ({
    password,
    passwordConfirmation,
    token,
  }: {
    password: string;
    passwordConfirmation: string;
    token?: string | null;
  }) => {
    const { data } = await publicAPI.put<
      ServiceResponse<{ patientId: string }>
    >("/patients/set-password", {
      password,
      password_confirmation: passwordConfirmation,
      token,
    });

    return data.data;
  },
};

export const setFridaysPassword = {
  mutation: async ({
    password,
    passwordConfirmation,
  }: {
    password: string;
    passwordConfirmation: string;
  }) => {
    const { data } = await privateAPI.put<ServiceResponse<void>>(
      "/patients/set-fridays-password",
      {
        password,
        password_confirmation: passwordConfirmation,
      },
    );

    return data.data;
  },
};

export const forgotPassword = {
  mutation: async ({ email }: { email: string }) => {
    await publicAPI.post("/patients/forgot-password", { email });
  },
};

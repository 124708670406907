import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import {
  DIRECTION,
  SIZE,
  SUBSCRIPTION_STATUS,
  VARIANT,
} from "@/shared.constants";
import type { SubscriptionStatus } from "@/shared.types";
import { Button, icons, IconWrapper, shapes } from "@/ui";

interface CurrentMembershipSectionProps {
  status: SubscriptionStatus;
}

export const CurrentMembershipSection = ({
  status,
}: CurrentMembershipSectionProps) => {
  const navigate = useNavigate();
  return (
    <section className="relative z-10 flex flex-col gap-4 overflow-hidden rounded-2xl bg-salmon-03 p-4">
      <header className="flex items-center justify-between">
        <h3 className="flex items-center gap-2 text-sm text-brown-10">
          <IconWrapper size={SIZE.SMALL}>
            <icons.Card />
          </IconWrapper>
          {t`Current membership`}
        </h3>
      </header>

      <div className="flex grow flex-col justify-center gap-1">
        {status === SUBSCRIPTION_STATUS.ACTIVE && (
          <>
            <h4 className="text-xl font-bold text-brown-10">
              {t`Monthly plan`}
            </h4>
            <p className="text-lg font-medium text-salmon-10">$99</p>
          </>
        )}

        {status === SUBSCRIPTION_STATUS.CANCELLED && (
          <>
            <h4 className="text-xl font-bold text-brown-10">
              {t`Membership canceled.`}
            </h4>
            <p className="text-xs text-brown-08">{t`Not currently billing`}</p>
          </>
        )}
      </div>

      {status === SUBSCRIPTION_STATUS.ACTIVE && (
        <div className="flex w-full items-center justify-between">
          <div className="text-xs text-brown-08">{t`Billing every 28 days`}</div>
          <Button
            variant={VARIANT.TERTIARY}
            onClick={() => navigate(ROUTES.MESSAGES)}
            className="text-xs font-medium text-brown-09"
          >{t`Cancel subscription`}</Button>
        </div>
      )}

      {status === SUBSCRIPTION_STATUS.CANCELLED && (
        <Button
          variant={VARIANT.TERTIARY}
          onClick={() => navigate(ROUTES.MESSAGES)}
          className="self-start px-2 text-xs font-medium text-brown-09"
        >{t`Reactivate subscription`}</Button>
      )}

      <div className="-right-5 top-8 -z-10 hidden h-full scale-x-[-1] transform items-center opacity-90 md:absolute md:flex">
        <shapes.CompositeSquareBy3
          direction={DIRECTION.TOP}
          shapeVariant={VARIANT.TERTIARY}
          shapeColor={VARIANT.QUATERNARY}
          className="opacity-90"
        />
      </div>
    </section>
  );
};
